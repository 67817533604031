import {ActionType, ApiEngine, Dispatcher, T} from '../../model';
import {setAlert} from './alert';


const projectApiPath = 'earnings';
const apiPathSurveyorEarnings = 'surveyor-earnings';


type GSE = (from: string | undefined, to: string | undefined, projectId: number | undefined, managerId: number | undefined, surveyorId: number | undefined, weekly: boolean| undefined, monthly: boolean | undefined) => Dispatcher;

/**
 * Get Surveyor earning weekly
 * @params data
 */

export const fetchWeeklyProjectsDet = (date: string, erSurveyorId: number, managerId: number | undefined, weeklyParams: T) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const surveyorId = weeklyParams.surveyorId !== undefined && weeklyParams.surveyorId !== '' ? weeklyParams.surveyorId : erSurveyorId;
    const projectId = weeklyParams.projectId !== '' ? weeklyParams.projectId: undefined;
    const frequency = weeklyParams.earningsFreq;
    const query = { date, surveyorId, managerId, projectId, frequency, tz: Intl.DateTimeFormat().resolvedOptions().timeZone };
    const weeklyEarningsDetails = await api.get(`${projectApiPath}/surveyor-earnings-pay-stub`, null, query);
    return dispatch({type: ActionType.PROJECT_WEEKLY_DETAILS, payload: weeklyEarningsDetails});
  } catch (err) {
    dispatch(setAlert(err));
  }
}

/**
 * Fetch Surveyor Earnings
 * @param from
 * @param to
 * @param projectId
 * @param managerId
 * @param surveyorId
 * @param weekly
 * @param monthly
 * @returns
 */

export const getSurveyorEarnings: GSE = (from, to, projectId, managerId, surveyorId, weekly, monthly) => async (dispatch, _store, api) => {
  try {
    const q = {from, to, projectId, managerId, surveyorId, weekly, monthly};
    const earnings = await api.get(apiPathSurveyorEarnings, null, q);
    dispatch({type: ActionType.SURVEYOR_EARNINGS, payload: earnings.earningAndCallData});
    dispatch({type: ActionType.CHART_EARNINGS, payload: earnings.chartData});
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Flush Weekly Earning Data
 */

export const earningsPayStubFlush = () => async (dispatch: Function, _store: Function, _api: ApiEngine) => {
  try {
    dispatch({type: ActionType.PROJECT_WEEKLY_DETAILS_FLUSH, payload: []});
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/***
 * Flush Surveyor Earnings Details
 */

export const flushSurveyorEarningData = () => async(dispatch: Function, _store: Function, _api: ApiEngine) => {
  try {
    dispatch({type: ActionType.FLUSH_SURVEYOR_EARNINGS, payload: []});
    dispatch({type: ActionType.FLUSH_CHARTS_EARNINGS, payload: []});
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
}
