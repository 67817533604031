import {ActionType} from '../../model';


export const SetLeftNav = (isExpanded: boolean) => async (dispatch: Function) => {
  return dispatch({type: ActionType.NAV_EXPAND_COLLAPSE, payload: {isExpanded: !isExpanded}});
};

export const GetLeftNav = () => async (dispatch: Function, store: Function) => {
  const {nav} = store();
  // const payload = isMobileDevice ? {isExpanded: !isMobileDevice} : nav;
  const payload = nav;
  return dispatch({type: ActionType.NAV_EXPAND_COLLAPSE, payload});
};
