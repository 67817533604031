import React from 'react';
import {Prompt, useHistory, useParams} from 'react-router';
import {connect} from 'react-redux';
import * as H from 'history';
import {Loader} from '../../components';
import {Guidelines} from '../../components/Guidelines';
import {CallSurvey} from '../../components/Calls';
import {CallDetail, CallSurComments} from '../../components/Generic';
import {Button, Confirm, GenModelProps, Grid, Model} from '../../components/Inputs';
import {
  Account,
  BN,
  ButtonProps,
  CallVisitInfo,
  ChkProps,
  Language as LangProps,
  onTAC,
  RC,
  StoreT,
  T
} from '../../model';
import {useActions} from '../../redux/actions';
import * as callsActions from '../../redux/actions/calls';
import * as languagesActions from '../../redux/actions/languages';
import * as navActions from '../../redux/actions/nav';
import * as svGuidelinesAction from '../../redux/actions/svGuidelines';
import {
  CONFIRM_MESSAGES,
  initFalsy, JsEvents,
  Language,
  OPT_OUT_AGREE_KEY,
  PatientType,
  RETURN_KEY_CODE,
  RosterKeys,
  StatusCode,
  SurCallResponse
} from '../../constants';
import {Path} from '../../routes';
import {enumAsAO, va} from '../../utils/arrayUtils';
import {sleep} from '../../utils/objectUtils';
import {pez, plb} from '../../utils/stringUtils';
import {del, get, save} from '../../utils/storage';
import {optOutSecretKey, parseLocPathAsMenu} from '../../utils/common';


interface SurProjectCallProps {
  languages: Array<LangProps>;
  visitCall: CallVisitInfo;
  user: Account;
}

type ParserFux = ({field, value}: { field: string; value: string; }) => { label: string; value: string; };

const SurProjectCalls = (props: SurProjectCallProps) => {
  const strEmpty = '';
  const waitLatency = 100;
  const [callStartTime, setStartCallTime] = React.useState('');
  const [isBlocking, setIsBlocking] = React.useState(initFalsy);
  const [loader, isLoading] = React.useState(!initFalsy);
  const [isLocked, setLocked] = React.useState(!initFalsy);
  const [locking, checkingLock] = React.useState(initFalsy);
  const [optOut, setOptOut] = React.useState(initFalsy);
  const [surveyResult, setSurveyResult] = React.useState(initFalsy as BN);
  const [dnc, setDNC] = React.useState(initFalsy);
  const [ps, setPartialSurvey] = React.useState(initFalsy);
  const [qar, setQARemoval] = React.useState(initFalsy);
  const [escalation, setEscalation] = React.useState(initFalsy);
  const [escalationComment, setEscalationCmt] = React.useState(strEmpty);
  const [positiveComment, setPosCmt] = React.useState(strEmpty);
  const [negativeComment, setNegCmt] = React.useState(strEmpty);
  const [selLanguage, setLanguage] = React.useState(Language.English);
  const [pdfUrl, setPdfUrl] = React.useState(strEmpty);
  const [showSG, setShowSG] = React.useState(initFalsy);
  const [sgConfirm, setSGConfirm]: [boolean, Function] = React.useState(initFalsy);
  const [confirming, setConfirming]: [boolean, Function] = React.useState(initFalsy);
  const hist = useHistory();
  const {id}: T = useParams();
  const surveyorCallsA = useActions(callsActions);
  const svGuideline = useActions(svGuidelinesAction);
  const nav = useActions(navActions);
  const langA = useActions(languagesActions);
  const {languages, user} = props;
  const callInfo: CallVisitInfo = props.visitCall;
  const buttonFields = enumAsAO(SurCallResponse);
  const languageName = languages.find(i => i.id === callInfo.languageId)?.name;
  const onEscalation: onTAC = (_e, {value}) => setEscalationCmt(value as string);
  const onPositiveCmt: onTAC = (_e, {value}) => setPosCmt(value as string);
  const onNegativeCmt: onTAC = (_e, {value}) => setNegCmt(value as string);
  const onKeyDown = (_e: T) => {
    if (_e.keyCode === RETURN_KEY_CODE) {
      _e.preventDefault();
    }
  };
  const callDetailFields = [
    {label: RosterKeys.PatientName, value: callInfo.visitorName},
    {label: RosterKeys.CellPhone, value: callInfo.phoneCell},
    {label: RosterKeys.HomePhone, value: callInfo.phoneHome},
    {label: 'Date Of Service', value: new Date(callInfo.dos).toUSDateString()},
    {label: RosterKeys.Age, value: callInfo.age},
    {label: RosterKeys.Sex, value: callInfo.gender},
    {label: RosterKeys.ProviderName, value: callInfo.provider},
    {label: RosterKeys.Center, value: callInfo.center},
    {label: RosterKeys.Department, value: callInfo.department},
    {label: RosterKeys.GuarantorName, value: callInfo.guarantor},
    {label: RosterKeys.Language, value: languageName},
    {label: RosterKeys.CHPW, value: callInfo.chpw},
    {label: RosterKeys.PatientType, value: PatientType[callInfo.typeId]},
    {label: RosterKeys.ProviderType, value: callInfo.providerType},
    {label: RosterKeys.PayerType, value: callInfo.payerType},
    {label: RosterKeys.Ethnicity, value: callInfo.ethnicity},
    {label: RosterKeys.Race, value: callInfo.race}
  ];
  const callComments = [
    {label: 'Positive', placeholder: 'Positive ', value: positiveComment, onChange: onPositiveCmt, onKeyDown},
    {label: 'Negative', placeholder: 'Negative', value: negativeComment, onChange: onNegativeCmt, onKeyDown}
  ];
  const escComments = [
    {placeholder: 'Add Notes', value: escalationComment, onChange: onEscalation}
  ];
  const takeABreakChkProps = {
    checked: optOut,
    className: '',
    label: 'One More Attempt and Break',
    name: 'optOut',
    onChange: () => setOptOut(!optOut),
    style: {padding: '10px'}
  };
  const onUnLoadEvent = (e: Event) => {
    e.preventDefault();
    e.returnValue = initFalsy;
  };
  const fetchCall = async () => {
    const pdfUrlRes = await svGuideline.fetchSvGuidelineStatus(id as number, user.id);

    if (!!pdfUrlRes) {
      setPdfUrl(pdfUrlRes);
      setShowSG(!initFalsy);
    }

    surveyorCallsA.fetchCallInfo(id, user.id)
      .then((p: T) => {
        if (p.statusCode === StatusCode.Unauthorized) {
          hist.push(Path.SurveyorProjects);
        } else {
          langA.fetchProjectLanguages(id)
            .then(() => isLoading(initFalsy))
            .then(() => isLoading(initFalsy))
        }
      })
      .catch(() => {
        setIsBlocking(initFalsy);
        hist.push(Path.SurveyorProjects);
      }); // Either all calls are done or an error! Lets redirect to my projects
  };
  const updateCallPayload = (result: number, isPartial: boolean) => {
    const {callId, projectId, visitId, visitorId} = callInfo;
    return {
      callerId: user.id,
      callStart: callStartTime,
      escalation: escalationComment,
      id: callId,
      isPartial,
      isQARemoval: qar,
      negative: plb(negativeComment),
      positive: plb(positiveComment),
      projectId,
      result,
      surveyLanguageId: selLanguage,
      visitId,
      visitorId
    };
  };
  const saveCallResponse = async (result: number) => {
    if (!result) {
      isLoading(initFalsy);
      return;
    }
    const optOutKey = optOutSecretKey(id, callInfo.callId);
    const optOutAgreeKey = optOutSecretKey(id, callInfo.callId, OPT_OUT_AGREE_KEY);
    const noPartialResults = [SurCallResponse['Agreed to Survey']]
    if (dnc) {
      noPartialResults.push(SurCallResponse['Do Not Call'])
    }
    const isPartial = ps || !(noPartialResults.includes(result));
    const updPayload = updateCallPayload(result, isPartial);

    if (!!get(optOutAgreeKey)) {
      Object.assign(updPayload, {isAgreeComplete: !initFalsy});
    }

    if (!isPartial) {
      Object.assign(updPayload, {surveyCompletedOn: new Date().toISOString()});
    }

    await surveyorCallsA.surveyCallResponse(updPayload, !initFalsy);
    del(optOutKey);
    del(optOutAgreeKey);

    if (optOut) {
      setIsBlocking(initFalsy);
      hist.push(Path.SurveyorProjects)
    } else {
      await surveyorCallsA.flushCallInfo();
      await fetchCall();
    }
  };
  const onAgreeResponse = async (result: number) => {
    const optOutKey = optOutSecretKey(id, callInfo.callId, OPT_OUT_AGREE_KEY);
    save(optOutKey, result.toString());
    const isPartial = !initFalsy;
    const updPayload = updateCallPayload(result, isPartial);

    await surveyorCallsA.surveyCallResponse(updPayload, initFalsy);
  };
  const onSurveyResponse = async (res?: number) => {
    isLoading(!initFalsy); // lock again
    const result = dnc ? SurCallResponse['Do Not Call'] : (!!surveyResult ? surveyResult : res) as number;
    setLocked(!initFalsy);
    saveCallResponse(result)
      .then(() => {
        setEscalationCmt(strEmpty);
        setPosCmt(strEmpty);
        setNegCmt(strEmpty);
        setEscalation(initFalsy);
        setDNC(initFalsy);
        setSurveyResult(initFalsy);
        setPartialSurvey(initFalsy);
        setQARemoval(initFalsy);
        document.getElementById('top')?.scrollIntoView();
      });
  };
  const saveFun = async (result: number) => {
    if (result !== SurCallResponse['Agreed to Survey']) {
      await onSurveyResponse(result);
    } else {
      await onAgreeResponse(result);
    }
  };
  const onStartCall = async () => {
    checkingLock(!initFalsy);
    await nav.SetLeftNav(!initFalsy);
    await sleep(waitLatency);
    setLocked(initFalsy); // unlock
    checkingLock(initFalsy);
    setStartCallTime(new Date().toISOString());
  };
  const pageLoadEffect = () => {
    // component mount - update
    fetchCall().then();

    return () => surveyorCallsA.unLockCall();
  };
  const blockBackEffect = () => {
    setIsBlocking(!initFalsy);
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener(JsEvents.BeforeUnload, onUnLoadEvent);

    return () => {
      // window.removeEventListener(histEvent, onBackButtonEvent);
      window.removeEventListener(JsEvents.BeforeUnload, onUnLoadEvent);
    };
  };
  const fieldValueParser: ParserFux = ({field, value}) => ({label: field, value});
  const additionalFields = va(callInfo.visitInfos) ? callInfo.visitInfos?.filter((cf) => cf.show) : [];
  const pipeFields = va(callInfo.visitInfos) ? callInfo.visitInfos : [];
  const listItems = va(additionalFields) ?
    callDetailFields.concat(additionalFields.map(fieldValueParser)) :
    callDetailFields;
  const callDetailProps = {
    listItems,
    listMainTitle: callInfo.projectName,
    listSubTitle: ('Call Details') as RC
  };
  const chkBoxes: ChkProps[] = [
    {
      checked: !!escalationComment,
      label: 'Escalate to your Project Manager',
      name: 'escalate',
      onChange: () => setEscalation(!escalation)
    },
    {checked: dnc, label: 'Add to Do Not Call List', name: 'dnc', onChange: () => setDNC(!dnc)},
    {checked: ps, label: 'Partial Survey', name: 'ps', onChange: () => setPartialSurvey(!ps)},
    {checked: qar, label: 'QA Removal', name: 'qar', onChange: () => setQARemoval(!qar)}
  ];
  const onEscalationClose = () => {
    setEscalation(initFalsy);
  };
  const escalationActions = (
    <>
      <Button content="Save and Return" onClick={onEscalationClose} primary={!initFalsy} />
      <Button content="Cancel" onClick={onEscalationClose} />
    </>
  );
  const escalationModelContent = (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <CallSurComments header="Provider Notes" items={escComments} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
  const callResponseProps = {
    buttonFields,
    captureResponse: async (result: number) => {
      await sleep(waitLatency);
      setSurveyResult(result);
      await saveFun(result)
    }
  };
  const escModelProps = {
    actions: escalationActions,
    centered: !initFalsy,
    content: escalationModelContent,
    initialize: escalation,
    onCancel: onEscalationClose
  };
  const questionnaireProps = {
    agreedToSurvey: (surveyResult === SurCallResponse['Agreed to Survey']),
    allowScroll: !initFalsy,
    callId: callInfo.callId,
    callResponseProps,
    captureResponse: onSurveyResponse,
    chkBoxes,
    languageId: callInfo.languageId,
    pipeItems: callDetailFields.concat(pipeFields.map(fieldValueParser)),
    ps,
    qar,
    setLanguage,
    surveyLangId: selLanguage,
    surveyorId: user.id,
    surveyResult
  };
  const surveyProps = {
    questionnaireProps,
    takeABreakChkProps,
    isLocked,
    locking,
    onStartCall
  };
  const title = `[${pez(callInfo.visitorName)}] ${pez(callInfo.phoneCell)} ${pez(callInfo.phoneHome)}`;
  const confirmButton = (
    <Button content="Yes, I've Read" loading={confirming} primary={!initFalsy} type="button" />
  );
  const getBlockingMessage = (location: H.Location) => {
    return `${CONFIRM_MESSAGES.LEAVE_PAGE} ${location && `to go to ${parseLocPathAsMenu(location.pathname)}`}`;
  };
  const onSGClose = () => setShowSG(initFalsy);
  const onSGConfirm = () => {
    setConfirming(!initFalsy);
    svGuideline.updateSvGuidelineStatus(id as number, user.id);
    setConfirming(initFalsy);
    setShowSG(initFalsy);
    setSGConfirm(initFalsy);
  };
  const fcConfirmProps = {
    content: CONFIRM_MESSAGES.SURVEYOR_GUIDELINES,
    header: 'Surveyor Guidelines Confirmation',
    cancelButton: 'No, I will read it later.',
    confirmButton,
    open: sgConfirm,
    onCancel: () => setSGConfirm(initFalsy),
    onConfirm: onSGConfirm,
  };
  const sgCancelButtProps: ButtonProps = {
    className: 'mb15 ml10',
    content: 'Remind me before next call',
    floated: 'right',
    onClick: onSGClose
  };
  const sgConfirmButtProps: ButtonProps = {
    className: 'mb15 ml10',
    content: 'Confirm as read',
    floated: 'right',
    onClick: () => setSGConfirm(!initFalsy),
    primary: !initFalsy,
  };
  const sgModelProp: GenModelProps = {
    actions: (
      <>
        <Button {...sgConfirmButtProps} />
        <Button {...sgCancelButtProps} />
      </>
    ),
    content: (
      <>
        <Guidelines url={pdfUrl} />
      </>
    ),
    initialize: showSG,
    onCancel: onSGClose,
    size: 'fullscreen'
  };

  React.useEffect(pageLoadEffect, []);
  React.useEffect(blockBackEffect, []);

  return (
    <>
      {
        loader &&
        <Loader />
      }

      <Prompt when={isBlocking} message={getBlockingMessage} />

      <Grid id="top">
        <Grid.Row className="headerTop no-print">
          <Grid.Column width={16}>
            <h1 className="mainTitle">Call Info {title}</h1>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column computer={6} tablet={6} mobile={16} className="call-details no-print">
            <CallDetail {...callDetailProps} />
            {
              !isLocked &&
              <CallSurComments header="Comments" items={callComments} />
            }
          </Grid.Column>

          <Grid.Column computer={10} tablet={12} mobile={16}>
            <CallSurvey {...surveyProps} />
          </Grid.Column>
        </Grid.Row>

        {
          escalation &&
          <Model {...escModelProps} />
        }
        {
          pdfUrl && pdfUrl !== strEmpty &&
          <Model {...sgModelProp} />
        }

        {
          sgConfirm &&
          <Confirm {...fcConfirmProps} />
        }
      </Grid>
    </>
  );
};

const propsMapping = (store: StoreT) => {
  return {
    // assignment: store.assignments,
    languages: store.languages,
    visitCall: store.visitCall,
    user: store.auth.user
  };
}

export default connect(propsMapping)(SurProjectCalls);
