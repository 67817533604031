import React from 'react';
import MultiSelectListBox from 'react-multiselect-listbox'
import {ServiceRecovery, SRKeys, SRList, T} from '../../model';
import {Button, Card, ChkBox, Grid, Input} from '../../components/Inputs';
import {initFalsy} from '../../constants';
import {MiniLoader} from '../Loader';
import {va} from '../../utils/arrayUtils';
import {piz} from '../../utils/numUtils';
import {sleep} from '../../utils/objectUtils';
import {flc} from '../../utils/stringUtils';


type srProps = {
  isEditSrReport?: boolean | number;
  projectId: number;
  onSave: Function;
  serviceRecoveriesHist: SRList;
  srHeaderKeys: Array<SRKeys>;
  srKeysLoader: boolean;
};

const srLoaderStyle = {
  height: '440px',
  width: '100%'
};

const emptyStr = [] as string[];
const SRQuestionnaire = (props: srProps) => {
  const {onSave, isEditSrReport, projectId, serviceRecoveriesHist, srHeaderKeys, srKeysLoader} = props;
  const [filename, setFilename] = React.useState(null);
  const [isSaving, setSaving] = React.useState(initFalsy);
  const [onlySr, setOnlySr] = React.useState(!initFalsy);
  const [srKeys, setSRKeys] = React.useState(emptyStr);
  const onSearch = ({items, query, textField}: T) => {
    return items.filter((item: T) => flc(item[textField]).includes(flc(query)))
  };

  const onSubmit = async () => {
    setSaving(!initFalsy);
    const payload = { projectId: piz(projectId), onlySr, srKeys, filename };

    try {
      await sleep();
      await onSave(payload);
      setSRKeys(payload.srKeys);
    } finally {
      setSaving(initFalsy);
    }
  };
  const listBoxProps = {
    onRemove: ({item}: T) => {
      setSRKeys([...srKeys.filter((i: string) => i !== item.value)]);
    },
    onRemoveAll: () => {
      setSRKeys([]);
    },
    onSearch,
    onSelect: ({sortedList}: T) => {
      setSRKeys(sortedList.map((i: T) => i.value));
    },
    onSelectAll: (selectedItems: T) => {
      const selected: T = [...srKeys, ...selectedItems.map((item: T) => item.value)];
      setSRKeys(selected);
    },
    options: va(srHeaderKeys) ? srHeaderKeys.map(({field}) => ({field, value: field})) : [],
    rowHeight: 30,
    sortable: !initFalsy,
    onSort: ({ sortedList}: T) => setSRKeys([...sortedList.map((i:T) => i.value)]),
    textField: 'field',
    value: srKeys,
    valueField: 'field',
  };
  const onHandleChange = (e: T) => {
    const val = e.target.value;
    val ? setFilename(e.target.value) : setFilename(null)
  }
  const formFields: T[] = [
    {
      component: Button,
      computer: 4,
      content: 'Save',
      disabled: (isSaving || !filename),
      icon: 'save',
      loading: isSaving,
      onClick: onSubmit,
      primary: !initFalsy,
      tablet: 4
    },
    {
      checked: onlySr,
      className: 'mt10',
      component: ChkBox,
      computer: 4,
      label: 'Only SR',
      onChange: () => setOnlySr(!onlySr),
      tablet: 4,
      toggle: !initFalsy
    },
    {
      component: Input,
      computer: 4,
      label: 'FileName',
      name: 'name',
      tablet: 4,
      onChange: (e: T) => onHandleChange(e),
      value: filename
    }
  ];

  const srQues = () => {
    const {rows} = serviceRecoveriesHist || {};
    if (va(rows)) {
      if (isEditSrReport) {
        const editSrKeys: T = rows.find((i: ServiceRecovery) => i.id === isEditSrReport);
        const srKeys =va(editSrKeys?.srKeys) ? editSrKeys?.srKeys : emptyStr;
        setFilename(editSrKeys?.name);
        setOnlySr(editSrKeys?.onlySr);
        setSRKeys(srKeys);
      } else {
        setSRKeys(rows[rows.length - 1].srKeys);
      }
    }
  };

  React.useEffect(srQues, [isEditSrReport])

  return (
    <>
      {
        srKeysLoader &&
        <MiniLoader style={srLoaderStyle}/>
      }
      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <Card fluid>
              <Card.Content>
                <Grid.Row>
                  <Grid.Column className="multi-select mb20" width={16}>
                    <MultiSelectListBox {...listBoxProps} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  {
                    formFields.map(({tablet, component, computer, ...fieldProps}, key) => (
                      <Grid.Column computer={computer} floated="right" key={key} mobile={16} tablet={tablet}>
                        {
                          React.createElement(component, fieldProps)
                        }
                      </Grid.Column>
                    ))
                  }
                </Grid.Row>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
};

export default SRQuestionnaire;
