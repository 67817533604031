import React from 'react';
import {DropEvent, DropzoneOptions, useDropzone} from 'react-dropzone';
import SparkMD5 from 'spark-md5';
import {Grid, Icon, Progress} from './index';
import {AfterFileDrop, FileUploadProps} from '../../model';
import {Loader} from '../index';
import {isFunction} from '../../utils/common';

type onDrop = (acceptedFiles: File[], rejectedFiles: File[], event: DropEvent) => void;

interface DZ {
  accept?: string;
  afterDrop: Function;
  fileType?: FileType;
  loading?: boolean;
  onDrop?: onDrop;
}

export enum FileType {
  Roster = 'roster',
  Guideline = 'guideline'
}

const DropZone = (props: DZ) => {
  const [progress, setProgress] = React.useState(0);
  const onDrop: onDrop = props.onDrop && isFunction(props.onDrop) ?
    props.onDrop :
    async (acceptedFiles: FileUploadProps[]) => {
      const
        reader = new FileReader(),
        file = acceptedFiles[0],
        fileMDFBuffer = new SparkMD5.ArrayBuffer();

      reader.onprogress = ({loaded, total}) => {
        const progress = loaded / total * 100;
        setProgress(progress);
      };
      reader.onerror = console.error;
      reader.onload = async () => {
        fileMDFBuffer.append(reader.result as ArrayBuffer);
        const md5Hash = fileMDFBuffer.end();
        const payload: AfterFileDrop = {
          fileName: file.name,
          file: md5Hash,
          result: reader.result
        }
        await props.afterDrop(payload);
      };

      reader.readAsArrayBuffer(file);
    };
  const dzOpts: Partial<DropzoneOptions> = {accept: props.accept || '.csv,.pdf', onDrop};
  const {getRootProps, getInputProps} = useDropzone(dzOpts as DropzoneOptions);
  const rootProps = getRootProps({className: 'dz-zone'});
  const inputProps = getInputProps();
  const _filetype = props.fileType || 'file';
  const message = `Drag 'n' drop, or click to select a ${_filetype} to upload.`;

  return (
    <>
      {
        props.loading &&
        <Loader />
      }
      <Grid>
        <Grid.Row>
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <section className="dz-container">
              <div {...rootProps}>
                <Icon size="big" name="cloud upload" />
                <input {...inputProps} />
                <p>{message}</p>
              </div>
            </section>
          </Grid.Column>
          {
            !!progress &&
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <Progress percent={progress} />
            </Grid.Column>
          }
        </Grid.Row>
      </Grid>
    </>
  );
};

export default DropZone;
