import React from 'react';
import {Card, Grid} from './Inputs';
import {DEFAULT_ERROR, initFalsy} from '../constants';
import LoginBGImg from '../styles/images/loginImg.png';


type PNF = { isGeneralError?: boolean; };

const Notfound = (props: PNF) => {
  const winLoc = window.location;
  const origin = winLoc.origin;
  const pathName = winLoc.pathname;

  return (
    <Grid centered={!initFalsy} style={{height: '100vh'}} verticalAlign="middle">
      <Grid.Row>
        <Card>
          <Card.Description>
            <Grid.Column className="mt20" width={16}>
              {
                !props.isGeneralError &&
                <div>
                  <h3>
                    The page&nbsp;
                    <small>(<code>{origin}{pathName}</code>)</small>
                    &nbsp;you are looking for is not found

                  </h3>
                  <p>
                    It may have been moved, or removed altogether.
                    Perhaps you can return back to the site's homepage and see if you can find what you are looking for. </p>
                </div>

              }

              {
                props.isGeneralError &&
                <h3>
                  {DEFAULT_ERROR}
                </h3>
              }

              <img className="mt20 loginImg img-fluid" src={LoginBGImg} alt="cr-no-found-url" />
            </Grid.Column>
          </Card.Description>
        </Card>
      </Grid.Row>
    </Grid>
  )
};

export default Notfound;
