import {ActionType, ApiEngine, CleanResponse, T} from '../../model';
import {setAlert, setSuccess} from './alert';


type FAHWD = {projectId: number; surveyorId : number; weekDate: string;};
type UAHWD = {id: number; weeklyCount: number;};

const apiPath = 'assignment';
const hisApiPath = 'assignment-history';

/**
 * Flush Assignments
 */
export const flushAssignments = () => async (dispatch: Function) => {
  try {
    return dispatch({type: ActionType.OPS_ASSIGNMENTS_FLUSH, payload: []});
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Assignments
 * @param projectId
 * @param managerId
 */
export const fetchAssignments = (projectId: number, managerId: number |undefined = undefined, orderBy: string | undefined, orderDir: string | undefined) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const q = {projectId, managerId, orderBy, orderDir};
    const assignments = await api.get(apiPath, null, q);
    const _assignments = assignments.map((a: T) => ({...a, name: a.surveyor.firstName, email: a.surveyor.email}));
    dispatch({type: ActionType.OPS_ASSIGNMENTS_LIST, payload: _assignments});
    return _assignments;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Assign Surveyor to Project
 * @param payload
 */
export const assignProjectSurveyor = (payload: T) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const res = await api.post(apiPath, payload);
    dispatch({type: ActionType.OPS_ASSIGN_SURVEYORS, payload: res});
    return payload;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Delete Surveyor to Project Assignment
 * @param id
 */
export const deleteAssignment = (id: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const response: CleanResponse = await api.delete(apiPath, {id});
    const assignments: T = _store().assignments;
    const selectedAssignment = assignments && assignments.findIndex((i: T) => i.id === id);
    if (selectedAssignment >= 0) {
      assignments.splice(selectedAssignment, 1);
      dispatch({type: ActionType.OPS_ASSIGNMENTS_LIST, payload: assignments});
    }
    dispatch(setSuccess(response.message));
  } catch (err) {
    dispatch(setAlert(err))
  }
};

/**
 * Update Surveyor to Project Assignment / Assignment history
 * @param payload
 */
export const updateAssignment = (payload: { id: number, weeklyGoal: number, type: string }) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    await api.put(apiPath, payload);
  } catch (err) {
    dispatch(setAlert(err))
  }
};

/**
 * ==================== Fetch/Save/Update Assignment History ====================
 */

/**
 * Fetch/Create Assignment History
 */
export const fetchAssignmentsHistory = (data: { assignmentId: number, isAdd: boolean, projectId: number, weekGoals: number }) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const payload = await api.post(hisApiPath, data);
    dispatch({type: ActionType.ASSIGNMENT_HISTORY_BULK_ADD, payload: payload});
    return payload;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Update Assignment History
 */
export const updateAssignmentsHistory = (data: { id: number, value: number, assignmentId: number, weekDate: number }) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const payload = await api.put(hisApiPath, data);
    dispatch({type: ActionType.ASSIGNMENT_HISTORY_UPDATE, payload: payload});
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Delete Assignment History
 */
export const deleteAssignmentsHistory = (id: number, asId: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const data = {id, assignmentId: asId};
    const payload = await api.delete(hisApiPath, data);
    dispatch({type: ActionType.ASSIGNMENT_HISTORY_DELETE});
    dispatch(setHistAfter(payload));
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Add Assignment History New Week
 * @param data {assignmentId, projectId}
 * @returns payload
 */
export const addNewAssignHisWeek = (data: {assignmentId: number, projectId: number, weekDate: string}) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const hisApiAddWeekPath = `${hisApiPath}/add-week`;
    const payload = await api.post(hisApiAddWeekPath, data);
    dispatch({type: ActionType.ASSIGNMENT_HISTORY_ADD});
    dispatch(setHistAfter(payload));
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
}

const setHistAfter = (payload: T) => async (dispatch: Function) => {
  dispatch({type: ActionType.ASSIGNMENT_HISTORY_FETCH, payload});
  return;
}

/**
 * Fetch Assignment History Weekly Data
 * @param data 
 */
export const getAssignHistWeeklyData = (data: FAHWD) => async(dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const {projectId, surveyorId, weekDate} = data;
    const query = {projectId, surveyorId, weekDate};
    const payload = await api.get(`${hisApiPath}/week-data`, null, query);
    dispatch({type: ActionType.ASSIGNMENT_HISTORY_WEEKLY_DATA, payload});
    return payload;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Update Assignment History Weekly Data
 * @param data 
 */
export const updateAssignHistWeeklyData = (data: UAHWD) => async(dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const payload = await api.put(`${hisApiPath}/week-data`, data);
    return payload;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Flush Assignment history
 */
export const flushAssignmentsHistory = () => async (dispatch: Function) => {
  try {
    return dispatch({type: ActionType.ASSIGNMENT_HISTORY_FLUSH, payload: []});
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Flush Assignment History Weekly Count
 */
export const flushAssignmentHistWeeklyCount = () => async (dispatch: Function) => {
  try {
    return dispatch({type: ActionType.ASSIGNMENT_HISTORY_WEEKLY_DATA_FLUSH, payload: null});
  } catch (err) {
    dispatch(setAlert(err));
  }
};
