import React from 'react';
import {useHistory, useLocation, useParams} from 'react-router';
import {Billing, Details, Notes} from '../Forms';
import {Form, FormWrap, Grid} from '../Inputs';
import {ButtonProps, FormikValues, FormProps, Project, T} from '../../model';
import {Path} from '../../routes';
import * as projectActions from '../../redux/actions/projects';
import {useActions} from '../../redux/actions';
import {va} from '../../utils/arrayUtils';
import {vo} from '../../utils/objectUtils';
import {projectAddSchema} from '../../utils/validation';
import {initFalsy, Language, MODIFIER_TABLE_TYPE} from '../../constants';


interface AddEditProjectProps {
  isAdd: boolean;
  projectInfo: Project;
}

type PT = React.FC<FormProps & { buttons: ButtonProps[] } & { projectInfo: Project; } & { mode?: string }>[];

interface ProjectFormProps extends FormProps {
  extraProps: AddEditProjectProps
}

const AEPForm: React.FC<ProjectFormProps> = (props: ProjectFormProps) => {
  const
    {mode}: T = useParams(),
    {handleSubmit, isSubmitting, isValid, extraProps: {isAdd, projectInfo}} = props,
    isView = mode === 'view',
    [p, setPage] = React.useState(0),
    hist = useHistory(),
    Pages: PT = [Details, Notes, Billing],
    isLastPage = p === Pages.length - 1,
    onBackOrCancel = (e: React.SyntheticEvent) => {
      if (p === 0) {
        const pathToReturn = isAdd ? Path.AdminClients : Path.AdminProjects;
        hist.push(pathToReturn);
        e.preventDefault();
        return;
      }

      setPage(p - 1)
    },
    onNext = (e: React.SyntheticEvent) => {
      if (isLastPage && !isValid) {
        setPage(0);
      } else {
        setPage(p + 1);
        e.preventDefault();
      }
    },
    submitButton: ButtonProps = {
      className: 'ml15',
      content: isAdd ? 'Submit' : 'Update',
      disabled: isSubmitting,
      loading: isSubmitting,
      primary: !initFalsy,
      type: 'submit'
    },
    nextButton: ButtonProps = {
      className: 'ml15',
      content: 'Next',
      icon: 'angle double right',
      labelPosition: 'right',
      onClick: onNext,
      primary: !initFalsy,
      type: 'button'
    },
    backButton: ButtonProps = {
      content: 'Back',
      icon: 'angle double left',
      labelPosition: 'left',
      onClick: onBackOrCancel,
      type: 'button'
    },
    cancelButton: ButtonProps = {
      content: 'Cancel',
      className: 'ml10',
      onClick: () => {
        hist.push(Path.AdminProjects)
      },
      type: 'button'
    },
    addFormButtons = [backButton, isLastPage ? !projectInfo.deletedAt ? submitButton : cancelButton : nextButton],
    formPageProps = {...props, buttons: addFormButtons, projectInfo, isView};

  if (isView && isLastPage) {
    addFormButtons.splice(addFormButtons.length - 1, 1);
    addFormButtons.push(cancelButton);
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Grid stackable={!initFalsy}>
        {
          React.createElement(Pages[p], formPageProps)
        }
      </Grid>
    </Form>
  )
};

const AddProject: React.FC<AddEditProjectProps> = (props: AddEditProjectProps) => {
  const
    isEdit = vo(props.projectInfo),
    isAdd = props.isAdd,
    loc = useLocation(),
    hist = useHistory(),
    projectA = useActions(projectActions),
    firstRun = () => {
      return () => projectA.flushProjectInfo();
    },
    performSubmitOpn = async (editCase: boolean, values: FormikValues) => {
      if (editCase) {
        const {user, client, languages, ...updPayload} = values;
        return projectA.updateProject(updPayload);
      }

      const {clientInfo}: T = loc.state;
      return projectA.addProject({...values, clientId: clientInfo.id});
    },
    getBillingModifier = (modifier: number) => {
      const pbm = modifiers?.filter(a => a.modifierId === modifier);
      return (va(modifiers) && va(pbm)) ? pbm : [{}];
    };

  React.useEffect(firstRun, []);

  if (isAdd && !isEdit && !loc.state) {
    hist.push(Path.AdminClients)
  }

  const
    {modifiers, ...pro} = props.projectInfo || {},
    langIds = (isAdd ? [Language.English] : pro.languages?.map(l => l.langId)) || [],
    formInitValues = {
      ...pro,
      approvalStatus: pro.approvalStatus || '',
      associateAgreement: pro.associateAgreement || initFalsy,
      clientBuzAdd: pro.clientBuzAdd || '',
      contractDate: pro.contractDate || '',
      isBenchmarked: pro.isBenchmarked || initFalsy,
      managerId: pro.managerId || '',
      name: pro.name || '',
      reportFreqId: pro.reportFreqId || '',
      sampling: pro.sampling ? (pro.sampling as string).split(',') : [],
      startDate: pro.startDate || '',
      statusId: pro.statusId || '',
      typeId: pro.typeId || '',
      billingModifier: getBillingModifier(MODIFIER_TABLE_TYPE['Project Build']),
      paymentModifier: getBillingModifier(MODIFIER_TABLE_TYPE.Payment),
      langIds
    },
    prjFrmConfig = {
      displayName: 'p_frm',
      initialValues: formInitValues,
      onSubmit: async (values: FormikValues, {resetForm}: FormikValues) => {
        try {
          await performSubmitOpn(isEdit, values);
          const pathToReturn = isEdit ? Path.AdminProjects : Path.AdminClients;
          hist.push(pathToReturn);
        } catch (err) {
          resetForm();
        }
      },
      children: AEPForm,
      validationSchema: projectAddSchema,
      extraProps: props
    };

  return (
    <>
      <FormWrap {...prjFrmConfig} />
    </>
  );
};


export default AddProject;
