import React from 'react';
import {CLG as CallLogGrid} from '../../components/CallLog'
import {Grid} from '../../components/Inputs'
import {initFalsy} from '../../constants';


const CallLog = () => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={16} mobile={16}>
          <CallLogGrid isManager={initFalsy} isOPS={initFalsy}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};


export default CallLog;
