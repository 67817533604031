import React, {SyntheticEvent} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Form} from './index';
import {SelectProps, SelectValue} from '../../model';
import {noop} from '../../utils/common';
import {initFalsy} from '../../constants';


type V = SelectValue;
const Select: React.FC<SelectProps> = (props: SelectProps) => {
  const
    isTruthy = !initFalsy,
    key = -99,
    {asFastField, hasFirstEmptyOpt, label, name, onChange, options, ...otherProps} = props,
    selOpts = options || [],
    defaultProps = {
      clearable: isTruthy,
      fluid: isTruthy,
      scrolling: isTruthy,
      search: isTruthy,
      selection: isTruthy
    },
    captureOnChange: V = onChange || noop,
    handleChange = (e: SyntheticEvent, data: SelectProps) => {
      captureOnChange(data.value);
      e.preventDefault();
    };

  if (hasFirstEmptyOpt && selOpts.findIndex(i => i.key === key) === -1) {
    selOpts.unshift({key, value: '', text: ''});
  }

  return (
    <Form.Field>
      {
        label &&
        <label>{label}</label>
      }
      <div className={otherProps.disabled ? 'disabled' : ''}>
        <>
          <Dropdown {...defaultProps} {...otherProps} options={selOpts} onChange={handleChange} />
        </>
      </div>
    </Form.Field>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired
};

export default Select;
