import React from 'react'
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {Button, Card, Confirm, Grid, GridView, Icon, TextBox} from '../../components/Inputs';
import {BN, ButtonProps, IconProps, StoreT, T} from '../../model';
import {Loader} from '../../components';
import * as assignActions from '../../redux/actions/assignments';
import {useActions} from '../../redux/actions';
import {CONFIRM_MESSAGES, initFalsy, NA} from '../../constants';
import {calPercentage, fixedFloat, piz} from '../../utils/numUtils';
import {firstDayOfWeek, getDiffTwoDates, usDateFormat} from '../../utils/dateUtils';
import {va} from '../../utils/arrayUtils';

interface AHP {
  assignmentId?: number;
  isAdd: boolean;
  projectId: T;
  weekGoals?: number;
  surveyorId?: number;
}

interface AssignHistoryProps {
  aHistPayload: AHP;
  isLoading: boolean;
  setLoader: Function;
}

interface ETProps {
  assignmentId: number;
  id: number;
  onGoalChange: Function;
  txtValue: number | undefined;
  weekDate: string;
}


const EditText = ({assignmentId, id, onGoalChange, txtValue, weekDate}: ETProps) => {
  const updateWGoals = (id: number, value: number) => {
    const data = {
      assignmentId,
      id,
      value,
      weekDate
    };
    onGoalChange(data);
  };
  return (
    <React.Fragment>
      <TextBox defaultValue={txtValue} onBlur={(e: T) => updateWGoals(id as number, e.target.value)} type="number" />
    </React.Fragment>
  )
}

const ProjectGoals: React.FC<AssignHistoryProps> = ({aHistPayload, isLoading, setLoader}) => {
  let [asId, totalWeeklyCount, lastWeek, totalGaol, totalPercent, dividedBy] = [0, 0, '', 0, 0, 1];
  const
    {id}: T = useParams(),
    projectId = piz(id),
    wData = useSelector((state: StoreT) => state.assignmentsHistory) || [],
    weeklyCount = useSelector((state: StoreT) => state.assignHistoryWeeklyData),
    headers = ['Start Of Week', 'Completed', 'Weekly Goal', 'Percentage', 'Action'],
    [delOpen, showDelConfirm]: [BN, Function] = React.useState(initFalsy),
    [currRow, setCurrRow]: [T, Function] = React.useState(null),
    [deleting, isDeleting]: [boolean, Function] = React.useState(initFalsy),
    [isAssingmentAdd, setIsAdd] = React.useState(!!aHistPayload ? aHistPayload.isAdd : initFalsy),
    assignAC = useActions(assignActions),
    loadAssignmentHist = async () => {
      const {assignmentId, weekGoals} = aHistPayload
      const updPayload = {
        assignmentId, projectId, isAdd: isAssingmentAdd, weekGoals
      };
      await assignAC.fetchAssignmentsHistory(updPayload);
      setLoader(initFalsy);
      setIsAdd(initFalsy);
    },
    onGoalChange = async (data: T) => {
      setLoader(!initFalsy);
      aHistPayload.isAdd && setIsAdd(initFalsy);
      await assignAC.updateAssignmentsHistory(data);
      setLoader(initFalsy);
    },
    performDelOperation = async (id: BN) => {
      isDeleting(!initFalsy);
      showDelConfirm(initFalsy);
      await assignAC.deleteAssignmentsHistory(id, asId);
      isDeleting(initFalsy);
    },
    confirmButton = (
      <Button color="red" content="Yes, Delete" loading={deleting} primary={initFalsy} type="button" />
    ),
    addNewRecord = async () => {
      const currWeek = firstDayOfWeek();
      const {assignmentId} = aHistPayload;
      const weekDate = !!lastWeek ? new Date(lastWeek).addDays()
        .toISODateString() : new Date(currWeek).toISODateString();
      const data = {
        assignmentId: !!asId ? asId : assignmentId,
        projectId,
        weekDate
      };
      setLoader(!initFalsy);
      await assignAC.addNewAssignHisWeek(data);
      setLoader(initFalsy);
    },
    addBtnProps: ButtonProps = {
      icon: 'plus',
      labelPosition: 'left',
      className: 'float-right ml10 mb10',
      primary: !initFalsy
    },
    confirmProps = {
      content: CONFIRM_MESSAGES.ASSIGNMENT_HISTORY_WEEK,
      header: 'Delete Assignment History Week',
      confirmButton,
      open: !!delOpen,
      onCancel: () => showDelConfirm(initFalsy),
      onConfirm: () => performDelOperation(delOpen),
    },
    onReload = async (projectId: number, weekDate: string, key: number) => {
      const data = {
        projectId,
        surveyorId: aHistPayload.surveyorId,
        weekDate: new Date(usDateFormat(weekDate)).toISOString()
      };
      setCurrRow(key as number);
      await assignAC.getAssignHistWeeklyData(data).then()
    },
    onUpdate = async (id: number) => {
      setCurrRow(null);
      const data = {
        id,
        weeklyCount: !!weeklyCount ? weeklyCount : 0,
      };
      await assignAC.updateAssignHistWeeklyData(data)
        .then(
          assignAC.flushAssignmentHistWeeklyCount().then(),
          loadAssignmentHist().then())
        .catch((err: T) => console.error(err))
    },
    wDataLength = wData.length,
    currWeek = firstDayOfWeek(),
    list = va(wData) ? wData.map((i: T, key: number) => {
      const {assignmentId, id, projectId, weekDate, weekCompGoals, weekGoals} = i;
      const weeklyPercent = calPercentage(weekCompGoals, weekGoals);
      const percentage = !isNaN(weeklyPercent) ? fixedFloat(weeklyPercent, 2) : NA;
      totalWeeklyCount += weekCompGoals;
      totalGaol += weekGoals;
      const isDateDiff = getDiffTwoDates(weekDate, currWeek);
      isDateDiff ? dividedBy += 1 : dividedBy += 0;
      totalPercent += piz(percentage);
      asId = assignmentId;
      lastWeek = wDataLength === key + 1 && weekDate;
      const etProps = {
        assignmentId,
        txtValue: weekGoals,
        onGoalChange,
        weekDate,
        id
      };
      const delIcon: {} = {name: 'trash', onClick: () => showDelConfirm(id), title: 'Delete'};
      const getUpdIcon: IconProps = (key === currRow) ?
        {name: 'save', onClick: () => onUpdate(id), title: 'Update'} :
        {name: 'redo', onClick: () => onReload(projectId, weekDate, key), title: 'Reload'};
      const actionIcons: IconProps[] = [getUpdIcon, delIcon];
      const complWeeklyData = (key === currRow) ?
        (weeklyCount ? (<>{weekCompGoals} <b>({weeklyCount})</b></>) : '') :
        weekCompGoals;
      return {
        weekDate: usDateFormat(weekDate),
        wcg: complWeeklyData,
        wg: (<EditText {...etProps} />),
        totalCumPercent: percentage !== NA ? `${percentage} %` : NA,
        action: (
          <>
            {
              actionIcons.map((iconProps: IconProps, key: number) => (
                <Icon key={key} className="hand-pointer" {...iconProps} />
              ))
            }
          </>
        )
      }
    }) : [],
    vaList = va(list),
    footerData = {
      weekDate: '',
      weekCompGoals: vaList ? <b>{totalWeeklyCount}</b> : '',
      weekGoals: vaList ? totalGaol : '',
      percentage: vaList ? `${fixedFloat(totalPercent / dividedBy, 1)} %` : '',
      action: (
        <Button {...addBtnProps} content="Add Week" onClick={addNewRecord} />
      )
    },
    totalRow = vaList ? [
      {
        weekDate: '',
        weekCompGoals: <b>Total Complete</b>,
        weekGoals: <b>Total Goal</b>,
        percentage: <b>Average Percentage</b>,
        action: '',
      },
      footerData
    ] : [
      footerData
    ],
    firstRun = () => {
      loadAssignmentHist().then();
    };

  React.useEffect(firstRun, []);

  return (
    <>
      {
        isLoading && <Loader />
      }

      {
        <Grid className="project-details-tab" columns="five">
          <Grid.Row className="pt10">
            <Grid.Column width={16}>
              <Card fluid>
                <Card.Content>
                  <GridView footer={totalRow} headers={headers} list={list} />
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
          {
            delOpen &&
            <Confirm {...confirmProps} />
          }
        </Grid>
      }
    </>
  );
}

export default ProjectGoals;
