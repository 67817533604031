import React from 'react';
import {ProjectSampling} from '../../components/Projects';


const PMSamplingPage: React.FC = () => {
  return (
    <ProjectSampling />
  );
};


export default PMSamplingPage;
