import {ActionType, Alert, ApiEngine, CleanResponse, SurveyExport, T} from '../../model';
import {setAlert, setSuccess} from './alert';
import {getArrObjDiff, va} from '../../utils/arrayUtils';
import {initFalsy} from '../../constants';
import {downloadAsCSV, jsonToCsv} from '../../utils/common';
import {processASCSV} from '../../utils/surveyCsv';
import {pez} from '../../utils/stringUtils';

type SRData = { from: string; isDownload: boolean; onlySr: boolean; projectId: number; srKeys: string[]; to: string; filename?: string };
type expSRReport = { id?: number, from?: string, to?: string };
const apiPath = 'service-recoveries';

/**
 * Fetch Service Recoveries
 * @param skip
 * @param limit
 * @param orderBy
 * @param orderDir
 * @param managerId
 * @returns
 */
export const fetchSRProjects = (skip: number, limit: number, orderBy: string, orderDir: string, managerId?: number, search?: string) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const query = {limit, managerId, orderBy, orderDir, skip, search};
    const result = await api.get(apiPath, null, query);

    return dispatch({type: ActionType.SR_PROJECTS_FETCH, payload: result});
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Service Recoveries Question List
 * @returns
 * @param projectId
 */
export const fetchSQuestionList = (projectId: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const query = {projectId};
    const path = `${apiPath}/header-keys`;
    const result = await api.get(path, null, query);
    return dispatch({type: ActionType.SR_HEADER_KEYS, payload: result});
  } catch (err) {
    dispatch(setAlert(err));
  }
};

export const saveSrData = (payload: SRData) => async (dispatch: Function, _store: Function, _api: ApiEngine) => {
  try {
    const {srKeys} = payload;
    if (!va(srKeys)) {
      const message = 'No Header Keys Selected';
      dispatch(setAlert({message} as Alert));
    }

    const result = await _api.post(apiPath, payload);
    return dispatch({type: ActionType.SR_SAVE, payload: result});
  } catch (err) {
    dispatch(setAlert(err));
  }
};

export const downloadSrDReport = (payload: SRData) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    if (!va(payload.srKeys)) {
      const message = 'No Header Keys Selected';
      dispatch(setAlert({message} as Alert));
    }

    const result: CleanResponse = await api.post(apiPath, payload);
    dispatch(setSuccess(result.message));
    dispatch({type: ActionType.SR_DOWNLOAD, payload: result});
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * fetchSRList
 * @returns payload
 */
export const fetchSRList = (skip: number, limit: number, orderBy: string, orderDir: string, projectId: number, managerId?: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const query = {limit, managerId, orderBy, orderDir, projectId, skip};
    const result = await api.get(apiPath, null, query);
    return dispatch({type: ActionType.SR_FETCH, payload: result});
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Create Csv File For Service Recovery
 * @returns file
 */
export const exportSRReport = (payload: expSRReport) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const date = new Date().toUSDateString('', !initFalsy);
    const {id, from, to} = payload;
    const query = {id, from, to};
    const {data, message} = await api.post(`${apiPath}/report`, query);

    if (!data || !va(data.surveyData)) {
      return dispatch(setAlert({message} as Alert));
    }

    const formattedSurveyData: T = processASCSV(data);
    const {srKeys, name} = data;
    const pName = srKeys.indexOf('Patient Name');
    const lang = srKeys.indexOf('Language of Survey');
    const dept = srKeys.indexOf('Department');
    const site = srKeys.indexOf('Site')

    if (pName !== -1) {
      srKeys[pName] = 'Patient Roster Name';
    }
    if (lang !== -1) {
      srKeys[lang] = 'Survey Language';
    }
    if (dept !== -1) {
      srKeys[dept] = 'Department Name';
    }
    if (site !== -1) {
      srKeys[site] = 'Site Name';
    }

    const result = formattedSurveyData.map((i: SurveyExport) => getArrObjDiff(data.srKeys, i));
    const fileTitle = `${pez(name, apiPath)}-${date}`;

    downloadAsCSV(fileTitle, jsonToCsv(result, !initFalsy));

    return !initFalsy;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Delete SR History Report
 * @returns Message
 */
export const delSrReport = (data: expSRReport) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const payload = {id: data.id};
    const res: CleanResponse = await api.delete(apiPath, payload)
    setSuccess(res.message);
    return res;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/***
 * Flush Service Recoveries
 */
export const flushServiceRecoveries = () => async (dispatch: Function) => {
  return dispatch({type: ActionType.SR_FLUSH, payload: null});
};

/***
 * Flush Service Recoveries
 */
export const flushSRProjects = () => async (dispatch: Function) => {
  return dispatch({type: ActionType.SR_PROJECTS_FLUSH, payload: null});
};

/***
 * Flush Service Recoveries
 */
export const flushSRKeys = () => async (dispatch: Function) => {
  return dispatch({type: ActionType.SR_HEADER_FLUSH, payload: []});
};
