import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router';
import {BtnSwitch, Card, Grid, GridView, Icon, Paging, ProgressGoals} from '../../components/Inputs';
import {Loader} from '../../components';
import {DEFAULT_RECORDS_PER_PAGE, GoalsHeader, initFalsy, ROLES} from '../../constants';
import {ButtonProps, IconProps, ProjectsList, ST, StoreT, User} from '../../model';
import {Path} from '../../routes';
import {useActions} from '../../redux/actions';
import * as projectActions from '../../redux/actions/projects';
import {va} from '../../utils/arrayUtils';
import {getSkipLimit} from '../../utils/common';

type P = { auth: User; projects: ProjectsList; }

enum SGD {
  Guidelines = 'Guidelines',
  Details = 'Details'
}

const SurProjects = (props: P) => {
  const hist = useHistory();
  const user = props.auth.user;
  const projectA = useActions(projectActions);
  const initPageNo = 1;
  const [isWeeklySelected, setWeekly] = React.useState(!initFalsy);
  const goalsHeader = isWeeklySelected ? `${GoalsHeader.Weekly} / ${GoalsHeader.WeeklyTotal}` : `${GoalsHeader.RP} / ${GoalsHeader.RPTotal}`;
  const headers = ['Project Name', goalsHeader, 'Action'];
  const sortKeys = ['name'];
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [loader, isLoading] = React.useState(initFalsy);
  const banIconProps: IconProps = {name: 'dont', size: 'large'};
  const reportButtProps: ButtonProps[] = [
    {active: isWeeklySelected === !initFalsy, content: 'Current Week', onClick: () => onChangeType(!initFalsy)},
    {as: 'div', className: 'or', key: 'or'},
    {active: isWeeklySelected === initFalsy, content: 'Reporting Period', onClick: () => onChangeType(initFalsy)}
  ];
  const cBanIconProps: IconProps = {
    className: 'calls-ban-icon',
    name: 'call',
    size: 'small',
    title: 'Call Not Allowed'
  };
  const banIconGrp = (
    <Icon.Group className="banIcon">
      <Icon {...banIconProps} />
      <Icon {...cBanIconProps} />
    </Icon.Group>
  );
  const callIconProps: IconProps = {className: 'hand-pointer', name: 'call', title: 'Call'};
  const sgIconProps: IconProps = {className: 'hand-pointer', name: 'file pdf', title: SGD.Guidelines};
  const settingIconProps: IconProps = {className: 'hand-pointer', name: 'cog', title: SGD.Details};
  const onChangeType = (isWeekly: boolean) => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, sortKeys[0], 'ASC', isWeekly).then();
    setWeekly(isWeekly);
  };
  const onCall = (pid: number) => {
    // Push to call page
    // hist.push(`${Path.SurveyorProjectCall}/${pid}`);
    const isSurveyor: boolean = user.role === ROLES.Surveyor;
    const isCIMRSurveyor: boolean = user.role === ROLES['CIMR Surveyor'];
    const path = isSurveyor ? `${Path.SurveyorProjectSurvey}/${pid}` : isCIMRSurveyor ? `${Path.CCSProjectSurvey}/${pid}` : `${Path.KellerSurveyorProjectSurvey}/${pid}`;
    hist.push(path);
  };

  const onPushToPath = (pushTo: string, prjId: number) => {
    let path = '';

    const isSurveyor: boolean = user.role === ROLES.Surveyor;
    const isCIMRSurveyor: boolean = user.role === ROLES['CIMR Surveyor'];

    switch (pushTo) {
      case SGD.Guidelines:
        path = (isSurveyor) ? `${Path.SurveyorGuidelines}/${prjId}` : (isCIMRSurveyor) ? `${Path.CCSGuidelines}/${prjId}`: `${Path.KellerSurveyorGuidelines}/${prjId}`;
        break;
      case SGD.Details:
        path = (isSurveyor) ? `${Path.SurveyorProjects}/${prjId}` : (isCIMRSurveyor) ? `${Path.CCSProjects}/${prjId}` : `${Path.KellerProjects}/${prjId}`;
        break;
    }

    hist.push(path);
  };
  const fetchProjects = async (skip = 0, lim = DEFAULT_RECORDS_PER_PAGE, sortOn = 'name', sortBy = 'ASC', isWeekly = !initFalsy) => {
    isLoading(!initFalsy);
    await projectA.fetchSurveyorProjects(skip, lim, sortOn, sortBy, user.id, isWeekly);
    isLoading(initFalsy);
  };
  const navFux = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    fetchProjects(skip, gridSize, sortKeys[0], 'ASC', isWeeklySelected).then();
  };
  const onSort = (cc: string, direction: ST) => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, cc, direction).then();
  };
  const fcl = () => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit).then();

    return () => projectA.flushProjects();
  };

  useEffect(fcl, []);

  const {rows, count} = props.projects || {};
  const projects = va(rows) ? rows.map(({assignment, id, isLocked, name}) => {
    return {
      _id: id,
      _lock: isLocked,
      name,
      progress: (
        <ProgressGoals
          compGoals={assignment?.surveyorCompGoals}
          reqGoals={assignment?.surveyorGoals}
          surveysRP={assignment?.surveysRP}
        />
      ),
      Action: (
        <>
          {
            isLocked ? banIconGrp : <Icon {...callIconProps} onClick={() => onCall(id as number)} />
          }
          <Icon {...sgIconProps} onClick={() => onPushToPath(SGD.Guidelines, id as number)} />
          <Icon {...settingIconProps} onClick={() => onPushToPath(SGD.Details, id as number)} />
        </>
      )
    }
  }) : [];


  return (
    <>
      {
        loader &&
        <Loader />
      }

      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">My Projects</h1>
            <BtnSwitch buttonProps={reportButtProps} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <Card fluid={!initFalsy}>
              <Card.Content>
                <GridView headers={headers} list={projects} onSort={onSort} sortKeys={sortKeys} />

                <Paging
                  activePage={activePage}
                  navigate={navFux}
                  pageSize={limit}
                  setPageChange={setPageChange}
                  setPageSize={setPageSize}
                  totalRecords={count}
                />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: StoreT) => {
  return {
    auth: state.auth,
    projects: state.projects
  };
};

export default connect(mapStateToProps)(SurProjects);
