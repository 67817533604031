import createReducer from './createReducer';
import {Action, ActionType, SRKeys, SRList, SRProjectsList} from '../../model';

const initSRProjects: SRProjectsList | null = null;
const initSRKeys: { field: string } [] = [];
const initServiceRecoveries: SRList | null = null;

export const serviceRecoveries = createReducer(initServiceRecoveries, {
  [ActionType.SR_FETCH](_store: Function, action: Action<SRList>) {
    return action.payload;
  },

  [ActionType.SR_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  }
});

export const srProjects = createReducer(initSRProjects, {
  [ActionType.SR_PROJECTS_FETCH](_store: Function, action: Action<SRProjectsList>) {
    return action.payload;
  },

  [ActionType.SR_PROJECTS_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  }
});

export const srHeaderKeys = createReducer(initSRKeys, {
  [ActionType.SR_HEADER_KEYS](_store: Function, action: Action<SRKeys>) {
    return action.payload;
  },

  [ActionType.SR_HEADER_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  }
});
