import createReducer from './createReducer';
import {Action, ActionType, Client, Project, ProjectsList, weeklyEarningData, VisitInfo} from '../../model';


const initProjectInfo: Partial<Project> = {};
const initProjects: ProjectsList | null = null;
export const projects = createReducer(initProjects, {
  [ActionType.PULL_PROJECTS](_store: Function, action: Action<ProjectsList>) {
    return action.payload;
  },

  [ActionType.PROJECTS_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },

  [ActionType.ADMIN_PROJECTS_ADD](_store: Function, action: Action<Project>) {
    return action.payload;
  },

  [ActionType.ADMIN_DEL_PROJECT](_store: Function, action: Action<Project>) {
    return action.payload;
  }
});


export const projectInfo = createReducer(initProjectInfo, {
  [ActionType.ADMIN_PROJECT_INFO](_store: Function, action: Action<Client>) {
    return action.payload;
  },

  [ActionType.ADMIN_PROJECT_INFO_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  }
});


export const weeklyEarnings = createReducer([], {
  [ActionType.PROJECT_WEEKLY_DETAILS](_store: Function, action: Action<weeklyEarningData>) {
    return action.payload;
  },

  [ActionType.PROJECT_WEEKLY_DETAILS_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  }
});


export const visitInfos = createReducer([], {
  [ActionType.PM_VISIT_INFO](_store: Function, action: Action<VisitInfo>) {
    return action.payload;
  },

  [ActionType.PM_VISIT_INFO_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  }
});
