import {Reducer} from 'redux';
import {Action, T} from '../../model';

export default function createReducer<S>(
  initialState: S,
  handlers: T
): Reducer<S> {
  const r = (state: S = initialState, action: Action<S>): S => {
    return handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
  };

  return r as Reducer<S>;
};
