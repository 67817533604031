import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {SRQuestion} from './index';
import {Loader} from '../';
import {List} from '../Generic';
import {Button, Card, Confirm, DatePicker, Form, Grid, Icon} from '../Inputs';
import {CONFIRM_MESSAGES, DEFAULT_RECORDS_PER_PAGE, initFalsy, ROLES, SortDir} from '../../constants';
import {BN, ButtonProps, IconProps, ServiceRecovery, ST, StoreT, T} from '../../model';
import {useActions} from '../../redux/actions';
import * as serviceRecoveriesActions from '../../redux/actions/serviceRecoveries';
import {va} from '../../utils/arrayUtils';
import {getSkipLimit} from '../../utils/common';
import {addDays, firstDayOfWeek, setSeconds, usDateFormat} from '../../utils/dateUtils';


const SRFilters = ({datesFilter, showHideBtnProps}: T) => {
  return (
    <Card fluid>
      <Card.Content>
        <Form>
          <Grid>
            <Grid.Row>
              {
                datesFilter.map((fProps: T, i: number) => {
                  return (
                    <Grid.Column key={i} mobile={16} tablet={7} computer={7}>
                      <DatePicker {...fProps} />
                    </Grid.Column>
                  );
                })
              }
              <Grid.Column className="mt25" mobile={16} tablet={2} computer={2}>
                <Button {...showHideBtnProps} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Card.Content>
    </Card>
  )
}

const SRDetails = () => {
  const initPageNo = 1;
  const confirmSRHeader = 'Client Data Export';
  const {id}: T = useParams();
  const {serviceRecoveriesHist, srHeaderKeys, user} = useSelector((state: StoreT) => ({
    user: state.auth.user,
    srHeaderKeys: state.srHeaderKeys,
    serviceRecoveriesHist: state.serviceRecoveries
  }));
  const {rows, count} = serviceRecoveriesHist || {};
  const currWeek = firstDayOfWeek();
  const {
    delSrReport,
    exportSRReport,
    fetchSRList,
    fetchSQuestionList,
    flushSRKeys,
    flushServiceRecoveries,
    saveSrData
  } = useActions(serviceRecoveriesActions);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [fromDate, setFromDate] = React.useState(new Date(addDays(currWeek, -7)).toISOString());
  const [toDate, setToDate] = React.useState(new Date(addDays(currWeek, -1)).toISOString());
  const [isSRQVisible, setSRQVisible] = React.useState(initFalsy);
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [loader, isLoading] = React.useState(!initFalsy);
  const [srKeysLoader, setSrKeysLoader] = React.useState(initFalsy);
  const [downloadConfirm, setDownloadConfirm] = React.useState(initFalsy);
  const [text, setText] = React.useState('')
  const [exportVal, setExportVal] = React.useState({});
  const [isModalDel, setModalDel] = React.useState(initFalsy);
  const [reportLoading, setReportLoading] = React.useState(initFalsy);
  const [isEditSrReport, setEditSrReport]: [BN, Function] = React.useState(initFalsy);
  const skip = getSkipLimit(activePage, limit);
  const sortKeys = ['client.name', 'project.name', 'name', 'downloadedOn'];
  const datesFilter = [
    {label: 'From', placeholder: 'From', name: 'fromDate', value: fromDate, setValue: setFromDate},
    {label: 'To', placeholder: 'To', name: 'toDate', value: toDate, setValue: setToDate}
  ];
  const headers = [
    'Client Name',
    'Project Name',
    'Name',
    'Download Date',
    'From - To',
    'Download Client Report'
  ];
  const getListBtnProps: ButtonProps = {
    content: 'Filter',
    primary: !initFalsy,
    onClick: () => fetchReport(),
    title: 'Get History'
  };
  const showHideBtnProps: ButtonProps = {
    circular: !initFalsy,
    compact: !initFalsy,
    icon: isSRQVisible ? 'list alternate outline' : 'triangle down',
    onClick: () => {
      setSRQVisible(!isSRQVisible);
      setEditSrReport(initFalsy);
    },
    primary: !initFalsy,
    title: 'Access Header Keys'
  };
  const fetchRecoveriesList = async (skip: number, lim: number, sortOn: string, sortBy: string) => {
    isLoading(!initFalsy);
    const managerId = user.role === ROLES['Project Manager'] ? user.id : undefined;
    await fetchSRList(skip, lim, sortOn, sortBy, id as number, managerId);
    isLoading(initFalsy);
  }
  const firstEffect = () => {
    fetchRecoveriesList(skip, limit, sortKeys[0], SortDir.ASC)
      .then(() => { 
        setSrKeysLoader(!initFalsy)
        fetchSQuestionList(id)
          .then(() => setSrKeysLoader(initFalsy))
      });

    return () => {
      flushServiceRecoveries();
      flushSRKeys();
    }
  };
  const navFux = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    fetchRecoveriesList(skip, gridSize, sortKeys[0], SortDir.ASC)
      .then();
  };
  const onSort = (cc: string, direction: ST) => {
    const skip = getSkipLimit(activePage, limit);
    fetchRecoveriesList(skip, limit, cc, direction)
      .then();
  };
  const fetchReport = () => {
    fetchRecoveriesList(0, DEFAULT_RECORDS_PER_PAGE, sortKeys[0], SortDir.ASC)
      .then();
  };
  const onAction = async (id: number, isDownLoad?: boolean) => {
    setModalDel(initFalsy);
    const message = !isDownLoad ? `Delete ${confirmSRHeader}` : `${confirmSRHeader} From ${usDateFormat(fromDate)} To ${usDateFormat(toDate)}`;

    if (!isDownLoad) {
      setModalDel(!initFalsy);
      setText(message);
      setExportVal({id});
    }

    setText(message)
    setDownloadConfirm(!initFalsy);
    const ed = setSeconds(new Date(addDays(toDate, 1)), -1).toISOString();
    setExportVal({id, from: fromDate, to: ed});
  };
  const onConfirmDelOrExport = () => {
    setReportLoading(!initFalsy)

    if (!isModalDel) {
      exportSRReport(exportVal)
        .then((res: boolean) => {
          if (res) {
            fetchRecoveriesList(skip, limit, sortKeys[0], SortDir.ASC).then();
            setReportLoading(initFalsy)
            setDownloadConfirm(initFalsy);
          }
        });
    } else {
      delSrReport(exportVal)
        .then((res: T) => {
          if (res) {
            fetchRecoveriesList(skip, limit, sortKeys[0], SortDir.ASC).then();
            setReportLoading(initFalsy)
            setDownloadConfirm(initFalsy);
          }
        });
    }
  };
  const onSave = async (payload: T) => {
    await saveSrData(payload)
      .then(() => {
        fetchRecoveriesList(0, DEFAULT_RECORDS_PER_PAGE, sortKeys[0], SortDir.ASC);
      })
      .finally(() => {
        isLoading(initFalsy);
        setSRQVisible(initFalsy);
        setEditSrReport(initFalsy);
      });
  };
  const deleteSrProp: IconProps = {className: 'mt-5 hand-pointer', name: 'trash alternate', title: 'Delete SR Report'};
  const getByDateIconProp: IconProps = {
    className: 'mt-5 hand-pointer',
    name: 'download',
    title: 'Download Selected SR Report'
  };
  const editIconProp: IconProps = {
    className: 'mt-5 hand-pointer',
    name: 'edit',
    title: 'Edit SR Report'
  };
  const onEdit = (id: number) => {
    setSRQVisible(initFalsy);
    setEditSrReport(id);
  };
  const list = va(rows) ? rows.map((srHistory: ServiceRecovery) => {
    const {downloadedOn, fromTo, id, name, project} = srHistory;

    return {
      clientName: project.client.name,
      projectName: project.name,
      name,
      downloadedOn: downloadedOn ? usDateFormat(downloadedOn) : '',
      fromTo: fromTo ? fromTo : '',
      action: (
        <>
          <Icon {...editIconProp} onClick={() => onEdit(id)} />
          <Icon {...getByDateIconProp} onClick={() => onAction(id, !initFalsy)} />
          <Icon {...deleteSrProp} onClick={() => onAction(id)} />
        </>
      )
    };
  }) : [];
  const gridProps = {headers, list, onSort, sortKeys};
  const pgProps = {activePage, navigate: navFux, pageSize: limit, setPageChange, setPageSize, totalRecords: count};
  const srPanelProps = {onSave, projectId: id, isEditSrReport, serviceRecoveriesHist, srHeaderKeys, srKeysLoader};
  const getReportBtn = (
    isModalDel ?
      <Button color="red" content="Yes, Delete" loading={reportLoading} primary={initFalsy} type="button" /> :
      <Button content="Download" loading={reportLoading} primary={!initFalsy} type="button" />
  );
  React.useEffect(firstEffect, []);

  return (
    <>
      {
        loader &&
        <Loader />
      }

      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">Client Data Exports</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <SRFilters
              datesFilter={datesFilter}
              getListBtnProps={getListBtnProps}
              showHideBtnProps={showHideBtnProps}
            />

            {
              (isEditSrReport || isSRQVisible) && 
              <SRQuestion {...srPanelProps} />
            }

            <Grid.Row>
              <Grid.Column width={16}>
                <List gridProps={gridProps} pgProps={pgProps} />
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {
        downloadConfirm &&
        <Confirm
          content={isModalDel ? CONFIRM_MESSAGES.DELETE_SR_REPORT : CONFIRM_MESSAGES.DOWNLOAD_SR_REPORT}
          header={text}
          confirmButton={getReportBtn}
          open={downloadConfirm}
          onCancel={() => setDownloadConfirm(initFalsy)}
          onConfirm={onConfirmDelOrExport}
        />
      }
    </>
  )
};

export default SRDetails;
