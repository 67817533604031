import React from 'react';
import {Button, Card, Form, Grid, TextBox} from '../Inputs';
import {ButtonProps, TextBoxProps, TextInputProps} from '../../model';
import {initFalsy} from '../../constants';

type AOProps = {
  fields: (TextBoxProps | TextInputProps)[],
  updateButtProps: ButtonProps
};

const AdditionalOperations: React.FC<AOProps> = (props: AOProps) => {
  return (
    <Form>
      <Card fluid={!initFalsy} className="mb20">
        <Card.Content className="pb0">
          <Grid verticalAlign='bottom'>
            <Grid.Row className="py0">
              {
                props.fields.map((sa, key) => (
                  <Grid.Column key={key} computer={6} tablet={8} mobile={16}>
                    <TextBox {...sa} />
                  </Grid.Column>
                ))
              }
              <Grid.Column computer={4} tablet={16} mobile={16} textAlign="right">
                <Button className="mb15" {...props.updateButtProps} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    </Form>
  );
};

export default AdditionalOperations;
