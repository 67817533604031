import * as React from 'react';
import {Router} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import {history} from './redux/configure';
import {setStageCss} from './utils/common';


const App: React.FC = () => {
  setStageCss();

  return (
    <Router history={history}>
      <AppRoutes />
    </Router>
  );
};

export default App;
