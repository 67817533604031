import {asPhone} from './validation';
import {T} from '../model';

/**
 * Force Lower case
 * @param st
 */
export const flc = (st: string): string => pez(st).toLowerCase();

/**
 * Force Upper case
 * @param st
 */
export const fuc = (st: string): string => pez(st).toUpperCase();

/**
 * Capitalize First Letter Of The Word
 * @param strInput
 */
export const cfl = (strInput: string) => {
  return strInput.replace(/\w\S*/g, (t: T) => fuc(t.charAt(0)) + flc(t.substr(1)));
};

/**
 * Parse Line Breaks
 * @param st
 */
export const plb = (st: string) => {
  const [sv, rv] = [/\r?\n|\r/g, ''];
  return pez(st).replace(sv, rv);
};

/**
 * Parse Empty String
 * @param str
 * @param delimiter
 */
export const pez = (str?: string, delimiter: string = '') => (str && typeof str === 'string') ? str.trim() : delimiter;
export const pes = (st: T, delimiter: string = '') => pez(isStr(st) ? st.trim() : st.toString(), delimiter);

/**
 * stringify a JSON object
 * @param o
 * @param replacer
 */
export const stringify = (o: Object, replacer: T = null) => JSON.stringify(o, replacer, 2);

/**
 * Callable telephone numbers
 * @param phoneNumber
 */
export const callableTelephone = (phoneNumber: string) => {
  return `tel:${asPhone(phoneNumber)}`;
}

/**
 * Is String
 * @param str
 */
export const isStr = (str: T) => {
  return str && typeof str === 'string' && str !== null;
}

/**
 * Get File Extension from fileName
 * @param fn
 */
export const getFileExtension = (fn: string) => flc(fn.substring(fn.lastIndexOf('.'), fn.length));

/**
 * Generates an uuid of given size
 * @param size [optional] defaults to 50
 * @param delimiter [optional] default is empty string
 * */
export const uuid = (size: number = 50, delimiter: string = '') => {
  const uniqueCode = function uniqueCode(a?: T) {
    return a ?
      // eslint-disable-next-line
      (a ^ Math.random() * 16 >> a / 4).toString(16) :
      // @ts-ignore
      ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, uniqueCode)
  };
  let uc = uniqueCode();
  while ((2 * size) > uc.length) {
    uc += uniqueCode();
  }

  return uc.replace(new RegExp('-', 'g'), delimiter).substring(0, size);
};

/**
 * Valid Url
 * @param str Url
 */
export const isValidURL = (str: string) => {
  // eslint-disable-next-line
  const res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
  return (res !== null);
};

/**
 * Remove Extra/Special Characters
 */
export const rmSpecialChar = (val: string, rmChar: string, repWith: string) => {
  const repVal = val.replaceAll(rmChar, repWith);
  const newString = repVal.replace(/\s+/g,' ').trim();
  return newString;
};
