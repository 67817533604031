import { HOURS_IN_DAY, ONE, ONE_SEC, TOT_COUNT, ZERO } from '../constants';
import {T} from '../model';


const localStorage: T = window.localStorage || window.sessionStorage || {};

type KV = { key: string, value: string };
type S = (key: string, value: string) => void;
type G = (key: string) => string | null;
type D = (key: string) => void;
type V = () => void;

/**
 * Clear all local storage
 */
export const clear: V = () => localStorage.clear;

/**
 * Add a new item in local storage
 * @param key
 * @param value
 */
export const save: S = (key, value) => localStorage.setItem(key, value);

/**
 * Add Multiple Items to LocalStorage
 * @param items Array containing objects having key as name of item and value as value of item
 */
export const bulkAdd = (items: KV[]) => {
  if (items.length > 0) {
    items.forEach((i) => {
      save(i.key, i.value);
    });
  }
};

/**
 * Get a particular item
 * @param itemKey [ Name or key of item ]
 * @returns {string}
 */
export const get: G = itemKey => localStorage.getItem(itemKey);

/**
 * Removes a particular item
 * @param itemKey
 */
export const del: D = itemKey => localStorage.removeItem(itemKey);

/**
 * Create Cookie
 * @param cName
 * @param cValue
 * @param exDays
 */
export const setCookie = (cName: string, cValue: T, exDays: number) => {
  const d = new Date();
  d.setTime(d.getTime() + (exDays * HOURS_IN_DAY * TOT_COUNT * TOT_COUNT * ONE_SEC));
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cName + '=' + JSON.stringify(cValue) + ';' + expires + ';path=/';
};

/**
 * Get Cookie
 */
export const getCookie = (cname: string) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  if (decodedCookie) {
    for(let i = ZERO; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(ZERO) === ' ') {
        c = c.substring(ONE);
      }
      if (c.indexOf(name) === ZERO) {
        return c.substring(name.length, c.length);
      }
    }
  }

  return '';
};
