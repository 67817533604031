import React from 'react';
import {useParams} from 'react-router';
import {Loader} from '../../components';
import {DropZone, Grid} from '../../components/Inputs';
import {Guidelines} from '../../components/Guidelines';
import {AfterFileDrop, StoreT, T} from '../../model';
import {useActions} from '../../redux/actions';
import * as svGuidelinesAction from '../../redux/actions/svGuidelines';
import {initFalsy, ROLES} from '../../constants';
import { isValidURL } from '../../utils/stringUtils';
import { useSelector } from 'react-redux';


const SurveyorGuidelines: React.FC = () => {
  const strEmpty = '';
  const {id}: T = useParams();
  const users = useSelector((state: StoreT) => state.auth.user)
  const svGuideline = useActions(svGuidelinesAction);
  const [loading, setLoading] = React.useState(initFalsy);
  const [url, setUrl] = React.useState(strEmpty);
  const [isAssignedQues, setIsAssignedQues] = React.useState(initFalsy);

  const processUrl = async (_url: string) => {
    setUrl(_url);
    setLoading(initFalsy);
  };
  const afterDrop = async (data: AfterFileDrop) => {
    // uploading begins
    setLoading(!initFalsy);
    setUrl(strEmpty);
    svGuideline.saveSurveyorGuidelines(id, data.fileName, data.result)
      .then(processUrl);
  };
  const fetchGuidelines = async () => {
    setLoading(!initFalsy);
    svGuideline.fetchSurveyorGuidelines(id)
      .then((res: any) => {
        if (res) {
          const validUrl = isValidURL(res);
          return processUrl(validUrl ? res : '');
        } else {
          setLoading(!initFalsy)
          setIsAssignedQues(!initFalsy);
        }
      })
  };
  const firstRun = () => {
    fetchGuidelines().then();
  };

  React.useEffect(firstRun, []);

  return (
    <>
      {
        loading &&
        <Loader />
      }

      {
        !isAssignedQues && <Grid>
          {
            users.role !== ROLES.Quality &&
            <Grid.Row className="headerTop">
              <Grid.Column width={16}>
                <h1 className="mainTitle">Upload Surveyor Guideline Pdf</h1>
              </Grid.Column>
              <Grid.Column width={16}>
                <Grid.Column width={16}>
                  <DropZone afterDrop={afterDrop} />
                </Grid.Column>
              </Grid.Column>
            </Grid.Row>
          }
          <Grid.Row>
            <Grid.Column className="mt20 mb10" width={16}>
              <Guidelines url={url} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
    </>
  );
};

export default SurveyorGuidelines;
