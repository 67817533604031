import React from 'react'
import {DropTargetMonitor, useDrop} from 'react-dnd';
import {noop} from '../../utils/common';
import {initFalsy} from '../../constants';


interface DroppableProps {
  accept: string;
  dropMsg?: string;
  onComplete?: Function;
}

const Droppable: React.FunctionComponent<DroppableProps> = ({accept, children, dropMsg, onComplete}) => {
  const dragConfig = {
    accept,
    collect: (monitor: DropTargetMonitor) => {
      const
        onComp = onComplete || noop,
        canDrop = monitor.canDrop(),
        didDrop = monitor.didDrop(),
        isOver = monitor.isOver({shallow: !initFalsy}),
        item = monitor.getItem();

      if (didDrop) {
        onComp(item);
      }

      return {canDrop, isOver, item}
    }
  };
  const [{canDrop, isOver}, drop] = useDrop(dragConfig);
  const isAllowed = canDrop && isOver;

  return (
    <div ref={drop}>
      {
        children && children
      }
      <section className="dz-container">
        <div className="dz-zone">
          {
            !(isAllowed) && (dropMsg || (`Drop ${accept} Here.`))
          }
          {
            (isAllowed) && ('...Ready to Drop')
          }
        </div>
      </section>
    </div>
  );
};

export default Droppable;
