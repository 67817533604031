import React from 'react';
import {useHistory, useParams} from 'react-router';
import {useSelector} from 'react-redux';
import AdminDashboard from './AdminDashboard';
import {AddProject, ListProjects} from '../../components/Admin';
import {Grid} from '../../components/Inputs';
import {Loader} from '../../components';
import {IDValue, StoreT} from '../../model';
import {initFalsy} from '../../constants';
import {useActions} from '../../redux/actions';
import * as ap from '../../redux/actions/projects';
import * as languagesActions from '../../redux/actions/languages';
import {vo} from '../../utils/objectUtils';


const ProjectManagement: React.FC = () => {
  const hist = useHistory(),
    {id} = useParams(),
    projectA = useActions(ap),
    langAction = useActions(languagesActions),
    pi = useSelector((state: StoreT) => state.projectInfo),
    thisIsAdd = id === 'add',
    [loader, isLoading] = React.useState(initFalsy),
    onSel = async (object: IDValue) => {
      isLoading(!initFalsy);
      const projectId = object.id;
      isLoading(initFalsy);
      hist.replace(`/admin/projects/${projectId}${object.value === 'edit' ? '' : `/${object.value}`}`);
    },
    lyf = () => {
      if (!thisIsAdd && id) {
        isLoading(!initFalsy);
        const isAdmin = !initFalsy;
        projectA.getProjectInfo(id, isAdmin).then(() => {
          isLoading(initFalsy);
        });
      }
      langAction.fetchLanguages(undefined, undefined, undefined, undefined, initFalsy);

      return () => {
        projectA.flushProjects();
        langAction.flushLanguages();
      };
    };

  React.useEffect(lyf, []);

  return (
    <AdminDashboard>
      {
        loader &&
        <Loader />
      }

      <Grid>
        <Grid.Row>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            {
              (thisIsAdd || vo(pi)) &&
              <AddProject isAdd={thisIsAdd} projectInfo={pi} />
            }
            {
              (!thisIsAdd && !vo(pi)) &&
              <ListProjects onSelect={onSel} />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AdminDashboard>
  );
};

export default ProjectManagement;
