import React from 'react';
import {useHistory} from 'react-router';
import {Button, Card, FieldArray, FormFieldGroup, FormFieldType, Grid, Table} from '../../Inputs';
import {ButtonProps, FieldArrayRenderProps, FormFieldProps, FormProps, Project, T} from '../../../model';
import {enumAsAO, va} from '../../../utils/arrayUtils';
import {initFalsy, PROJECT_MODIFIERS} from '../../../constants';


interface BillingProps extends FormProps {
  buttons: ButtonProps[];
  projectInfo: Project;
  isView?: boolean;
}

interface XT extends FormFieldProps {
  gridSize?: T;
}

const Billing: React.FC<BillingProps> = (props: BillingProps) => {
  const
    {buttons, values, isView} = props,
    hist = useHistory(),
    step = '0.50',
    primaryFields = [
      {
        icon: 'dollar',
        iconPosition: 'left',
        label: 'Billable Per Survey Rate',
        name: 'billableRate',
        step: '0.50',
        type: FormFieldType.Number,
        disabled: isView
      },
      {
        icon: 'dollar',
        iconPosition: 'left',
        label: 'Payment Per Survey Rate',
        name: 'paymentRate',
        step: '0.50',
        type: FormFieldType.Number,
        disabled: isView
      }
    ],
    modifiers: XT[] = [
      {
        icon: 'dollar',
        iconPosition: 'left',
        label: 'Marginal Rate',
        gridSize: 5,
        name: 'rate',
        step: '0.50',
        type: FormFieldType.Number,
        disabled: isView
      },
      {
        label: 'Marginal type',
        gridSize: 5,
        name: 'typeId',
        options: enumAsAO(PROJECT_MODIFIERS),
        type: FormFieldType.Select,
        disabled: isView
      },
      {label: 'Marginal Details', gridSize: 4, name: 'details', type: FormFieldType.Text, disabled: isView}
    ],
    reportingFlatFees: XT[] = [
      {
        icon: 'dollar',
        iconPosition: 'left',
        label: 'Per Reporting Period Fee',
        name: 'perReportingPeriodFee',
        step,
        type: FormFieldType.Number,
        disabled: isView
      },
      {
        icon: 'dollar',
        iconPosition: 'left',
        label: 'Power Bi',
        name: 'powerBiFee',
        step,
        type: FormFieldType.Number,
        disabled: isView
      },
      {
        icon: 'dollar',
        iconPosition: 'left',
        label: 'Project Manager Fee',
        name: 'pmFee',
        step,
        type: FormFieldType.Number,
        disabled: isView
      }
    ],
    lineItemFlatFees: XT[] = [
      {label: 'Flat Fee Header Name', name: 'name', type: FormFieldType.Text, disabled: isView},
      {
        icon: 'dollar',
        iconPosition: 'left',
        label: 'Flat Fee Price',
        name: 'price',
        step,
        type: FormFieldType.Number,
        disabled: isView
      }
    ],
    renderModifiers = (fps: FieldArrayRenderProps, isBillingModifier: boolean) => {
      return (
        <>
          <Table className="crossTable">
            <Table.Header>
              <Table.Row>
                {
                  modifiers.slice(0).map((s, ix) => (<Table.HeaderCell key={ix}> {s.label} </Table.HeaderCell>))
                }
              </Table.Row>
            </Table.Header>
          </Table>

          <Grid>
            {
              (
                (
                  isBillingModifier ?
                    va(values.billingModifier) && values.billingModifier :
                    va(values.paymentModifier) && values.paymentModifier
                ) || []
              ).map((_c: T, i: number) => {
                return (
                  <Grid.Row key={i}>
                    {
                      modifiers.slice(0).map((f: XT, key: number) => {
                        const {gridSize, label, ...mf} = f;
                        const modifierType = isBillingModifier ? 'billingModifier' : 'paymentModifier';
                        const name = `${modifierType}.${i}.${mf.name}`;
                        const tfProps = {...mf, name, placeholder: label};
                        return (
                          <Grid.Column key={key} mobile={16} tablet={gridSize} computer={gridSize}>
                            <FormFieldGroup fields={tfProps as FormFieldProps} />
                          </Grid.Column>
                        )
                      })
                    }
                    <Grid.Column mobile={16} tablet={2} computer={2}>
                      <Button
                        circular={!initFalsy}
                        color='red'
                        icon='minus'
                        type='button'
                        onClick={() => fps.remove(i)}
                        disabled={isView}
                      />
                    </Grid.Column>
                    <hr />
                  </Grid.Row>
                );
              })
            }
          </Grid>

          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Button
              className='mt30'
              circular={!initFalsy}
              color='orange'
              icon='plus'
              type='button'
              onClick={() => fps.push({})}
              disabled={isView}
            />
          Add Modifier
          </Grid.Column>
        </>
      );
    },
    renderLineItem = (fps: FieldArrayRenderProps) => {
      return (
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Grid>
            {
              va(values.lineItems) && values.lineItems.map((_f: T, i: number) => {
                return (
                  <Grid.Row key={i}>
                    {
                      lineItemFlatFees.slice(0).map((f: XT, key: number) => {
                        const tfProps = {...f, name: `lineItems.${i}.${f.name}`};
                        return (
                          <Grid.Column key={key} mobile={16} tablet={7} computer={7}>
                            <FormFieldGroup fields={tfProps as FormFieldProps} />
                          </Grid.Column>
                        );
                      })
                    }
                    <Grid.Column mobile={16} tablet={2} computer={2} className='mt20'>
                      <Button circular={!initFalsy} color='red' icon='minus' type='button' onClick={() => fps.remove(i)} />
                    </Grid.Column>
                  </Grid.Row>
                )
              })
            }
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Button
                circular={!initFalsy}
                color='orange'
                icon='plus'
                type='button'
                onClick={() => fps.push({})}
                disabled={isView}
              />
          Add Line Item
            </Grid.Column>
          </Grid>
        </Grid.Column>
      )
    };

  return (
    <Grid.Row>
      <Grid.Column mobile={16} tablet={16} computer={8}>
        <Card fluid={!initFalsy} className="mb20">
          <Card.Header>
            <h2 className="subTitle">Project Billing</h2>
            {
              (isView && !props.projectInfo.deletedAt) &&
              <Button
                className="asAnchor float-right"
                content="Edit"
                icon="edit"
                onClick={() => hist.push(`/admin/projects/${props.projectInfo.id}`)}
              />
            }
          </Card.Header>
          <Card.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <FormFieldGroup fields={primaryFields[0] as T} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <h3 className="w100 subHeaderInner">
                    <span>Modifier Table (For Billing)</span>
                  </h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <FieldArray name="billingModifier" render={fps => renderModifiers(fps, !initFalsy)} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <h3 className="w100 subHeaderInner">
                    <span>Reporting Period Flat Fees</span>
                  </h3>
                </Grid.Column>
                <div className="project-billing">
                  <Grid>
                    <Grid.Row>
                      {
                        reportingFlatFees.slice(0).map((f: XT, key: number) => {
                          const {label, ...mf} = f;
                          const fieldP = {...mf, placeholder: label};
                          return (
                            <Grid.Column key={key} mobile={16} tablet={8} computer={8}>
                              <label htmlFor={mf.name}>{label}</label>
                              <FormFieldGroup fields={fieldP as FormFieldProps} />
                            </Grid.Column>
                          )
                        })
                      }
                      <Grid.Column className="mt30" mobile={16} tablet={16} computer={16}>
                        <FieldArray name="lineItems" render={renderLineItem} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <FormFieldGroup fields={primaryFields[1] as T} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <h3 className="w100 subHeaderInner">
                    <span>Modifier Table (For Payment)</span>
                  </h3>
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <FieldArray name="paymentModifier" render={fps => renderModifiers(fps, initFalsy)} />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={16} className="mt15" textAlign="right">
                  {
                    buttons.map((buttonProps: ButtonProps, key: number) => (
                      <Button key={key} {...buttonProps} />
                    ))
                  }
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Billing;
