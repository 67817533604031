import React from 'react';
import {useHistory} from 'react-router';
import {useSelector} from 'react-redux';
import {ClientForm} from '../Forms';
import {FormWrap} from '../Inputs';
import {IDValue, FormikValues, StoreT} from '../../model';
import {useActions} from '../../redux/actions';
import * as clientActions from '../../redux/actions/clients';
import {clientAddSchema as validationSchema} from '../../utils/validation';


type T = { action: IDValue, doEdit: Function, onCancel: Function };
const Add: React.FC<T> = (props: T) => {
  const
    clientA = useActions(clientActions),
    hist = useHistory(),
    {action, doEdit, onCancel} = props,
    onContactRemoval = (contactId: number) => clientA.removeContact(contactId),
    clientInfo = useSelector((state: StoreT) => state.clientInfo) || {},
    {name = '', abbr = '', stateId = '', contacts = [{typeId: 0}]} = clientInfo,
    clientFrmConfig = {
      displayName: 'clientForm',
      initialValues: {name, abbr, stateId, contacts},
      onSubmit: async (values: FormikValues, {resetForm}: FormikValues) => {
        try {
          switch (action.value) {
            case 'edit':
              const updPayload = {...values, id: clientInfo.id};
              await clientA.updateClient(updPayload);
              break;
            case 'view':
              hist.push({pathname: '/admin/projects/add', state: {clientInfo}});
              break;
            case 'flush':
              break;
            default:
              await clientA.addClient(values);
          }

          onCancel();
        } catch (err) {
          resetForm();
        }
      },
      children: ClientForm,
      validationSchema,
      extraProps: {action, clientInfo, doEdit, onCancel, onContactRemoval}
    };

  return (
    <>
      <FormWrap {...clientFrmConfig} />
    </>
  )
};

export default Add;
