import createReducer from './createReducer';
import {initFalsy} from '../../constants';
import {Action, ActionType, NavState} from '../../model';

const initialAuth: NavState = {isExpanded: !initFalsy};
export const nav = createReducer(initialAuth, {
  [ActionType.NAV_EXPAND_COLLAPSE](_store: Function, action: Action<NavState>) {
    return action.payload;
  }
});
