export enum Headers {
  'Positive Comments',
  'Negative Comments',
  'Positive Cleaned',
  'Positive NSM',
  'Negative Cleaned',
  'Negative NSM',
  'Name',
  'Patient Roster Name',
  'Patient Type',
  'DOS',
  'Survey Language',
  'Project Name',
  'Provider Name',
  'Department Name',
  'Site Name',
  'Surveyor Name',
  'Surveyed On',
  'Survey Completed On',
  'Result',
  'Survey Status',
  'MRN',
  'DOB',
  'Age',
  'Cell Phone',
  'Home Phone',
  'Payer Type',
  'Provider Type',
  'Guarantor',
  'CHPW',
  'Ethnicity',
  'Race',
  'Sex',
  'Attempts',
  'Department Required Surveys',
  'Department Completed Surveys',
  'Department Remaining Surveys',
  'Department Last Encounter',
  'Department Total Encounters',
  'Department Eligible Encounters',
  'Provider Required Surveys',
  'Provider Completed Surveys',
  'Provider Remaining Surveys',
  'Provider Total Encounters',
  'Provider Last Encounter',
  'Provider Eligible Encounters',
  'Site Required Surveys',
  'Site Completed Surveys',
  'Site Remaining Surveys',
  'Site Last Encounter',
  'Site Total Encounters',
  'Site Eligible Encounters',
  'Surveyor Email',
  'UniqueId',
  'Visit Purpose',
  'QA Removal'
}
