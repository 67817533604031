import React from 'react';
import {ConfirmModal} from './index';
import {ConfirmProps} from '../../model';

const GenConfirm: React.FC<ConfirmProps> = (props: ConfirmProps) => {
  return (
    <ConfirmModal {...props} />
  );
};

GenConfirm.defaultProps = {
  confirmButton: 'Confirm'
};

export default GenConfirm;
