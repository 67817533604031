/**
 * Delete confirmation messages
 */
enum CONFIRM_MESSAGES {
  ASSIGNMENT_HISTORY_WEEK = 'Are you sure you want to delete this week',
  CALL_LOG_SURVEY_EXIT = 'Are you sure you want to exit the survey without saving?',
  CLIENT_DEC = 'Are you sure you want to deactivate this client?',
  CLIENT_RAC = 'Are you sure you want to reactivate this client?',
  CONTACT_AND_PROJECT = 'Are you sure you want to delete this client including all its contacts and projects?',
  DOWNLOAD_SR_REPORT = 'Would you like to download this report?',
  DELETE_SR_REPORT = 'Are you sure you want to delete this export?',
  LANGUAGE = 'Are you sure you want to delete this language?',
  LEAVE_PAGE = 'Are you sure you want to leave this page?',
  LOGIC = 'Are you sure you want to delete this logic?',
  LOGOUT = 'Would you like to stay on this page?',
  PROJECT = 'Are you sure you want to delete this project?',
  PROJECT_DEC = 'Are you sure you want to deactivate this project?',
  PROJECT_RAC = 'Are you sure you want to reactivate this project?',
  QUES_ITEM = 'Are you sure you want to delete this questionnaire item?',
  QUESTION = 'Are you sure you want to deactivate this question?',
  REACTIVE_QUESTION = 'Are you sure you want to reactivate this question?',
  ANSWER = 'Are you sure you want to deactivate this response?',
  REACTIVE_ANSWER = 'Are you sure you want to reactivate this response?',
  RE_ASSIGN_SUV_PRJ = 'Are you sure you want to un-assign this surveyor from project?',
  RESP = 'Are you sure you want to delete this response?',
  UNDO_ROSTER = 'Are you sure you want to undo this roster?',
  REACTIVATE_USER = 'Are you sure you want to reactivate this user account?',
  SAMPLE_PARAM = 'Are you sure you want to delete this sampling parameter?',
  SUB_FINAL_COUNT = 'Are you sure you want to submit the final count? This will reset the roster for this project.',
  SURVEYOR_GUIDELINES = 'I confirm that I have read the guidelines.',
  USER_ACCOUNT_DELETE = 'Are you sure you want to permanently delete this user account?',
  USER_DEACTIVATE = 'Are you sure you want to deactivate this user account?',
}

export default CONFIRM_MESSAGES;
