import React from 'react';
import {Formik} from 'formik';
import {GenericFormProps} from '../../model';


const Form: React.FC<GenericFormProps> = (props: GenericFormProps) => {
  return (
    <Formik autoComplete="off" {...props}>
      {
        childProps => (
          <>
            {
              props && props.children(Object.assign({}, {...childProps}, {...props}))
            }
          </>
        )
      }
    </Formik>
  );
};

export default Form;
