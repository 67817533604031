import {T} from '../model';
import { va } from './arrayUtils';

type enumValT = (E: T, key: T) => number;

/**
 * object is valid
 * @param obj
 */
export const vo = (obj: object | undefined) => !!(obj && Object.keys(obj).length);


/**
 * simulate server latency (default is 1000 ms)
 * @param ms
 * @param something
 */
export const sleep = (ms: number = 1000, something: object = {}) => {
  return new Promise(
    resolve => setTimeout(() => {
      resolve(something)
    }, ms)
  );
}

/**
 * Safely pick property value from matching object keys irrespective of there case
 * @param obj
 * @param prop
 */
export const getProp = (obj: T, prop: string) => {
  let key;
  for (key in obj) {
    if (key.toLowerCase() === prop.toLowerCase()) {
      return obj[key];
    }
  }
};

/**
 * Sanitize and filter an object w.r.t specific keys
 * @param raw [Object]
 * @param allowedKeys [Required valid keys]
 */
export const saFiObj = (raw: T, allowedKeys: string[]) =>
  Object.assign({}, ...allowedKeys.map(key => ({[key]: raw[key]})));


/**
 * Get ENUM's numeric value from string key
 * @param E
 * @param key
 */
export const enumVal: enumValT = (E, key) => E[key as keyof typeof E] as number;

/**
 * Remove Object Value
 * @param obj (Object)
 * @param valToRem (Name Of Value To Be Remove)
 */
export const removeObjValue = (obj: T, valToRem: string) => {
  let new_obj: T = {};
  let new_keys = Object.keys(obj).filter((this_fruit: T) => {
    const newVal = obj[this_fruit];
    if (va(newVal)) {
      const index = newVal.indexOf(valToRem);
      if (index > -1)
        newVal.splice(index, 1);
    }
    return newVal !== valToRem;
  });
  new_keys.forEach((key) => (new_obj[key] = obj[key]));

  return new_obj;
};
