import React from 'react';
import { useParams } from 'react-router';
import {T} from '../../model';
import {useActions} from '../../redux/actions';
import * as projectActions from '../../redux/actions/projects';

const ProjectNameById: React.FC = () => {
  const {id}: T = useParams();
  const pro = useActions(projectActions);
  const [projectName, setProjectName] = React.useState('Questionnaire Viewer');
  const fpl = () => {
    pro.fetchProjectInfoById(id)
      .then((res: {name: string}) => {
        setProjectName(res.name);
      })
      .catch((err: T) => console.error(err));
  };

  React.useEffect(fpl, []);
  return <h1 className="mainTitle">{projectName}</h1>
}

export default ProjectNameById;
