import React from 'react';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {Button, Card, ChkBox, Confirm, Divider, Grid, Icon, List, Select, TextBox} from '../Inputs';
import {Loader} from '../index';
import {Questionnaire} from './index';
import {
  Account,
  Answer,
  BN,
  ButtonProps,
  QsLogicSummary,
  QuestionnaireItem,
  SelectProps,
  SelectValue,
  StoreT,
  T
} from '../../model';
import {useActions} from '../../redux/actions';
import * as questionnaireActions from '../../redux/actions/questionnaire';
import {AnswerType, CONFIRM_MESSAGES, initFalsy} from '../../constants';
import {va} from '../../utils/arrayUtils';
import {vo} from '../../utils/objectUtils';
import {isStr} from '../../utils/stringUtils';
import {getQuestionnaireViewerRoute} from '../../utils/common';

interface QLProps {
  buttons: ButtonProps[];
  goBackProps: ButtonProps;
  isOps?: boolean;
}

type DDLOpt = { text: string; value: number; };
type RFV = { rosterField: string; rosterValue: string; };

const QreLogic: React.FC<QLProps> = ({buttons, goBackProps}) => {
  const
    initEptObj = null,
    initEptArr: DDLOpt[] = [],
    initRFV: RFV = {rosterField: '', rosterValue: ''},
    AnsLogicTypes = [AnswerType.Multiple, AnswerType.Single],
    {id}: T = useParams(),
    [isLoading, setIsLoading] = React.useState(initFalsy),
    [isDeleting, setIsDeleting] = React.useState(initFalsy),
    [questionId, setLogicQuestion]: [T, Function] = React.useState(initEptObj),
    [answerId, setLogicAnswer]: [T, Function] = React.useState(initEptObj),
    [affectedQuestionId, setLogicAffectedQuestion] = React.useState([]),
    [ansOpts, setAnsOpts]: [DDLOpt[], Function] = React.useState(initEptArr),
    [affectedOpts, setAffectedOpts]: [DDLOpt[], Function] = React.useState(initEptArr),
    [causeOpts, setCauseOpts]: [DDLOpt[], Function] = React.useState(initEptArr),
    [delAOpen, showDelAConfirm] = React.useState(initFalsy as BN),
    // [rstField, setRosterField] = React.useState(initRFV.rosterField),
    // [rsValue, setRosterVal] = React.useState(initRFV.rosterValue),
    [viaRoster, setViaRoster] = React.useState(initFalsy),
    [rosterFV, setRosterFV] = React.useState(initRFV),
    [logic, setLogicSummary]: [QsLogicSummary[], Function] = React.useState([] as QsLogicSummary[]),
    [isUpdate, setIsUpdate] = React.useState(initFalsy),
    [remSingleQsId, setSingleQsId] = React.useState([]),
    questionnaire = useSelector((state: StoreT) => state.questionnaire as QuestionnaireItem[]),
    user: Account = useSelector((state: StoreT) => state.auth.user),
    qre = useActions(questionnaireActions),
    fetchQLs = async () => {
      setIsLoading(!initFalsy);
      const logRes = await qre.fetchQsNLogic(id, !initFalsy);
      setLogicSummary(logRes);
      setIsLoading(initFalsy);
    },
    parseQsOpn = ({question, quesSequence, sequence, type}: QuestionnaireItem) => {
      if (!question?.isQuestion) {
        const text = type === AnswerType.Scripted ? `SC ${question?.title}` : `Q${quesSequence} ${question?.title}`;
        return {text, value: sequence};
      } else {
        return {};
      }
    },
    firstRun = () => {
      setIsLoading(!initFalsy);
      fetchQLs()
        .then(() => {
          const opts = questionnaire?.filter(i => AnsLogicTypes.includes(i.type as number)).map(parseQsOpn);
          const remObjVal = opts.filter(value => JSON.stringify(value) !== '{}');
          setCauseOpts(remObjVal);
          setIsLoading(initFalsy);
        });

      return () => {
        qre.flushQuestionnaire();
        qre.flushQsLogic();
      }
    },
    onClear = () => {
      // clear
      setLogicQuestion(initEptObj);
      setLogicAnswer(initEptObj);
      setLogicAffectedQuestion([]);
      setAnsOpts(initEptArr);
      setAffectedOpts(initEptArr);
      setRosterFV(initRFV);
      // setRosterField(initRFV.rosterField);
      // setRosterVal(initRFV.rosterValue);
      setIsLoading(initFalsy);
      setIsUpdate(initFalsy);
      setViaRoster(initFalsy);
    },
    onViaRosterChange = () => {
      onClear();
      if (!viaRoster) {
        setAffectedOpts(questionnaire?.map(parseQsOpn));
      }
      setViaRoster(!viaRoster);
    },
    onCancel = () => {
      if (viaRoster) {
        onViaRosterChange();
      } else {
        onClear();
      }
    },
    onSaveLogic = async () => {
      const payload = {affectedQuestionId, answerId, projectId: id, questionId, viaRoster, ...rosterFV};
      // save
      setIsLoading(!initFalsy);
      await qre.addQLogic(payload);
      const logRes = await qre.fetchQsNLogic(id, !initFalsy);
      setLogicSummary(logRes);
      onCancel();
    },
    onUpdLogic = async () => {
      const payload = {
        ...rosterFV,
        affectedQuestionId,
        answerId: !!answerId ? answerId : initEptObj,
        id: remSingleQsId,
        projectId: id,
        questionId: !!questionId ? questionId : initEptObj,
        viaRoster
      };
      // update questionnaire logic
      setIsLoading(!initFalsy);
      await qre.updateQLogic(payload);
      const logRes = await qre.fetchQsNLogic(id, !initFalsy);
      setLogicSummary(logRes);
      onCancel();
    },
    onAffectedQsChange = (value: T) => {
      const remQsIdArr = affectedQuestionId.map((e: T, i: number) => !value.includes(e) ? remSingleQsId[i] : initEptObj);
      const remQsId = remQsIdArr.filter(i => i);
      va(remQsId) && qre.removeQsLogic(remQsId);
      setLogicAffectedQuestion(value)
    },
    onLQCChange = (logicQuestion: SelectValue) => {
      const
        qsAns = questionnaire?.find(i => i.sequence === logicQuestion)?.answer || [],
        ao = qsAns.map(i => ({text: i.title, value: i.id})),
        afOp = questionnaire?.filter(i => i.sequence > logicQuestion).map(parseQsOpn);

      setLogicQuestion(logicQuestion);
      setAnsOpts(ao);
      setAffectedOpts(afOp);
    },
    onLogicDelete = async () => {
      setIsDeleting(!initFalsy);
      await qre.removeQsLogic(affectedQuestionId);
      const logRes = await qre.fetchQsNLogic(id, !initFalsy);
      setLogicSummary(logRes);
      setIsDeleting(initFalsy);

      showDelAConfirm(initFalsy);
    },
    confirmButtProps: ButtonProps = {content: 'Yes, Delete', loading: isDeleting, primary: initFalsy, type: 'button'},
    confirmProps = {
      content: CONFIRM_MESSAGES.LOGIC,
      header: 'Remove Logic',
      confirmButton: <Button color="red" {...confirmButtProps} />,
      open: !!delAOpen,
      onCancel: () => showDelAConfirm(initFalsy),
      onConfirm: onLogicDelete
    },
    selectQuesProps: SelectProps = {
      hasFirstEmptyOpt: !initFalsy,
      label: 'Select Question',
      onChange: onLQCChange,
      options: causeOpts,
      value: questionId,
      WC: Select
    },
    selectAnsProps: SelectProps = {
      hasFirstEmptyOpt: !initFalsy,
      label: 'Select Answer',
      onChange: (value: SelectValue) => setLogicAnswer(value),
      options: ansOpts,
      value: answerId,
      WC: Select
    },
    selectAffectedQuesProps: SelectProps = {
      hasFirstEmptyOpt: !initFalsy,
      label: 'Select Affected Question',
      multiple: !initFalsy,
      options: affectedOpts,
      onChange: (value: SelectValue) => onAffectedQsChange(value),
      placeholder: 'Select Final Question',
      value: affectedQuestionId,
      WC: Select
    },
    onTextChange = (value: string, fieldName: string) => setRosterFV({...{}, ...rosterFV, [fieldName]: value}),
    rfProps = {
      autoComplete: 'off',
      label: 'Roster Field',
      name: 'rosterField',
      onChange: (e: T) => onTextChange(e.target.value, 'rosterField'),
      value: rosterFV.rosterField,
      WC: TextBox
    },
    rvProps = {
      autoComplete: 'off',
      className: 'w100',
      label: 'Roster Field Value',
      name: 'rosterValue',
      onChange: (e: T) => onTextChange(e.target.value, 'rosterValue'),
      value: rosterFV.rosterValue,
      WC: TextBox
    },
    viaRosterProps = {
      checked: viaRoster,
      className: 'mr20',
      labelInLeft: !initFalsy,
      label: 'Via Roster',
      name: 'via-roster',
      onChange: onViaRosterChange,
      toggle: !initFalsy
    },
    cancelButtonProps: ButtonProps = {
      className: 'float-right',
      content: 'Reset',
      icon: 'undo',
      labelPosition: 'left',
      onClick: onCancel,
      type: 'button'
    },
    saveButtonProps: ButtonProps = {
      className: 'float-right',
      content: 'Save',
      disabled: !(((questionId && answerId) || viaRoster) && affectedQuestionId),
      icon: 'save',
      labelPosition: 'left',
      onClick: onSaveLogic,
      primary: !initFalsy,
      type: 'button'
    },
    updButtonProps: ButtonProps = {
      className: 'float-right',
      content: 'Update',
      disabled: !(((questionId && answerId) || viaRoster) && affectedQuestionId),
      icon: 'save',
      labelPosition: 'left',
      onClick: onUpdLogic,
      primary: !initFalsy,
      type: 'button'
    },
    fields: T[] = viaRoster ? [rfProps, rvProps] : [selectQuesProps, selectAnsProps],
    quesProps = {
      addLogicScroll: !initFalsy,
      isDeleted: !initFalsy
    },
    editQSLogic = (payload: T) => {
      const {affectedQsId, ans, id, q} = payload;
      setIsUpdate(!initFalsy);
      setLogicAffectedQuestion(affectedQsId);
      setSingleQsId(id);
      if (isStr(q)) {
        setViaRoster(!initFalsy);
        setAffectedOpts(questionnaire?.map(parseQsOpn));
        setRosterFV({rosterField: q, rosterValue: ans});
      } else {
        setViaRoster(initFalsy);
        onLQCChange(q);
        setLogicQuestion(q);
        setLogicAnswer(ans);
      }
    },
    delQSLogic = (affId: T) => {
      showDelAConfirm(!initFalsy);
      setLogicAffectedQuestion(affId);
    },
    LogicSummary = (lsProps: Partial<QsLogicSummary>) => {
      const
        {affectedQuestionId, answer, answerId, id, questionId, rosterField, rosterValue} = lsProps,
        affectedQuestionIds = (va(affectedQuestionId) ? affectedQuestionId : [affectedQuestionId]) as T[],
        q = questionnaire?.find(i => i.sequence === questionId),
        aqs = questionnaire?.filter(i => affectedQuestionIds?.includes(i.sequence)),
        qID = vo(q) ? q?.quesSequence : questionId,
        affectedQIdDisplayText = (
          va(aqs) ?
            (
              aqs.map(aqi =>
                aqi?.type === AnswerType.Scripted ?
                  `SC ${aqi?.question?.title}` :
                  `Q${(aqi.quesSequence || aqi.sequence)}`
              )
            ) :
            (affectedQuestionId as number[]).map(aq => `Q${aq}`)
        ).join(', '),
        qLabel = `When ${questionId ? `Q${qID}` : rosterField}`,
        aLabel = ` has answer => [${answerId ? answer?.title : rosterValue}]`,
        affectedQLabel = affectedQuestionId && (
          (answerId || (lsProps.viaRoster || viaRoster)) && ` Then (${affectedQIdDisplayText}) is not displayed.`),
        gridWidth = !!id ? 14 : 16,
        editPayload = {
          affectedQsId: affectedQuestionId,
          ans: answerId ? answer?.id : rosterValue,
          id,
          q: questionId ? questionId : rosterField,
        };

      return (
        <Grid>
          <Grid.Row>
            <Grid.Column width={gridWidth}>
              {
                qLabel
              }

              {
                aLabel
              }

              {
                affectedQLabel
              }
            </Grid.Column>
            {
              !!id &&
              <Grid.Column width={2}>
                {
                  !isUpdate &&
                  <Icon className="hand-pointer" name="edit" onClick={() => editQSLogic(editPayload)} />
                }
                <Icon className="float-right hand-pointer" name="trash" onClick={() => delQSLogic(id)} />
              </Grid.Column>
            }
          </Grid.Row>
        </Grid>
      )
    };

  if (va(fields) && fields.length === 2) {
    fields.push(selectAffectedQuesProps)
  }
  const qsUrl = getQuestionnaireViewerRoute(user.role, id as number);
  const qsLinkButtProps = {
    as: 'a',
    className: 'asAnchor float-left',
    content: 'Questionnaires',
    href: qsUrl,
    icon: 'external alternate',
    style: {padding: '5px 0px 0px 10px'},
    onClick: (_e: React.SyntheticEvent) => {
      _e.preventDefault();
      window.open(qsUrl);
    },
  };

  React.useEffect(firstRun, []);

  return (
    <Grid>
      {
        isLoading &&
        <Loader />
      }

      <Grid.Row className="headerTop">
        <Grid.Column width={16}>
          <h1 className="mainTitle">Questionnaire Logic
            <Button {...qsLinkButtProps} />
          </h1>
          <Button {...goBackProps} />
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column computer={6} mobile={16} tablet={16}>
          <Card fluid={!initFalsy}>
            <Card.Header>
              <h2 className="subTitle">Existing Display Logic</h2>
            </Card.Header>
            <Card.Content className="qs-logic">
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <List>
                {
                  va(logic) && logic.map((logicItem, idx) => (
                    <List.Item key={idx}>
                      <LogicSummary viaRoster={!initFalsy} {...logicItem as T} />
                      <Divider clearing />
                    </List.Item>
                  ))
                }
                {
                  !va(logic) &&
                  <List.Item className="text-center" content="No Data Found..." />
                }
              </List>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column computer={10} mobile={16} tablet={16}>
          <Card fluid={!initFalsy}>
            <Card.Header>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <h2 className="subTitle">Create Questionnaire Logic</h2>
                  </Grid.Column>
                  <Grid.Column floated="right" width={8}>
                    <div className="btnToggle">
                      <ChkBox {...viaRosterProps} />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Header>
            <Card.Content>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16}>
                  </Grid.Column>
                  {
                    fields.map(({WC, ...fp}, idx) => (
                      <Grid.Column key={idx} width={16}>
                        {
                          WC &&
                          <WC placeholder={fp.placeholder || fp.label} {...fp} />
                        }
                      </Grid.Column>
                    ))
                  }
                </Grid.Row>
                {
                  (questionId || rosterFV.rosterField) &&
                  <Grid.Row className="mt10">
                    <Grid.Column width={12}>
                      <h3 className="w100 subHeaderInner">
                        <LogicSummary
                          {...rosterFV}
                          affectedQuestionId={affectedQuestionId}
                          answer={{title: ansOpts.find(a => a.value === answerId)?.text} as Answer}
                          answerId={answerId}
                          questionId={questionId}
                        />
                      </h3>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Button {...cancelButtonProps} />
                    </Grid.Column>
                    <Grid.Column width={2}>
                      {
                        !isUpdate ? <Button {...saveButtonProps} /> : <Button {...updButtonProps} />
                      }
                    </Grid.Column>
                  </Grid.Row>
                }
                <Grid.Row className="mt15">
                  <Grid.Column width={16}>
                    {
                      buttons.map((bp, key) => (
                        <Button key={key} {...bp} />
                      ))
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>
          <Questionnaire {...quesProps} />
        </Grid.Column>
      </Grid.Row>

      {
        delAOpen &&
        <Confirm {...confirmProps} />
      }

    </Grid>
  )
};

export default QreLogic;
