import React from 'react';
import {ProjectsList} from '../../components/Projects';
import {initFalsy} from '../../constants';


const Projects = () => {
  return (
    <ProjectsList isManager={!initFalsy} />
  );
};

export default Projects;
