import React from 'react';
import {SRProjects} from '../../components/ServiceRecoveries';
import {initFalsy} from '../../constants';

const ServiceRecoveries = () => {
  return (
    <SRProjects isManager={!initFalsy} />
  );
};

export default ServiceRecoveries;
