import React from 'react';
import {Button, Form, Grid, Icon, TextBox} from '../Inputs';
import {hasError, pwdRequirements} from '../../utils/validation';
import {FormikValues, PwdR} from '../../model';
import {initFalsy} from '../../constants';

const CreteResetPwdForm = (fProps: FormikValues) => (
  <Form className="pt-4 pl-0" onSubmit={fProps.handleSubmit}>
    <TextBox
      name="password"
      placeholder="Create Password"
      type="password"
      error={hasError(fProps.errors.password)}
      onChange={fProps.handleChange}
      value={fProps.values.password}
      autoComplete="off"
    />
    <TextBox
      name="confirmPwd"
      placeholder="Confirm Password"
      type="password"
      error={hasError(fProps.errors.confirmPwd)}
      onChange={fProps.handleChange}
      value={fProps.values.confirmPwd}
      autoComplete="off"
    />
    <Button
      className="btn-block mt-2"
      type="submit"
      primary={!initFalsy}
      disabled={fProps.isSubmitting}
      loading={fProps.isSubmitting}
      content="Submit"
    />
    <Form.Field>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16}>
            <Grid.Row>
              <Grid.Column mobile={8}>
                <p className="pwdRequirementsTitle"> Password Must Meet The Following Requirements</p>
                <ul className="pwdRequirements ">
                  {
                    pwdRequirements.map((pr: PwdR) => (
                      <li key={pr.key}>
                        <span className={pr.hasError ? 'danger' : ''}>
                          - {pr.value}
                        </span>
                        {
                          !pr.hasError &&
                          <Icon name="check circle" color="green" className="ml5" />
                        }
                      </li>
                    ))
                  }
                </ul>
              </Grid.Column>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form.Field>
  </Form>
);

export default CreteResetPwdForm;
