import React from 'react';
import {connect, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import {BtnSwitch, Card, Grid, GridView, Paging, ProgressGoals} from '../../components/Inputs';
import {Loader} from '../../components';
import {DEFAULT_RECORDS_PER_PAGE, GoalsHeader, initFalsy, PROJECT_STATUS, ROLES} from '../../constants';
import {Account, ButtonProps, PAList, Project, ST, StoreT} from '../../model';
import {Path} from '../../routes';
import {useActions} from '../../redux/actions';
import * as projectActions from '../../redux/actions/projects';
import * as userActions from '../../redux/actions/users';
import {va} from '../../utils/arrayUtils';
import {getSkipLimit, joinAsFullName} from '../../utils/common';
import {piz} from '../../utils/numUtils';
import {vo} from '../../utils/objectUtils';

interface Props {
  sessionUser: Account;
  sur: Partial<Account>;
}

const MySurPro: React.FC<Props> = ({sessionUser, sur}: Props) => {
  const {id} = useParams();
  const surveyorId = piz(id);
  const hist = useHistory();
  const projectA = useActions(projectActions);
  const userA = useActions(userActions);
  const initPageNo = 1;
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [loader, isLoading] = React.useState(!initFalsy);
  const [isWeeklySelected, setWeekly] = React.useState(!initFalsy);
  const goalsHeader = isWeeklySelected ? `${GoalsHeader.Weekly} / ${GoalsHeader.WeeklyTotal}` : `${GoalsHeader.RP} / ${GoalsHeader.RPTotal}`;
  const headers = ['Project Name', goalsHeader, 'Status'];
  const sortKeys = ['name', ''];
  const onSelect = async (p: Partial<Project>) => {
    const rosterState = {projectId: p.id, name: p.name};
    const path = sessionUser.role === ROLES['Project Manager'] ? Path.MySurveyors : sessionUser.role === ROLES.Operations ? Path.OPSSurveyors : Path.CIMRSurveyors;
    hist.push(`${path}/${surveyorId}/${p.id}`, rosterState);
  };
  const onChangeType = (isWeekly: boolean) => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, sortKeys[0], 'ASC', isWeekly).then();
    setWeekly(isWeekly);
  };
  const reportButtProps: ButtonProps[] = [
    {active: isWeeklySelected === !initFalsy, content: 'Current Week', onClick: () => onChangeType(!initFalsy)},
    {as: 'div', className: 'or', key: 'or'},
    {active: isWeeklySelected === initFalsy, content: 'Reporting Period', onClick: () => onChangeType(initFalsy)}
  ];
  const fetchProjects = async (skip = 0, lim = DEFAULT_RECORDS_PER_PAGE, sortOn = 'name', sortBy = 'ASC', isWeekly = !initFalsy) => {
    isLoading(!initFalsy);
    await projectA.fetchSurveyorProjects(skip, lim, sortOn, sortBy, surveyorId, isWeekly);
    isLoading(initFalsy);
  };
  const navFux = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    fetchProjects(skip, gridSize, sortKeys[0], 'ASC', isWeeklySelected).then();
  };
  const onSort = (cc: string, direction: ST) => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, cc, direction).then();
  };
  const firstRun = () => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit)
      .then(() => userA.pullUserInfo(surveyorId));

    return () => {
      projectA.flushProjects();
      userA.flushUserInfo();
    }
  };
  const {rows, count}: PAList = useSelector((state: { projects: PAList }) => state.projects) || {};
  const projects = va(rows) ? rows.map(({assignment, id, isLocked, name, statusId}) => ({
    _id: id,
    _lock: isLocked,
    name,
    progress: (
      <ProgressGoals
        compGoals={assignment?.surveyorCompGoals}
        reqGoals={assignment?.surveyorGoals}
        surveysRP={assignment?.surveysRP}
      />
    ),
    status: PROJECT_STATUS[statusId]
  })) : [];
  const mainTitle = `${vo(sur) ? joinAsFullName(sur.firstName, sur.lastName) : 'Surveyor'}'s Projects`

  React.useEffect(firstRun, []);

  return (
    <>
      {
        loader &&
        <Loader />
      }

      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">{mainTitle}</h1>
            <BtnSwitch buttonProps={reportButtProps} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <Card fluid>
              <Card.Content>
                <GridView headers={headers} list={projects} onSelect={onSelect} onSort={onSort} sortKeys={sortKeys} />

                <Paging
                  totalRecords={count}
                  navigate={navFux}
                  activePage={activePage}
                  pageSize={limit}
                  setPageSize={setPageSize}
                  setPageChange={setPageChange}
                />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

const mapToProps = (state: StoreT) => {
  return {
    sessionUser: state.auth.user,
    sur: state.userInfo
  };
};
export default connect(mapToProps)(MySurPro);
