import React from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router';
import {Account, ButtonProps, ChartData, LabelField, Project, StoreT, T} from '../../model';
import {joinAsFullName as joinName, surveyMaxValue} from '../../utils/common';
import {initFalsy, PROJECT_STATUS, ROLES, StatusCode} from '../../constants';
import {CallsHistoryChart} from '../../components/Calls';
import {BtnSwitch, GenModelProps, Grid, Model} from '../../components/Inputs';
import {Loader} from '../../components';
import {useActions} from '../../redux/actions';
import * as clActions from '../../redux/actions/calls';
import * as prjActions from '../../redux/actions/projects';
import * as usrActions from '../../redux/actions/users';
import {aMaxVal, va} from '../../utils/arrayUtils';
import {vo} from '../../utils/objectUtils';
import {asCurrency} from '../../utils/numUtils';
import {setAlert} from '../../redux/actions/alert';
import {ViewEditAccount} from '../../components/Admin';
import {pez} from '../../utils/stringUtils';


interface SurProjectDetailProps {
  callsHistory: ChartData[];
  projectInfo: Project;
  sur: Partial<Account>;
  user: Account;
}


const SurveyorsProjectDetails: React.FC<SurProjectDetailProps> = ({callsHistory, projectInfo, sur, user}) => {
  const {pid, uid} = useParams();
  const [isWeeklySelected, setWeekly] = React.useState(!initFalsy);
  const [loader, isLoading] = React.useState(initFalsy);
  const [isShowPMPopup, setShowPMPopup] = React.useState(initFalsy);
  const projectActions = useActions(prjActions);
  const callActions = useActions(clActions);
  const userActions = useActions(usrActions);
  const completedSurvey = va(callsHistory) ? aMaxVal(callsHistory.map(c => c.cSurveys)) : 0;
  const onShowPM = async () => {
    isLoading(!initFalsy);
    await userActions.pullUserInfo(projectInfo?.managerId);
    setShowPMPopup(!initFalsy);
    isLoading(initFalsy);
  };
  const onHidePMPopup = () => {
    setShowPMPopup(initFalsy);
  };
  const proDetails: LabelField[] = [
    {
      as: 'a',
      label: 'Project Manager',
      onClick: onShowPM,
      value: joinName(projectInfo?.user?.firstName, projectInfo?.user?.lastName),
      title: 'Project Manager Contact Information'
    },
    {label: 'Time Zone', value: pez(projectInfo.timezone as string)},
    {label: 'Status', value: PROJECT_STATUS[projectInfo.statusId]},
    {label: `Current ${isWeeklySelected ? 'Week' : 'Report'} Count`, value: completedSurvey}
  ];
  const CIMRRoles = [ROLES['CIMR Surveyor'], ROLES['CIMR Manager']]
  const isCIMR = CIMRRoles.includes(user.role);
  const pmModelContentProps = {
    action: 'view',
    id: projectInfo?.user?.id,
    isModel: !initFalsy,
    onCancel: onHidePMPopup
  };
  const pmModelProps: GenModelProps = {
    content: (<ViewEditAccount {...pmModelContentProps} />),
    initialize: isShowPMPopup,
    onCancel: onHidePMPopup,
    size: 'small'
  };
  const onChangeType = (isWeekly: boolean) => {
    isLoading(!initFalsy);
    callActions.fetchSurCallHistory(pid, uid, isWeekly, initFalsy);
    setWeekly(isWeekly);
    isLoading(initFalsy);
  };
  const firstRun = () => {
    isLoading(!initFalsy);
    projectActions.getProjectInfo(pid, undefined, !initFalsy)
      .then((res: T) => {
        if (res.code !== StatusCode.Unauthorized) {
          userActions.pullUserInfo(uid)
            .then(() => callActions.fetchSurCallHistory(pid, uid, isWeeklySelected, initFalsy))
            .then(() => isLoading(initFalsy));
        }
      })
      .catch((err: T) => {
        setAlert(err);
      });

    return () => {
      projectActions.flushProjectInfo();
      userActions.flushUserInfo();
      callActions.flushCallInfo();
    };
  };
  const maxValue = surveyMaxValue(callsHistory);
  const reportButtProps: ButtonProps[] = [
    {active: isWeeklySelected === !initFalsy, content: 'Current Week', onClick: () => onChangeType(!initFalsy)},
    {as: 'div', className: 'or', key: 'or'},
    {active: isWeeklySelected === initFalsy, content: 'Reporting Period', onClick: () => onChangeType(initFalsy)}
  ];
  const mainTitle = `${vo(sur) ? joinName(sur.firstName, sur.lastName) : 'Surveyor'}'s Project Details`;

  if (!isCIMR) {
    const earnings = projectInfo.paymentRate * completedSurvey;
    const _moreProDet = [
      {label: `Current ${isWeeklySelected ? 'Week' : 'Report'} Earnings`, value: asCurrency(earnings)},
    ];
    _moreProDet.forEach(itm => proDetails.push(itm));
  }

  React.useEffect(firstRun, []);

  return (
    <>
      {
        loader &&
        <Loader />
      }
      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">{mainTitle}</h1>
            <BtnSwitch buttonProps={reportButtProps} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <CallsHistoryChart
            callsHistory={callsHistory}
            isLock={projectInfo.isLocked}
            isWeeklySelected={isWeeklySelected}
            maxValue={maxValue}
            proDetails={proDetails}
            projectName={projectInfo.name}
          />
        </Grid.Row>

        {
          isShowPMPopup &&
          <Model {...pmModelProps} />
        }
      </Grid>
    </>
  );
};

const mapToProps = (store: StoreT) => {
  return {
    callsHistory: store.callsHistory as T,
    projectInfo: store.projectInfo,
    sur: store.userInfo,
    user: store.auth.user
  };
};

export default connect(mapToProps)(SurveyorsProjectDetails);
