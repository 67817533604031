import React from 'react';
import {connect} from 'react-redux'
import {useActions} from '../../redux/actions';
import {Card, Chart, FormWrap, Grid, GridView} from '../../components/Inputs';
import {Filters} from '../Surveyors';
import {Account, CallStat, FormikValues, MiniAccount, ProjectsList, ST, StoreT, T} from '../../model';
import {Loader} from '../../components/index';
import {BAR_PRIMARY_COLOR, BAR_SECONDARY_COLOR, Frequency, initFalsy, ROLES} from '../../constants';
import {aMaxVal, byKey, va} from '../../utils/arrayUtils';
import {asCurrency, fixedFloat, pfz} from '../../utils/numUtils';
import {hourToTime, startFrom, toEnd} from '../../utils/dateUtils';
import * as callActions from '../../redux/actions/calls';
import * as projectActions from '../../redux/actions/projects';
import * as userAccActions from '../../redux/actions/users';


interface StatsProps {
  callStats: CallStat[];
  chartStats: CallStat[];
  isPM?: boolean;
  isOp?: boolean;
  isSurveyor?: boolean;
  projects: ProjectsList;
  surveyors: Array<MiniAccount>;
  user: Account;
}

type FSE = (
  startDate?: string,
  endDate?: string,
  projectId?: number,
  surveyorId?: number,
  weekly?: boolean,
  monthly?: boolean
) => Promise<void>;

type Filter = { startDate?: string; endDate?: string; projectId?: number, surveyorId?: number, earningsFreq?: number };

enum LABEL {
  Avg_Hourly_Earnings = 'Avg Hourly Earnings',
  Avg_Surveys_P_Hr = 'Avg Surveys (Per Hour)',
  SurveysCompleted = 'Surveys Completed',
}


const CallStats = (props: StatsProps) => {
  let [totSurveys, totSurveys_ig, totAttempts, totTimeInSys, totEarning, totAgreeTimeSys_ig] = [0, 0, 0, 0, 0, 0];
  const {isPM, isOp, isSurveyor, projects, surveyors, user, callStats, chartStats} = props;
  const fromDate = startFrom(isOp);
  const initFilter: Filter = {startDate: '', endDate: '', earningsFreq: Frequency.Weekly};
  const [loader, isLoading] = React.useState(initFalsy);
  const [filter, setFilter] = React.useState(initFilter);
  const isWeekly = filter.earningsFreq === Frequency.Weekly;
  const [getCallSortData, setCallSortData] = React.useState({column: 'weekOf', direction: 'ASC'});
  const [defaultDate, setStartDate] = React.useState(fromDate);
  const isMonthly = filter.earningsFreq === Frequency.Monthly;
  const callAction = useActions(callActions);
  const proAction = useActions(projectActions);
  const userAction = useActions(userAccActions);
  const axisBottomLabel = `${isWeekly ? 'Week' : (isMonthly ? 'Month' : 'Day')} Of`;
  const CIMRRoles = [ROLES['CIMR Surveyor'], ROLES['CIMR Manager']]
  const isCIMR = CIMRRoles.includes(user.role);
  const header = [
    axisBottomLabel,
    'Time in System (Hours)',
    LABEL.SurveysCompleted,
    'Surveys Attempted',
    'Survey Completion Rate',
    'Avg Length of Surveys (hh:mm:ss)',
    LABEL.Avg_Surveys_P_Hr
  ];
  const headers = !isCIMR ? header.concat([LABEL.Avg_Hourly_Earnings]) : header;
  const sortKeys = ['weekOf', 'timeInSys', 'surveys', 'attempts'];
  const onSort = (column: string, direction: ST) => {
    setCallSortData({column, direction})
  };
  const fetchCS: FSE = async (startDate, endDate, projectId, surveyorId, weekly, monthly) => {
    isLoading(!initFalsy);
    const managerId = isPM ? user.id : undefined;
    const surId = isSurveyor ? user.id : surveyorId;
    const exactStartDate = !!startDate ? startDate : defaultDate;
    const exactEndDate = endDate ? toEnd(endDate) : '';
    const type = user.role === ROLES['CIMR Manager'] ? ROLES['CIMR Surveyor'] : undefined;
    await callAction.fetchCallStats(exactStartDate, exactEndDate, projectId, managerId, surId, weekly, monthly, type);
    isLoading(initFalsy);
  };
  const fse = () => {
    const [sortOn, sortBy] = ['name', 'ASC'];
    const type = user.role === ROLES['CIMR Manager'] ? ROLES['CIMR Surveyor'] : undefined;
    const fetchProjects = isSurveyor
      ? proAction.fetchSurveyorProjects(0, Number.MAX_SAFE_INTEGER, sortOn, sortBy, user.id)
      : proAction.fetchProjects(0, Number.MAX_SAFE_INTEGER, sortOn, sortBy, undefined, isPM ? user.id : undefined, !initFalsy);
    const fetchSurveyors = userAction.fetchSvInfo(isPM ? user.id : undefined, type);

    fetchCS()
      .then(fetchProjects)
      .then(fetchSurveyors);

    return () => {
      proAction.flushProjects();
      callAction.flushCallStats();
      callAction.flushSurveyorEarnings();
      userAction.flushSurveyUserList();
    }
  };
  const filtersFormConfig = {
    children: Filters,
    defaultDate,
    displayName: 'call-stats-filter',
    fromDate,
    initialValues: {startDate: '', endDate: '', projectId: '', surveyorId: '', earningsFreq: Frequency.Weekly},
    isManager: !isPM ? isOp : isPM,
    isSurveyor: isSurveyor,
    onSubmit: async (values: FormikValues, {resetForm}: FormikValues) => {
      try {
        const {startDate, endDate, projectId, surveyorId, earningsFreq} = values;

        setFilter(values as Filter);

        await fetchCS(startDate, endDate, projectId, surveyorId, earningsFreq === Frequency.Weekly, earningsFreq === Frequency.Monthly);
      } catch (err) {
        resetForm();
      }
    },
    projects: projects,
    setStartDate,
    surveyors: surveyors
  };
  const {column, direction} = getCallSortData;
  const list = va(callStats) ? (callStats.sort(byKey(column, direction === 'ASC', !initFalsy)) || []).map((cs: CallStat) => {
    const {agreedTimeInSys_ig, attempts, earnings, surveys, surveys_ig, timeInSys, weekOf} = cs;
    const avgLengthOsSurveys = agreedTimeInSys_ig / surveys_ig;
    const avgSurveysPHour = surveys_ig / timeInSys;
    const estHourlyEarnings = earnings / timeInSys;
    const surveyAvgLen = pfz(avgLengthOsSurveys);
    totAgreeTimeSys_ig += agreedTimeInSys_ig;
    totSurveys += Number(surveys);
    totSurveys_ig += Number(surveys_ig);
    totAttempts += attempts;
    totTimeInSys += timeInSys;
    totEarning += earnings;
    const payload: T = {
      /**
       *  Week Of / Day Of / Month Of
       */
      weekOf: weekOf,
      /**
       * Absolute difference in hours between Surveyed On And Call Stats and difference less than 1 hour
       */
      timeInSys: fixedFloat(timeInSys, 1),
      /**
       * No of surveys fully completed (not partial) and difference less than 1 hour
       */
      surveys: surveys,
      /**
       * No of all total attempts within chosen dates and selected projects and surveyors
       */
      attempt: attempts,
      /**
       * No of total attempts over above no of surveys i.e. col3/col4 %
       */
      completionRate: `${fixedFloat(surveys / attempts * 100)}%`,
      /**
       * Agreed time in system (Non-partial, agreed survey, fully complete, only call details questionnaire) over surveys (only call details questionnaire) in HH:MM:SS format
       */
      avgLengthOfSurvey: hourToTime(surveyAvgLen),
      /**
       * Surveys (call details questionnaire) over total time in system (col2)
       */
      avgSurveysPerHour: fixedFloat(avgSurveysPHour, 1),
    };
    /**
     * Hourly earnings (Non CIMR surveyors) Call details questionnaire complete not partial surveys earnings / timeInSys (2nd col)
     */
    !isCIMR && (payload.hourlyEarnings = asCurrency(estHourlyEarnings));
    return payload;
  }) : [];
  const avgSurveyTot = totSurveys_ig / totTimeInSys;
  const hourlyEarnTot = totEarning / totTimeInSys;
  const avgLenSurvey = totAgreeTimeSys_ig / totSurveys_ig;
  const total: T = va(list) ? [
    {
      weekOf: 'Total',
      timeInSys: fixedFloat(totTimeInSys, 1),
      surveys: <b>{totSurveys}</b>,
      attempt: <b>{totAttempts}</b>,
      completionRate: `${fixedFloat(totSurveys / totAttempts * 100)}%`,
      avgLengthOfSurvey: hourToTime(avgLenSurvey),
      avgSurveysPerHour: fixedFloat(avgSurveyTot, 1),
    }
  ] : [];
  if (!isCIMR && va(list) && va(total)) {
    total[0]['hourlyEarnings'] = asCurrency(hourlyEarnTot);
  }
  const chartsMaxVal = () => {
    if (!va(chartStats)) {
      return 0;
    }
    const surveysMax = aMaxVal(chartStats.map(cs => cs.surveys / cs.timeInSys));
    const earningsMax = aMaxVal(chartStats.map((cs: CallStat) => cs.earnings / cs.timeInSys));
    return surveysMax >= earningsMax ? surveysMax : earningsMax;
  };
  const legendLabels = [LABEL.Avg_Surveys_P_Hr];
  const keys = ['surveyTime'];

  if (!isCIMR) {
    legendLabels.unshift(LABEL.Avg_Hourly_Earnings);
    keys.unshift('earnings');
  }

  const chartProps = {
    ...props,
    availableColors: [BAR_PRIMARY_COLOR, BAR_SECONDARY_COLOR],
    axisBottomLabel,
    axisLeftLabel: legendLabels.join(' / '),
    groupMode: 'grouped',
    height: '400px',
    includeLine: initFalsy,
    indexBy: 'weekOf',
    keys,
    legendLabels: legendLabels,
    maxValue: chartsMaxVal()
  };
  const chartData = va(chartStats) ? chartStats.sort(byKey(chartProps.indexBy, direction === 'DESC', !initFalsy))
    .map(cs => {
      const estHourlyEarnings = cs.earnings / cs.timeInSys;
      const avgSurveysPHour = cs.surveys_ig / cs.timeInSys;

      const payload = {
        ...cs,
        lineValue: cs.surveys,
        surveyTime: avgSurveysPHour,
        surveyTimeLabel: LABEL.Avg_Surveys_P_Hr,
        surveyTimeValue: fixedFloat(avgSurveysPHour, 1),
      };
      if (!isCIMR) {
        Object.assign(payload, {
          earnings: estHourlyEarnings,
          earningsLabel: LABEL.Avg_Hourly_Earnings,
          earningsValue: asCurrency(estHourlyEarnings),
        })
      }
      return payload;
    }) : [];

  React.useEffect(fse, []);

  return (
    <>
      {
        loader &&
        <Loader />
      }

      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">Surveyor Call Stats</h1>
          </Grid.Column>
        </Grid.Row>
        <FormWrap {...filtersFormConfig} />
        <Grid.Row>
          <Grid.Column width={16}>
            <Card fluid>
              <Card.Content>
                <Chart data={chartData} {...chartProps} />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Card fluid>
              <Card.Content>
                <GridView footer={total} headers={headers} onSort={onSort} sortKeys={sortKeys} list={list} />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};
const ReduxStoreMapper = (store: StoreT) => ({
  callStats: store.callStats,
  chartStats: store.chartStats,
  projects: store.projects,
  surveyors: store.surveyorInfo,
  user: store.auth.user
});

export default connect(ReduxStoreMapper)(CallStats);
