import React from 'react';

const CallQuality = () => {
  return (
    <>
     Call Quality
    </>
  );
};

export default CallQuality;
