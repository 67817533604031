import React from 'react';
import {Card, Grid} from '../../components/Inputs';
import {QAList, QuestionAnsBank} from '../../components/Questionnaire';
import {Answer, BN, Question} from '../../model';
import {initFalsy} from '../../constants';
import {useActions} from '../../redux/actions';
import * as languagesActions from '../../redux/actions/languages';
import * as questActions from '../../redux/actions/questions';
import * as ansActions from '../../redux/actions/answers';
import {sleep, vo} from '../../utils/objectUtils';


const QBM = () => {
  const
    [isLoading, setIsLoading] = React.useState(initFalsy),
    [isAddQ, setIsAddQ] = React.useState(initFalsy),
    [editAns, setIsEditAns] = React.useState({} as Answer),
    [isAddAns, setIsAddAns] = React.useState(initFalsy),
    [editQ, setIsEditQ] = React.useState({} as Question),
    [tabQAIndex, setTabQAIndex] = React.useState(0),
    [searchValue, setSearchValue] = React.useState(''),
    lan = useActions(languagesActions),
    que = useActions(questActions),
    ans = useActions(ansActions),
    clear = () => {
      // que.flushQuestions();
      setIsAddQ(initFalsy);
      setIsAddAns(initFalsy);
      setIsEditQ({} as Question);
      setIsEditAns({} as Answer);
    },
    onTabChange = async (tabIdx: number) => {
      clear();
      await sleep(50);
      setTabQAIndex(tabIdx);
    },
    onDelete: (id: BN, isQ: boolean) => Promise<void> = async (id, isQ) => {
      if (isQ) {
        await que.removeQuestions(id);
      } else {
        await ans.removeAnswers(id);
      }
    },
    onSave = async (isAdd: boolean, payload: Question) => {
      setIsLoading(!initFalsy);
      if (isAdd) {
        await que.addQuestions(payload);
      } else if (vo(editQ) && editQ.id) {
        const updPayload = {...payload, id: editQ.id};
        await que.updateQuestions(updPayload);
      }
      clear();
      setSearchValue('');
      setIsLoading(initFalsy);
    },
    onSaveAns = async (isAdd: boolean, payload: Answer) => {
      setIsLoading(!initFalsy);
      if (isAdd) {
        await ans.addAnswers(payload);
      } else if (vo(editAns) && editAns.id) {
        const updPayload = {...payload, id: editAns.id};
        await ans.updateAnswers(updPayload);
      }
      clear();
      setSearchValue('');
      setIsLoading(initFalsy);
    },
    showAdd: () => void = async () => {
      clear();
      await sleep(50);
      setIsAddQ(!isAddQ);
    },
    onEdit: (q: Question) => void = async (q) => {
      clear();
      setIsLoading(!initFalsy);
      await sleep(200);
      setIsEditQ(q);
      setIsLoading(initFalsy);
    },
    onAddAns: () => void = async () => {
      clear();
      await sleep(50);
      setIsAddAns(!isAddAns);
    },
    onEditAns: (a: Answer) => void = async (a) => {
      clear();
      setIsLoading(!initFalsy);
      await sleep(200);
      setIsEditAns(a);
      setIsLoading(initFalsy);
    },
    commonProps = {
      loading: isLoading,
      onCancel: clear,
      tabIndex: tabQAIndex
    },
    answerBankProps = {
      ...commonProps,
      edit: editQ,
      isAdd: isAddQ,
      isQ: !initFalsy,
      onSave
    },
    qaListProps = {
      onAddQ: showAdd,
      onAddR: onAddAns,
      onDel: onDelete,
      onEditQ: onEdit,
      onEditR: onEditAns,
      onTabChange,
      searchValue,
      setSearchValue,
      tabIndex: tabQAIndex
    },
    questionBankProps = {
      ...commonProps,
      edit: editAns,
      isAdd: isAddAns,
      isQ: initFalsy,
      onSave: onSaveAns
    },
    firstLoad = () => {
      lan.fetchLanguages(undefined, undefined, undefined, undefined, initFalsy);

      return () => {
        que.flushQuestions();
        ans.flushAnswers();
        lan.flushLanguages();
      };
    };

  React.useEffect(firstLoad, [searchValue]);

  return (
    <Grid>
      <Grid.Row className="headerTop">
        <Grid.Column width={16}>
          <h1 className='mainTitle'>Question Bank Manager</h1>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={6} tablet={16} mobile={16}>
          <Card fluid={!initFalsy}>
            <Card.Content>
              <QAList {...qaListProps} />
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column computer={10} tablet={16} mobile={16}>
          <Card fluid={!initFalsy}>
            <Card.Content id="QAB">
              {
                (isAddQ || editQ) &&
                <QuestionAnsBank {...questionBankProps} />
              }
              {
                (isAddAns || editAns) &&
                <QuestionAnsBank {...answerBankProps} />
              }
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default QBM;
