import React from 'react';
import {useSelector} from 'react-redux';
import {Button, Card, Confirm, GridView, Icon, Paging} from '../Inputs';
import {va} from '../../utils/arrayUtils';
import {CONFIRM_MESSAGES, DEFAULT_RECORDS_PER_PAGE, Del, initFalsy, ROLES} from '../../constants';
import {getSkipLimit} from '../../utils/common';
import * as userAccActions from '../../redux/actions/users';
import {useActions} from '../../redux/actions';
import {Account, BN, ButtonProps, IconProps, NS, RC, ST, StoreT, T, UsersList} from '../../model';


type FU = (skip?: number, lim?: number, sortOn?: (string | undefined), sortBy?: ST) => Promise<T>;
type P = { onAction: Function, search?: string, role?: NS | undefined, reload?: boolean, activePage: number, limit: number, setPageSize: Function, setPageChange: Function, isAdmin?: boolean, status?: boolean | null };
type ListAccountsProps = P & { children?: RC }

const AccountsList: React.FC<ListAccountsProps> = (props: ListAccountsProps) => {
  const headers = [
    'First Name',
    'Last Name',
    'Email',
    'Type',
    'Contact Number',
    'Vonage Extension',
    'Status',
    'Action'
  ];
  const sortKeys = ['firstName', 'lastName', 'email', 'role', 'phoneNumber', 'vonageExt'];
  const {activePage, isAdmin, limit, onAction, role, reload, search, setPageChange, setPageSize, status} = props;
  const userAction = useActions(userAccActions);
  const [daOpen, showDAConfirm]: [BN, Function] = React.useState(initFalsy);
  const [deactivating, setDeactivating]: [boolean, Function] = React.useState(initFalsy);
  const [inviting, setInviting]: [BN, Function] = React.useState(initFalsy);
  const [deactivated, setDeactivated] = React.useState(initFalsy);
  const [isDelBtn, setDelBtn] = React.useState(initFalsy);
  const users = useSelector((state: StoreT) => state.users as UsersList);
  const {rows, count} = users;
  const navFux = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    fetchUsers(skip, gridSize).then();
  };
  const refreshInvite = async (id: number) => {
    setInviting(id);
    await userAction.reInvite(id);
    setInviting(initFalsy);
  };
  const onSort = (cc: string, direction: ST) => {
    const skip = getSkipLimit(activePage, limit);
    fetchUsers(skip, limit, cc, direction).then();
  };
  const onDelete = (isDeleted?: boolean, id?: BN) => {
    if (isDeleted !== undefined) {
      setDelBtn(initFalsy);
      setDeactivated(isDeleted);
    } else {
      setDelBtn(!initFalsy);
    }

    showDAConfirm(id);
  };
  const performDelOperation = async (id: BN) => {
    setDeactivating(!initFalsy);
    const payload = {id, status: !deactivated ? !initFalsy : initFalsy};
    isDelBtn ? await userAction.removeUser(id) : await userAction.deactivateUser(payload);
    fetchUsers().then();
    onDelete(initFalsy, initFalsy);
    setDeactivating(initFalsy);
  };
  const fetchUsers: FU = async (skip = getSkipLimit(activePage, limit), lim = DEFAULT_RECORDS_PER_PAGE, sortOn = 'firstName', sortBy = 'ASC') => {
    return userAction.fetchUserAccounts(skip, lim, sortOn, sortBy, role, search, !initFalsy, !initFalsy, status);
  };
  const pageLifeCycle = () => {
    // on mount
    fetchUsers().then();

    // on un mount
    return () => userAction.flushUserAccounts();
  };
  const DelButton = () => {
    const content = isDelBtn ? Del.YD : !deactivated ? Del.YDA : Del.YRA;
    const delButtProps: ButtonProps = {
      color: isDelBtn ? 'red' : !deactivated ? 'red' : undefined,
      content,
      loading: deactivating,
      onClick: () => performDelOperation(daOpen),
      primary: isDelBtn ? initFalsy : !deactivated ? initFalsy : !initFalsy,
      type: 'button'
    };
    return (
      <Button {...delButtProps} />
    )
  };
  const delConfirmProps = {
    confirmButton: DelButton,
    content: isDelBtn ? CONFIRM_MESSAGES.USER_ACCOUNT_DELETE : deactivated === initFalsy ? CONFIRM_MESSAGES.USER_DEACTIVATE : CONFIRM_MESSAGES.REACTIVATE_USER,
    header: !!daOpen && isDelBtn ? Del.DA : !deactivated ? Del.DACTU : Del.RACTU,
    onCancel: () => onDelete(initFalsy, initFalsy),
    open: !!daOpen
  };
  const gridProps = {headers, onSort, sortKeys};
  const paginationProps = {
    activePage: activePage,
    navigate: navFux,
    pageSize: limit,
    setPageChange: setPageChange,
    setPageSize: setPageSize,
    totalRecords: count
  };
  const userRows = va(rows) ? rows.map((user: Account) => {
    const {id, email, isDeleted, firstName, lastName, phoneNumber, role, vonageExt} = user;
    const clickHandler = (id: number, action: string) => onAction({id, action});
    const userRole = ROLES[role];
    const isInv = (inviting as unknown as number) === id;
    const deactivateIconProps: IconProps = {
      name: isDeleted ? 'lock' : 'lock open',
      onClick: () => onDelete(isDeleted, id),
      title: isDeleted ? Del.RACTU : Del.DACTU
    };
    const userStatus = isDeleted ? Del.DE : Del.AC;
    const viewIcon: IconProps = {name: 'eye', onClick: () => clickHandler(id, 'view'), title: 'View Details'};
    const actionIcons: IconProps[] = isAdmin ? [
      viewIcon,
      {name: 'edit', onClick: () => clickHandler(id, 'edit'), title: 'Edit'},
      {name: 'trash alternate', title: 'Delete', onClick: () => onDelete(undefined, id)},
      {name: 'refresh', loading: isInv, onClick: () => refreshInvite(id), title: 'Re-Invite'},
      deactivateIconProps,
    ] : [viewIcon];
    const Action = (
      <>
        {
          actionIcons.map((iconProps: IconProps, key: number) => (
            <Icon key={key} className="hand-pointer" {...iconProps} />
          ))
        }
      </>
    );

    return {_warn: isDeleted, firstName, lastName, email, userRole, phoneNumber, vonageExt, userStatus, Action};
  }) : [];

  React.useLayoutEffect(pageLifeCycle, [role, search, reload, status]);

  return (
    <Card fluid>
      <Card.Content>
        <GridView list={userRows} {...gridProps} />

        <Paging {...paginationProps} />

        {
          !!daOpen &&
          <Confirm {...delConfirmProps} />
        }
      </Card.Content>
    </Card>
  )
};

export default AccountsList;
