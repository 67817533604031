import React from 'react';
import {useSelector} from 'react-redux';
import {Button, Card, Form, FormField, FormFieldType, Grid, Select, TextBox} from '../../Inputs';
import {ButtonProps, FieldProps, FormProps, SelectValue, StoreT} from '../../../model';
import {addAccountFormJson, initFalsy} from '../../../constants';
import {hasError} from '../../../utils/validation';
import {mapSelectOption} from '../../../utils/common';


interface ExtraProps {
  setRole: Function;
  curRole: string;
  role: number;
  onCancel: Function;
  userRoles: Array<string | number>;
}

interface AddFormProps extends FormProps {
  extraProps: ExtraProps;
}

const AddForm = (formProps: AddFormProps) => {
  const Truthy = !initFalsy;
  const {errors, handleChange, handleSubmit, isSubmitting, values, extraProps} = formProps;
  const {onCancel, setRole, curRole, userRoles}: ExtraProps = extraProps;
  const languageOptions = useSelector((state: StoreT) => state.languages) || [];
  const selProps = {
    label: 'Languages',
    multiple: Truthy,
    name: 'langIds',
    options: languageOptions.map(({id, name}) => ({text: name, value: id})),
    placeholder: 'Select Languages',
    upward: Truthy
  };
  const submitButton: ButtonProps = {
    content: 'Create Account',
    className: 'ml10 mb15',
    loading: isSubmitting,
    primary: Truthy,
    type: 'submit'
  };
  const cancelButton: ButtonProps = {
    content: 'Cancel',
    secondary: Truthy,
    onClick: onCancel,
    className: 'mb15'
  };
  const addFormJSON = addAccountFormJson.map((field: FieldProps) => {
    return (
      field.name ?
        Object.assign(field, {
          autoComplete: 'off',
          disabled: !Truthy,
          error: hasError(errors[field.name]),
          onChange: handleChange,
          value: values[field.name]
        }) :
        Object.assign(field, {
          disabled: isSubmitting
        })
    );
  });
  const addFormButtons = [cancelButton, submitButton];

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={10}>
          <Card>
            <Card.Header>
              <h2 className="subTitle">Add Account</h2>
            </Card.Header>
            <Card.Content className="pb0">
              <Form onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={8}>
                      <Select
                        label="Select Account Type"
                        name="userRole"
                        onChange={(value: SelectValue) => setRole(value)}
                        options={mapSelectOption(userRoles.sort())}
                        placeholder="Select Account Type"
                      />
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <h3 className="w100 subHeaderInner">
                        <span>Provide Account Details For New {curRole}</span>
                      </h3>
                    </Grid.Column>
                    {
                      addFormJSON.map((field: FieldProps, key: number) => (
                        <Grid.Column mobile={16} tablet={16} computer={8} key={key}>
                          {
                            <TextBox {...field} />
                          }
                        </Grid.Column>
                      ))
                    }
                    {
                      (curRole === 'Surveyor' || curRole === 'CIMR Surveyor' || curRole === 'Keller Surveyor')&&
                      <Grid.Column mobile={16} tablet={16} computer={8}>
                        <FormField type={FormFieldType.Select} {...selProps} />
                      </Grid.Column>
                    }
                    <Grid.Column width={16} textAlign="right">
                      {
                        addFormButtons.map((buttonProps: ButtonProps, key: number) => (
                          <Button key={key} {...buttonProps} />
                        ))
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AddForm;
