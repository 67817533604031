import {ActionType, Answer, ApiEngine, CleanResponse} from '../../model';
import {setAlert, setSuccess} from './alert';

const apiPath = 'answer';

export const flushAnswers = () => async (dispatch: Function) => {
  dispatch({type: ActionType.ANSWERS_FLUSH, payload: []});
};

export const fetchAnswers = (search?: string, isQsBuilder?: boolean) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const query = {search};
      if (isQsBuilder) {
        Object.assign(query, {questionnaire: isQsBuilder})
      }
      const data: CleanResponse = await api.get(apiPath, null, query);
      dispatch({type: ActionType.ANSWERS_FETCH, payload: data});
      // setSuccess(data.message);
      return;
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

export const addAnswers = (payload: Answer) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const res: CleanResponse = await api.post(apiPath, payload);
      dispatch({type: ActionType.ANSWERS_ADD, payload: res.data});
      setSuccess(res.message);
      return dispatch(fetchAnswers());
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

export const updateAnswers = (payload: Answer) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const res: CleanResponse = await api.put(apiPath, payload);
      dispatch({type: ActionType.ANSWERS_PUT, payload: res.data});
      setSuccess(res.message);
      return dispatch(fetchAnswers());
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

export const removeAnswers = (payload: {id: number, isAnsDel: boolean}) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const res: CleanResponse = await api.delete(apiPath, payload);
      dispatch({type: ActionType.ANSWERS_DEL, payload: res.data});
      setSuccess(res.message);
      return dispatch(fetchAnswers());
    } catch (err) {
      dispatch(setAlert(err));
    }
  };

export const removeLangAnswer = (langAnswerId: number) =>
  async (dispatch: Function, _store: Function, api: ApiEngine) => {
    try {
      const payload = {langAnswerId};
      const res: CleanResponse = await api.delete(apiPath, payload);
      dispatch({type: ActionType.ANSWERS_LANG_DEL, payload: res.data});
      setSuccess(res.message);
      return dispatch(fetchAnswers());
    } catch (err) {
      dispatch(setAlert(err));
    }
  };
