import React from 'react';
import {useParams} from 'react-router-dom';
import {PublicLayout} from './index';
import {CreatePassword} from '../../components/Accounts';
import { T } from '../../model';


const ConfirmPwdPage: React.FC = () => {
  const {resetToken}: T = useParams();

  return (
    <PublicLayout>
      {
        resetToken &&
        <CreatePassword />
      }
    </PublicLayout>
  );
};

export default ConfirmPwdPage;
