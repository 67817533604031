import React from 'react';
import {useSelector} from 'react-redux';
import {Loader} from '../../components';
import {
  Button,
  Card,
  Confirm,
  Form,
  FormField,
  FormFieldType,
  FormWrap,
  Grid,
  GridView,
  Icon,
  Paging
} from '../../components/Inputs';
import {CONFIRM_MESSAGES, DEFAULT_RECORDS_PER_PAGE, initFalsy, Language} from '../../constants';
import {
  BN,
  ButtonProps,
  FormFieldProps,
  FormikValues,
  FormProps,
  IconProps,
  LanguagesList,
  SelectValue,
  ST
} from '../../model';
import {useActions} from '../../redux/actions';
import * as languageActions from '../../redux/actions/languages';
import {enumAsArray, va} from '../../utils/arrayUtils';
import {getSkipLimit, mapSelectOption} from '../../utils/common';

interface Props {
}

interface AddFormProps extends FormProps {
  // extraProps: ExtraProps;
}

type T = { onCancel: Function, fetchLgs: Function };

type FU = (skip?: number, lim?: number, sortOn?: (string | undefined), sortBy?: ST) => Promise<void>;

const AddLanguage: React.FC<T> = (props: T) => {
  let formTitle = 'Add New Language';
  const
    [chk, setChk] = React.useState(initFalsy),
    [languageFrmList, setLanguageFrmList] = React.useState(),
    lang = useActions(languageActions),
    selProps = {
      label: 'Languages',
      name: 'langIds',
      options: mapSelectOption(enumAsArray(Language, !initFalsy), !initFalsy),
      placeholder: 'Select Languages'
    },
    addFormJSON: FormFieldProps[] = [
      {
        label: 'Language Name',
        name: 'name',
        type: FormFieldType.Text
      },
      {
        onClick: () => setChk(!chk),
        label: 'Select Language from a List',
        name: 'chk',
        type: FormFieldType.CheckBox,
        value: chk
      },
      {
        ...selProps,
        defaultValue: languageFrmList,
        className: chk ? '' : 'invisible',
        label: 'A List of Languages',
        name: 'name',
        type: FormFieldType.Select,
        onChange: (value: SelectValue) => setLanguageFrmList(value),
      }
    ];

  const
    {onCancel, fetchLgs} = props,
    name = '',
    LanguageForm = ({handleSubmit, isSubmitting, resetForm}: AddFormProps) => {
      const addFormButtons: ButtonProps[] = [
        {content: 'Cancel', onClick: resetForm, secondary: !initFalsy},
        {content: 'Save', className: 'ml10', disabled: isSubmitting, loading: isSubmitting, primary: !initFalsy}
      ];
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Card fluid={!initFalsy} className="mb20">
                <Card.Header>
                  <h2 className="subTitle">{formTitle}</h2>
                </Card.Header>
                <Card.Content>
                  <Form onSubmit={handleSubmit}>
                    <Grid>
                      <Grid.Row>
                        {
                          addFormJSON.map((fieldProps, key: number) => {
                            return (
                              <Grid.Column key={key} mobile={16} tablet={16} computer={8}>
                                {
                                  <FormField {...fieldProps} />
                                }
                              </Grid.Column>
                            )
                          })
                        }

                        <Grid.Column mobile={16} tablet={16} computer={16} className="mt15" textAlign="right">
                          {
                            addFormButtons.map((buttonProps: ButtonProps, key: number) => (
                              <Button key={key} {...buttonProps} />
                            ))
                          }
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
    },
    langFrmConfig = {
      displayName: 'language_form',
      initialValues: {name},
      onSubmit: async ({name}: FormikValues, {resetForm}: FormikValues) => {
        try {
          if (name) {
            await lang.addLanguage(name);
            await fetchLgs();
          }
          onCancel();
        } catch (err) {
          resetForm();
        }
      },
      children: LanguageForm
    };

  return (
    <>
      <FormWrap {...langFrmConfig} />
    </>
  )
};

const Languages: React.FC<Props> = () => {
  const
    initPageNo = 1,
    [loading, setLoading] = React.useState(initFalsy),
    [isAdding, onAddLang] = React.useState(initFalsy),
    [isDeleting, onDeleting] = React.useState(initFalsy),
    [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE),
    [activePage, setPageChange] = React.useState(initPageNo),
    [remLangId, setDelId]: [BN, Function] = React.useState(initFalsy),
    lang = useActions(languageActions),
    languages = useSelector((state: { languages: LanguagesList }) => state.languages) || [],
    confirmButton = (
      <Button content="Delete Language" loading={isDeleting} color="red" primary={initFalsy} type="button" />
    ),
    onRemove = async (id: BN) => {
      onDeleting(!initFalsy);
      await lang.delLanguages(id);
      const skip = getSkipLimit(activePage, limit);
      await fetchLgs(skip, limit);
      setDelId(initFalsy);
      onDeleting(initFalsy);
    },
    navFux = (activePage: number, gridSize: number) => {
      const skip = getSkipLimit(activePage, gridSize);
      fetchLgs(skip, gridSize).then();
    },
    fetchLgs: FU = async (skip = 0, limit = DEFAULT_RECORDS_PER_PAGE, orderBy = 'name', orderDir = 'ASC') => {
      setLoading(!initFalsy);
      await lang.fetchLanguages(skip, limit, orderBy, orderDir, !initFalsy);
      setLoading(initFalsy);
    },
    lyf = () => {
      fetchLgs().then();

      return () => lang.flushLanguages();
    },
    addBtnProps: ButtonProps = {
      icon: 'plus',
      labelPosition: 'left',
      className: 'float-right mb10 ml10',
      primary: !initFalsy
    },
    actionProps: IconProps = {name: 'trash', title: 'Remove Language'},
    languageHeaders = ['Language', 'Actions'],
    languageList = va(languages.rows) ? languages.rows.map(({id, name}) => ({
      name,
      actions: <Icon className="hand-pointer" onClick={() => setDelId(id)} {...actionProps} />
    })) : [];

  React.useEffect(lyf, []);

  return (
    <>
      {
        loading &&
        <Loader />
      }

      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">Languages</h1>
            <Button {...addBtnProps} content="Add New Language" onClick={() => onAddLang(!initFalsy)} />
          </Grid.Column>
        </Grid.Row>
        {
          isAdding &&
          <Grid.Row>
            <Grid.Column width={16}>
              <AddLanguage onCancel={() => onAddLang(initFalsy)} fetchLgs={fetchLgs} />
            </Grid.Column>
          </Grid.Row>
        }
        <Grid.Row>
          <Grid.Column width={16}>
            <Card fluid>
              <Card.Content>
                <GridView headers={languageHeaders} list={languageList} />

                <Paging
                  totalRecords={languages.count}
                  navigate={navFux}
                  activePage={activePage}
                  pageSize={limit}
                  setPageSize={setPageSize}
                  setPageChange={setPageChange}
                />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>

        {
          !!remLangId &&
          <Confirm
            content={CONFIRM_MESSAGES.LANGUAGE}
            header="Delete Language"
            confirmButton={confirmButton}
            open={!!remLangId}
            onCancel={() => setDelId(initFalsy)}
            onConfirm={() => onRemove(remLangId)}
          />
        }
      </Grid>
    </>
  );
};

export default Languages;
