import React from 'react';
import {Earnings} from '../../components/Surveyors';
import {initFalsy} from '../../constants';

const SurveyorEarnings = () => {
  return (
    <>
      <Earnings isPM={!initFalsy} />
    </>
  );
};

export default SurveyorEarnings;
