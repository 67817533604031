import createReducer from './createReducer';
import {Action, ActionType, Questionnaire, QuestionnaireList, QsLogic, T} from '../../model';

const initQs: Partial<Questionnaire>[] = [];
const initQLs: Partial<QuestionnaireList> = {};
const initLs: Partial<QsLogic>[] = [];

export const questionnaireList = createReducer(initQLs, {
  [ActionType.QUESTIONNAIRE_LIST_FETCH](_store: Function, action: Action<QuestionnaireList>) {
    return action.payload;
  },
  [ActionType.QUESTIONNAIRE_LIST_FLUSH](_store: Function, action: Action<T>) {
    return action.payload;
  }
});

export const questionnaire = createReducer(initQs, {
  [ActionType.QUESTIONNAIRE_FETCH](_store: Function, action: Action<Questionnaire[]>) {
    return action.payload;
  },
  [ActionType.QUESTIONNAIRE_FLUSH](_store: Function, action: Action<T>) {
    return action.payload;
  }
});

export const questionnaireLogic = createReducer(initLs, {
  [ActionType.QUESTIONNAIRE_LOGIC_FETCH](_store: Function, action: Action<QsLogic[]>) {
    return action.payload;
  },
  [ActionType.QUESTIONNAIRE_LOGIC_FLUSH](_store: Function, action: Action<T>) {
    return action.payload;
  }
});
