import {Action, ActionType, EscalationList, Escalation} from '../../model';
import createReducer from './createReducer';

const initEscalationsState: EscalationList = {count: 0, rows: []};

export const escalations = createReducer(initEscalationsState, {
  [ActionType.ESCALATION_FETCH](_store: Function, action: Action<EscalationList>) {
    return action.payload;
  },

  [ActionType.ESCALATION_FLUSH](_store: Function, action: Action<void>) {
    return action.payload;
  },
});

export const escalationInfo = createReducer([], {
  [ActionType.ESCALATION_FETCH_BY_ID](_store: Function, action: Action<Escalation>) {
    return action.payload;
  },

  [ActionType.ESCALATION_FLUSH_BY_ID](_store: Function, action: Action<void>) {
    return action.payload;
  },
});
