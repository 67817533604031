import {Action, ActionType, Language} from '../../model';
import createReducer from './createReducer';

const initialLang: Array<Language> = [];

export const languages = createReducer(initialLang, {
  [ActionType.FETCH_LANGUAGES](_store: Function, action: Action<Language>) {
    return action.payload;
  },
  [ActionType.FLUSH_LANGUAGES](_store: Function, action: Action<void>) {
    return action.payload;
  }
});
