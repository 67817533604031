import React from 'react';
import CRLogo from '../styles/images/cr-logo.png';


const Logo: React.FC = () => {
  return (
    <img src={CRLogo} alt="cr-logo" />
  );
};

export default Logo;
