import {DependencyList, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {AnyAction, T} from '../../model';
import {va} from '../../utils/arrayUtils';


type actionT = AnyAction | T;
export const useActions = (actions: actionT, deps?: DependencyList | undefined): actionT => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (va(actions)) {
        return actions.map((a: actionT) => bindActionCreators(a, dispatch));
      }
      return bindActionCreators(actions, dispatch);
    },
    // eslint-disable-next-line
    deps ? [dispatch, ...deps] : deps
  );
};
