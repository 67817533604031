import React from 'react';
import {useSelector} from 'react-redux';
import {Message} from '../components/Inputs';
import {Alert, MessageBoxProps, StoreT} from '../model';
import {initFalsy} from '../constants';


export const ToastAlert: React.FC<MessageBoxProps> = (props: MessageBoxProps) => {
  const
    color = props.severity || 'red',
    size = props.size || 'small',
    content = props.message,
    [isHidden, hideItNow] = React.useState(!initFalsy),
    onDismiss = () => hideItNow(!isHidden);
  return (
    <>
      {
        isHidden &&
        <Message className="alert" color={color} size={size} onDismiss={onDismiss}>
          {content}
        </Message>
      }
    </>
  );
};

const Alerter = () => {
  const alert: Alert = useSelector((state: StoreT) => state.alert);
  return (
    <>
      {
        alert && alert.successMsg &&
        <ToastAlert severity="green" message={alert.successMsg} />
      }

      {
        alert && alert.message &&
        <ToastAlert message={alert.message} />
      }
    </>
  );
};

export default Alerter;
