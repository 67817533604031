import React from 'react';
import {CommentCleaning} from '../../components/CommentCleaner';


const PMCommentCleaning = () => {
  return (
    <CommentCleaning />
  );
};

export default PMCommentCleaning;
