import * as React from 'react';
import {useSelector} from 'react-redux';
import {Footer, Header, LeftMenu, Wrapper} from '../../components';
import {useActions} from '../../redux/actions';
import * as appActions from '../../redux/actions/nav';
import {ParentPath} from '../../routes';
import {NAV} from '../../constants';
import {RC, StoreT} from '../../model';
import * as authActions from '../../redux/actions/auth';

type P = { mainHeading?: string };
const AdminDashboard: React.FC<P & RC> = (props: P & RC) => {
  const // {role} = useSelector((state: StoreT) => state.auth.user),
    {isExpanded} = useSelector((state: StoreT) => state.nav),
    {SetLeftNav, GetLeftNav} = useActions(appActions),
    {Load} = useActions(authActions),
    styles: React.CSSProperties = {
      position: 'relative',
      overflow: 'auto',
      transition: 'all .15s',
      padding: '88px 20px 0',
      marginLeft: `${isExpanded ? 300 : 75}px`
    },
    toggleFunc = () => SetLeftNav(isExpanded),
    pageLifeCycle = () => {
      GetLeftNav();
      Load();
    };

  React.useEffect(pageLifeCycle, [isExpanded]);

  return (
    <Wrapper>
      <Header expanded={isExpanded} />

      <LeftMenu
        navItems={NAV.Admin}
        parentLink={ParentPath.Admin}
        expanded={isExpanded}
        onToggle={toggleFunc}
      />

      <main className="mainWrap" style={styles}>
        {
          props && props.mainHeading &&
          <h1 className="mainTitle">{props.mainHeading}</h1>
        }
        {
          props && props.children
        }
      </main>

      <Footer />
    </Wrapper>
  );
};

export default AdminDashboard;
