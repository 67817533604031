import React from 'react';
import {useHistory, useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {Account, LabelField, StoreT, T} from '../../model';
import {Button, Card, Chart, Grid, TextBox} from '../Inputs';
import {ProjectName} from '../../components/Projects';
import {BAR_PRIMARY_COLOR, GoalsHeader, initFalsy, LINE_COLOR} from '../../constants';
import {getQuestionnaireViewerRoute, isFunction, noop, pdcChartHeight} from '../../utils/common';
import {va} from '../../utils/arrayUtils';
import {piz} from '../../utils/numUtils';


type CH = { surveys: number, surveyDate: string, cSurveys: number };

interface AAAProps extends LabelField {
}

interface CallHistoryProps {
  callsHistory: CH[];
  height?: number;
  isLock?: boolean;
  isWeeklySelected: boolean;
  maxValue?: number;
  proDetails: T[];
  projectName?: string;
}

const _divTxt = ({icon, label, onClick, title, value}: Partial<AAAProps>) => (
  <div className="asAnchor field hand-pointer" onClick={(isFunction(onClick) ? onClick : noop) as T} title={title}>
    <label>{label}</label>
    <div className="ui disabled icon input mb5">
      <span className="ui button asAnchor">{value}</span>
      {
        !!icon &&
        <i aria-hidden="true" className={`${icon} icon`} />
      }
    </div>
  </div>
);
export const AsAAnchor = (props: AAAProps) => {
  const {as, ...otherProps} = props;
  const defType: string = 'a';
  const _txtProps = as === defType ? {value: otherProps.value} : {disabled: !initFalsy, ...props};

  return as === defType ? React.createElement(_divTxt, otherProps) : React.createElement(TextBox, _txtProps);
};

const CallHistory: React.FunctionComponent<CallHistoryProps> = (props) => {
  const hist = useHistory();
  const {id, pid}: T = useParams();
  const projectId = piz(id || pid);
  const {callsHistory, height, isLock, isWeeklySelected, proDetails, projectName} = props;
  const user: Account = useSelector((state: StoreT) => state.auth.user);
  const includeLine = !initFalsy;
  const axisBottomLabel = isWeeklySelected ? 'Day Of' : 'Week Of';
  const axisLeftLabel = 'Surveys';
  const _height = `${height || pdcChartHeight()}px`;
  const indexBy = 'surveyDate';
  const keys = ['cSurveys'];
  const subTitle = isWeeklySelected ? 'Week' : 'Reporting Period';
  const legendLabels = includeLine ?
    (isWeeklySelected ? [GoalsHeader.Weekly, GoalsHeader.WeeklyTotal] : [GoalsHeader.RP, GoalsHeader.RPTotal]) :
    [GoalsHeader.Weekly];
  const chartProps = {
    availableColors: [BAR_PRIMARY_COLOR, LINE_COLOR],
    axisBottomLabel,
    axisLeftLabel,
    height: _height,
    includeLine,
    indexBy,
    keys,
    legendLabels,
    lineValueLabel: isWeeklySelected ? 'Current Week Minimum' : 'Weekly Minimum',
    maxValue: props.maxValue,
  };
  const qsUrl = getQuestionnaireViewerRoute(user.role, projectId);
  const qsLinkButtProps = {
    as: 'a',
    className: 'asAnchor',
    content: 'View Questionnaires',
    href: qsUrl,
    icon: 'eye',
    onClick: (_e: React.SyntheticEvent) => {
      _e.preventDefault();
      hist.push(qsUrl);
    },
  };
  const SIZE_ZERO_BAR = (props.maxValue || 50) / 100;
  const chartData = va(callsHistory) ? callsHistory.map(e => ({
    ...e,
    cSurveysLabel: 'Surveys Completed',
    cSurveys: !isWeeklySelected && e.cSurveys === 0 ? SIZE_ZERO_BAR : e.cSurveys,
    cSurveysValue: e.cSurveys
  })) : [];

  return (
    <>
      <Grid.Column computer={6} tablet={12} mobile={16}>
        <Card id="pdc" fluid={includeLine} className="disabledList">
          {
            projectName &&
            <Card.Header>
              <h2 className="subTitle">
                <ProjectName name={projectName} isLocked={isLock} />
              </h2>
            </Card.Header>
          }
          <Card.Content>
            <Grid.Row>
              <Grid.Column width={16}>
                <h3 className="subHeaderInner">
                  <span>Project Details</span>
                </h3>
              </Grid.Column>
              {
                proDetails.map((f, k) => (
                  <Grid.Column key={k} width={16}>
                    <AsAAnchor {...f} />
                  </Grid.Column>
                ))
              }
            </Grid.Row>
          </Card.Content>
          <Card.Content extra>
            <Button {...qsLinkButtProps} />
          </Card.Content>
        </Card>
      </Grid.Column>
      <Grid.Column computer={10} tablet={16} mobile={16}>
        <Card fluid={includeLine}>
          <Card.Header>
            <h2 className="subTitle">Current {subTitle} Progress</h2>
          </Card.Header>
          <Card.Content>
            <Grid.Row>
              <Grid.Column computer={16} tablet={16} mobile={16}>
                {
                  va(callsHistory) &&
                  <div className="chartHolder">
                    <Chart data={chartData} {...chartProps} />
                  </div>
                }
                {
                  !va(callsHistory) &&
                  <p className="center-force">No Surveys Done This {subTitle}</p>
                }
              </Grid.Column>
            </Grid.Row>
          </Card.Content>
        </Card>
      </Grid.Column>
    </>
  );
};

export default CallHistory;
