import React from 'react';
import {ProjectsDetails} from '../../components/Projects';
import {initFalsy} from '../../constants';


const ProjectDetail = () => {
  return (
    <ProjectsDetails isManager={!initFalsy} />
  );
};


export default ProjectDetail;

