import React from 'react';
import {CallStats} from '../../components/Calls';
import {initFalsy} from '../../constants';

const Stats = () => {
  return (
    <>
      <CallStats isOp={!initFalsy} />
    </>
  );
};

export default Stats;
