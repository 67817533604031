export const __NODE_ENV__: string = process.env.NODE_ENV;
export const __REACT_APP_ENV__ = process.env.REACT_APP_ENV;

export const isDev = __NODE_ENV__ === 'development';
export const isBeta = __REACT_APP_ENV__ === 'beta';
export const isStage = __REACT_APP_ENV__ === 'staging';


export const ShortRoles: { [key: string]: string; } = {
  Admin: 'Admin',
  Operations: 'OPS',
  'Project Manager': 'PM',
  Surveyor: 'Surveyor',
  Quality: 'QA',
  Finance: 'FA',
  'Comment Cleaner': 'CC',
  'CIMR Surveyor': 'CCSurveyor',
  'CIMR Manager': 'CIMR'
};

export const EMAIL_VALIDATE_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i;
export const PWD_TEST1_REGEX = /^(?=.*[a-z])/i;
export const PWD_TEST2_REGEX = /^(?=.*[A-Z])/;
export const PWD_TEST3_REGEX = /^(?=.*[0-9])/i;
export const PWD_TEST4_REGEX = /^(?=.{8,})/i;

export const initFalsy: boolean = false;

export const BRT = '<br />';

export const BAR_PRIMARY_COLOR = '#f5b439';
export const BAR_SECONDARY_COLOR = '#0d3fc3';
export const LINE_COLOR = '#000';

export const OPT_OUT_AGREE_KEY = 'optOutAgree';

export const SESSION_AUTH_KEY: string = 'session_key';

export const AUTH_TOKEN_REFRESH_IN: number = 6 * 60;

export const DEFAULT_ERROR = 'Something went wrong. Please try again later with/after a refresh. If you continue to see this error contact system administrator.';

export const NEW_CONTENT_ALERT = 'We have recently updated the system. In order for everything to work you will need to clear the cache for OSMS. Please press "Control" + "F5" together. In the event of any issues, Please contact your project manager.';

/**
 * Questionnaire Field Prefix
 */
export const QSF = 'qrf-';

/**
 * Survey Ignore Visit_additional_Info matcher
 */
export const VISIT_INFO_MATCH: string = '- LANG';

export const NA = 'N/A';

export const RETURN_KEY_CODE = 13;

export const DEFAULT_RECORDS_PER_PAGE = 10;

export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss';
export const CST_TIMEZONE = 'America/Chicago';
export const DATE_FORMAT = 'MM/DD/YY';
export const TODAY_DATE = new Date();

export const DEFAULT_MAX_PER_PAGE = 5000;

export const DEFAULT_PAGE_SIZES = [1, 5, 10, 25, 50, 100, 200, 500, 1000, DEFAULT_MAX_PER_PAGE];

export const DEFAULT_ALERT_SHOW_TIME = 3000; // milliseconds

export const DEFAULT_VALUE = 0;
export const GoogleDocsViewerURL = '//docs.google.com/gview';

/**
 * Surveyor Inactive Time (time in seconds)
 */
export const ON_SURVEY_WAIT_TIME = 90;
export const ON_SURVEY_SHOW_ALERT_IN_TIME = 30;
export const ON_NORMAL_USER_TIME = 600;
export const ON_NORMAL_SHOW_ALERT_IN_TIME = 60;

/**
 * 2Factor Authentication
 */
export const ipApiUrl = 'https://api.ipify.org?format=json';
export const initialMinute = 4; // 4 minute
export const initialSeconds = 59; //59 seconds
export const [ZERO, ONE, FOUR, ONE_SEC, HOURS_IN_DAY, TOT_COUNT] = [0, 1, 4, 1000, 24, 60];
export const COOKIE_NAME = '_reme' // _reme (Remember Me)
export const COOKIE_EXPIRE_DAYS = 365;


export const API_URL: string = isDev
  ? `//${window.location.hostname}:8000` // '//192.168.1.146:8000'
  // ? `//staging.api.cg-osms.com` // run as staging
  : ((__NODE_ENV__ === 'production' && isBeta) ? '//beta.api.cg-osms.com' : (
    (__NODE_ENV__ === 'production' && isStage) ?
      '//staging.api.cg-osms.com' :
      '//api.cg-osms.com'
  ));
