// DATE EXTENSIONS
// ================
// @ts-nocheck

// @ts-ignore
declare interface Date {

  /**
   * Add Days
   * @param d daysToAdd
   */
  addDays(d?: number): Date;

  addMonths(m: number): Date;

  lastDayOfMonth(): number;

  toISODateString(s?: string): string;

  toLongDateString(incYear?: boolean): string;

  toUSDateString(splitter?: string, useFullYear?: boolean, includeTime?: boolean, timeSplitter?: string): string;

  toUSDateTimeStr(dateSplitter?: string): string;

  toMMMDDYYYY(delimiter?: string): string;

  toYYYYMMDD(delimiter?: string): string;

  toMMDDYY(delimiter?: string): string;

  toYMDHMS(s?: string): string;

}


(function () {
  // eslint-disable-next-line no-extend-native
  Date.prototype.toLongDateString = function (incYear = false) {
    const locale = 'en-US';
    const year = incYear ? 'numeric' : undefined;
    const opt = {...{}, ...{day: 'numeric', month: 'long', weekday: 'long'}, year};
    if (isNaN(Date.parse((this).toString()))) {
      return '';
    }
    return new Intl.DateTimeFormat(locale, opt as Intl.DateTimeFormatOptions).format(this);
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.toUSDateString = function (splitter = '/', useFullYear = true, includeTime = false, timeSplitter = ':') {
    const dt = new Date(new Date(this).toLocaleString('en-US', {timeZone: 'UTC'}));
    const mm = (dt.getMonth() + 1).toString().padStart(2, '0');
    const dd = (dt.getDate()).toString().padStart(2, '0');
    const yy = useFullYear ? dt.getFullYear() : dt.getFullYear().toString().substring(-2);

    let ret = [mm, dd, yy].join(splitter);

    if (includeTime) {
      const hh = (dt.getHours()).toString().padStart(2, '0');
      const min = (dt.getMinutes()).toString().padStart(2, '0');
      const ss = (dt.getSeconds()).toString().padStart(2, '0');

      ret += [hh, min, ss].join(timeSplitter);
    }

    return ret;
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.toUSDateTimeStr = function (dateSplitter = '/') {
    const
      dt = new Date(new Date(this).toLocaleString('en-US', {timeZone: 'UTC'})),
      mm = (dt.getMonth() + 1).toString().padStart(2, '0'),
      dd = (dt.getDate()).toString().padStart(2, '0'),
      yy = dt.getFullYear(),
      hh = (dt.getHours()).toString().padStart(2, '0'),
      min = (dt.getMinutes()).toString().padStart(2, '0'),
      ss = (dt.getSeconds()).toString().padStart(2, '0'),
      splitter = ' ',
      timeSplitter = ':';

    return [[mm, dd, yy].join(dateSplitter), [hh, min, ss].join(timeSplitter)].join(splitter);
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.toYMDHMS = function () {
    const
      mm = (this.getMonth() + 1).toString().padStart(2, '0'),
      dd = (this.getDate()).toString().padStart(2, '0'),
      yy = this.getFullYear(),
      hh = this.getHours().toString().padStart(2, '0'),
      min = this.getMinutes().toString().padStart(2, '0'),
      ss = this.getSeconds().toString().padStart(2, '0'),
      dateSplitter = '-',
      splitter = ' ',
      timeSplitter = ':';

    return [[yy, mm, dd].join(dateSplitter), [hh, min, ss].join(timeSplitter)].join(splitter)
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.toISODateString = function (s = '-') {
    const mm = (this.getMonth() + 1).toString().padStart(2, '0');
    const dd = (this.getDate()).toString().padStart(2, '0');
    const yy = this.getFullYear();
    return [yy, mm, dd].join(s);
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.lastDayOfMonth = function () {
    const mm = this.getMonth() + 1;
    const yy = this.getFullYear();

    return new Date(yy, mm, 0).getDate();
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.addMonths = function (m: number) {
    const nmz = this.getMonth() + m;
    return new Date(this.setMonth(nmz));
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.toMMMDDYYYY = function (delimiter = '-') {
    const month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = this.getDate();
    const month_index = this.getMonth();
    const year = this.getFullYear();
    return [month_names[month_index], day, year].join(delimiter);
  }

  // YYYY-MM-DD
// eslint-disable-next-line no-extend-native
  Date.prototype.toYYYYMMDD = function (delimiter = '-') {
    const day = this.getDate().toString().padStart(2, '0');
    const mm = (this.getMonth() + 1).toString().padStart(2, '0');
    const year = this.getFullYear();
    return [year, mm, day].join(delimiter);
  };

  // MMDDYY
  // eslint-disable-next-line no-extend-native
  Date.prototype.toMMDDYY = function (delimiter = '') {
    const day = (this.getDate()).toString().padStart(2, '0');
    const mm = (this.getMonth() + 1).toString().padStart(2, '0');
    const year = (this.getFullYear()).toString().substring(-2);
    return [mm, day, year].join(delimiter);
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.addDays = function (d = 7) {
    const curDate = new Date(new Date(this).toLocaleString('en-US', {timeZone: 'UTC'}));
    return new Date(curDate.setDate(curDate.getDate() + d));
  };
})();
