import React from 'react';
import {Grid} from '../../components/Inputs';
import {PESCD as PMEscalationDetails} from '../../components/Escalations';
import { initFalsy } from '../../constants';

const EscalationDetail = () => {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <PMEscalationDetails isOPS={!initFalsy} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default EscalationDetail;
