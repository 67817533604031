import React from 'react';
import {connect} from 'react-redux';
import {Button, Card, Form, FormField, FormFieldType, FormWrap, Grid, Image, Segment} from '../../components/Inputs';
import {Loader} from '../../components';
import {
  Account,
  ButtonProps,
  FieldProps,
  FormFieldProps,
  FormikValues,
  FormProps,
  Language,
  StoreT,
  T,
  User
} from '../../model';
import {editAccountForm, initFalsy, ROLES} from '../../constants';
import {useActions} from '../../redux/actions';
import * as languagesActions from '../../redux/actions/languages';
import * as userAccActions from '../../redux/actions/users';
import dummyImg from '../../styles/images/dummyImg.png';
import {vo} from '../../utils/objectUtils';
import {validate} from '../../utils/validation';


interface ProfileEditFormProps extends FormProps {
  languageOptions: Array<Language>;
  userInfo: Partial<Account>;
  userAction: T;
}

interface MyProfileProps {
  auth: User;
  languageOptions: Array<Language>;
  userInfo: Partial<Account>;
}

const ProfileEditForm = (props: ProfileEditFormProps) => {
  const truthy = !initFalsy;
  const {handleSubmit, initialValues: {role}, isSubmitting, languageOptions, values}: ProfileEditFormProps = props;
  const selProps = {
    clearable: !truthy,
    disabled: truthy,
    label: 'Languages',
    multiple: truthy,
    name: 'langIds',
    options: languageOptions.map(({id, name}) => ({text: name, value: id})),
    placeholder: 'Select Languages',
    type: FormFieldType.Select
  };
  const saveButtonProps: ButtonProps = {
    className: 'ml15 invisible',
    content: 'Update',
    loading: isSubmitting,
    primary: truthy,
    type: 'submit'
  };
  const editFormJSON: FormFieldProps[] = editAccountForm.map((field: FieldProps) => {
    const readOnly = {disabled: truthy, readOnly: truthy};
    return {...field, type: FormFieldType.Text, value: values[field.name], ...readOnly};
  });

  return (
    <Grid>
      <Grid.Row className="headerTop">
        <Grid.Column width={16}>
          <h1 className="mainTitle">My Profile</h1>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Card>
            <Card.Content>
              <Form onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                      <h3 className="w100 subHeaderInner">
                        <span>Personal Information</span>
                      </h3>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row stretched verticalAlign="top">
                    <Grid.Column mobile={16} tablet={6} computer={3} textAlign="center">
                      <Segment className="segmentStyle bgLightYellow">
                        <Image circular size="medium" src={dummyImg} />
                        <div className="clearFix" />
                        <Button className="iconBtn invisible mt10" basic icon="edit" type="button" />
                        <Button className="iconBtn invisible ml10 mt10" basic icon="trash alternate" type="button" />
                      </Segment>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={10} computer={13}>
                      <Grid className="customGrid" columns={3}>
                        <Grid.Row>
                          {
                            editFormJSON.map((field, key: number) => (
                              <Grid.Column key={key}>
                                <FormField {...field} />
                              </Grid.Column>
                            ))
                          }
                          {
                            (role === ROLES.Surveyor || role === ROLES['CIMR Surveyor'] || role === ROLES["Keller Surveyor"]) &&
                            <Grid.Column>
                              <FormField {...selProps} />
                            </Grid.Column>
                          }
                          <Grid.Column width={16} textAlign="right">
                            <Button {...saveButtonProps} />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const MyProfile = ({auth, languageOptions, userInfo}: MyProfileProps) => {
  const [loading, setLoading] = React.useState(initFalsy);
  const userAction = useActions(userAccActions);
  const langAction = useActions(languagesActions);
  const {user: {role, id}} = auth;
  const {firstName = '', lastName = '', email = '', phoneNumber = '', vonageExt = '', languages = []} = userInfo;
  const requiredFields = ['firstName', 'lastName', 'email'];
  const initLangIds = languageOptions
    .filter(a => languages.map((a: string) => a.toLowerCase()).includes(a.name.toLowerCase()))
    .map(a => a.id);
  const initValues = {firstName, lastName, email, phoneNumber, role, vonageExt, langIds: initLangIds};
  const profileFormConfig = {
    children: ProfileEditForm,
    displayName: 'user-profile-form',
    initialValues: initValues,
    languageOptions,
    onSubmit: async (values: FormikValues, {resetForm}: FormikValues) => {
      try {
        setLoading(!initFalsy);
        // do update
        const payload: Partial<Account> = {...values, id};
        await userAction.updateAccount(payload);
        setLoading(initFalsy);
      } catch (err) {
        resetForm();
      }
    },
    validate: (values: FormikValues) => validate(requiredFields, values),
    userInfo,
    userAction
  };
  const fetchProfile = async () => {
    setLoading(!initFalsy);

    if (role === ROLES.Surveyor || role === ROLES['CIMR Surveyor'] || role === ROLES["Keller Surveyor"]) {
      await langAction.fetchLanguages(undefined, undefined, undefined, undefined, initFalsy);
    }
    await userAction.pullUserInfo(id);

    setLoading(initFalsy);
  };
  const firstLoad = () => {
    editAccountForm.map((field: FieldProps) => ({...field, value: null}));
    fetchProfile().then();

    return () => {
      userAction.flushUserInfo()
      langAction.flushLanguages()
    };
  };

  React.useEffect(firstLoad, []);
  return (
    <>
      {
        loading &&
        <Loader />
      }
      {
        vo(userInfo) &&
        <FormWrap {...profileFormConfig} />
      }
    </>
  );
};

const mapStoreProps = (store: StoreT) => {
  return {
    auth: store.auth,
    languageOptions: store.languages,
    userInfo: store.userInfo
  };
}

export default connect(mapStoreProps)(MyProfile);
