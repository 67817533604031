import React from 'react';
import {CCP} from '../../components/CommentCleaner';
import {initFalsy} from '../../constants';


const CommentCleaning = () => {
  return (
    <CCP isManager={!initFalsy} />
  );
};

export default CommentCleaning;
