import React from 'react';
import {Card, ChkBox, Grid} from '../Inputs';
import {Questionnaire} from '../Questionnaire';
import {CallIcon} from './index';
import {ChkProps, T} from '../../model';
import {initFalsy} from '../../constants';


interface CSP {
  isLocked: boolean;
  locking: boolean;
  onStartCall: () => Promise<void>;
  questionnaireProps: T;
  takeABreakChkProps: ChkProps;
}

const CallSurvey: React.FC<CSP> = (props) => {
  const iTruthy = !initFalsy;
  const cardHeader = (
    <Card.Header className="no-print">
      <h2 className="subTitle">{props.questionnaireProps.visitorName}</h2>
    </Card.Header>
  );
  const takeABreakChk = (
    <Grid.Row>
      <Grid.Column width={16}>
        <ChkBox {...props.takeABreakChkProps} />
      </Grid.Column>
    </Grid.Row>
  );

  return (
    <>
      {
        props.isLocked &&
        <Card fluid={iTruthy} className="mb20">
          {
            cardHeader
          }
          <Card.Content>
            <Grid>
              <CallIcon loading={props.locking} onClick={props.onStartCall} />

              {
                takeABreakChk
              }
            </Grid>
          </Card.Content>
        </Card>
      }

      {
        !props.isLocked &&
        <Grid>
          <Questionnaire {...props.questionnaireProps} />
        </Grid>
      }
    </>
  );
}

export default CallSurvey;
