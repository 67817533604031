import React from 'react';
import {useSelector} from 'react-redux';
import {Loader} from '../../components';
import {Card, Grid, GridView, Icon, Paging} from '../../components/Inputs';
import {DEFAULT_RECORDS_PER_PAGE, initFalsy} from '../../constants';
import {ST, StoreT} from '../../model';
import {useActions} from '../../redux/actions';
import * as userActions from '../../redux/actions/users';
import {va} from '../../utils/arrayUtils';
import {getSkipLimit,} from '../../utils/common';

interface EUProps {
}

interface PMProps {
  password: string;
}

type FU = (skip?: number, lim?: number, sortOn?: (string | undefined), sortBy?: ST) => Promise<void>;

const PasswordMask = ({password}: PMProps) => {
  const [show, setShow] = React.useState(initFalsy);
  const maskPwd = (pwd: string) => {
    const minChars = 3;
    return `${pwd.length > minChars ? pwd.substring(0, minChars) : ''}***`;
  };
  return (
    <>
      {
        !show &&
        maskPwd(password)
      }

      {
        show && password
      }

      <Icon className="hand-pointer" name={show ? 'eye slash' : 'eye'} onClick={() => setShow(!show)} />
    </>
  );
};

const ExternalUser: React.FC<EUProps> = () => {
  const initPageNo = 1;
  const [loading, setLoading] = React.useState(initFalsy);
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const userAct = useActions(userActions);
  const extUserInfo = useSelector((state: StoreT) => state.userExtInfo) || [];
  const extUserHeaders = ['Attempted Email', 'Attempted Password', 'Ip Address'];
  const extUserWidth = ['w33', 'w33', 'w33'];
  const extUserList = va(extUserInfo.rows) ? extUserInfo.rows.map(({email, apwd, ipAddress}) => ({
    email,
    aPwd: (
      <PasswordMask password={apwd} />
    ),
    ipAddress
  })) : [];
  const navFux = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    fetchExu(skip, gridSize).then();
  };
  const fetchExu: FU = async (skip = 0, limit = DEFAULT_RECORDS_PER_PAGE, orderBy = 'createdAt', orderDir = 'DESC') => {
    setLoading(!initFalsy);
    await userAct.fetchExtUsers(skip, limit, orderBy, orderDir);
    setLoading(initFalsy);
  };
  const fetchExtUser = () => {
    fetchExu().then();

    return () => userAct.flushExtUser();
  };

  React.useEffect(fetchExtUser, []);

  return (
    <>
      {
        loading &&
        <Loader />
      }

      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">External User</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Card fluid>
              <Card.Content>
                <GridView headers={extUserHeaders} list={extUserList} widthCss={extUserWidth} />

                <Paging
                  totalRecords={extUserInfo.count}
                  navigate={navFux}
                  activePage={activePage}
                  pageSize={limit}
                  setPageSize={setPageSize}
                  setPageChange={setPageChange}
                />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default ExternalUser;
