import React from 'react';
import {useSelector} from 'react-redux';
import {Button, Card, Form, FormField, FormFieldType, Grid, TextBox} from '../../Inputs';
import {ButtonProps, FieldProps, FormProps, StoreT} from '../../../model';
import {initFalsy, ROLES, viewAccountForm} from '../../../constants';


type ExtraProps = {
  doEdit?: Function;
  id: number;
  isModel?: boolean;
  onCancel: Function;
}

interface VFProps extends FormProps {
  extraProps: ExtraProps;
}

const ViewForm = (props: VFProps) => {
  const languageOptions = useSelector((state: StoreT) => state.languages);
  const {extraProps, handleChange, handleSubmit, initialValues, isSubmitting, values} = props;
  const {doEdit, id, isModel, onCancel} = extraProps;
  const deleteButton: ButtonProps = {
    content: 'Deactivate',
    color: 'red',
    className: 'ml10 mb15',
    primary: initFalsy,
    loading: isSubmitting,
    type: 'submit'
  };
  const cancelButton: ButtonProps = {
    content: 'Cancel',
    onClick: onCancel,
    secondary: !initFalsy,
    className: 'mb15'
  };
  const viewFormJSON = viewAccountForm.map((field: FieldProps) => {
    return (
      field.name ?
        Object.assign(field, {
          disabled: !initFalsy,
          onChange: handleChange,
          value: values[field.name]
        }) :
        Object.assign(field, {
          disabled: isSubmitting
        })
    )
  });
  const selProps = {
    disabled: !initFalsy,
    label: 'Languages',
    multiple: !initFalsy,
    name: 'langIds',
    options: languageOptions.map(({id, name}) => ({text: name, value: id})),
    placeholder: 'Select Languages'
  };
  const viewFormButtons: ButtonProps[] = [];
  viewFormButtons.push(cancelButton);
  !!doEdit && viewFormButtons.push(deleteButton);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={isModel ? 16 : 10}>
          <Card fluid={!initFalsy}>
            <Card.Header>
              <h2 className="subTitle">{isModel ? 'Project Manager Details' : 'View Account Details'}</h2>
              {
                !!doEdit &&
                <Button icon="edit" className="asAnchor float-right" onClick={() => doEdit(id)} content="Edit" />
              }
            </Card.Header>
            <Card.Content className="pb0">
              <Form onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Row>
                    {
                      viewFormJSON.map((field: FieldProps, key: number) => (
                        <Grid.Column mobile={16} tablet={16} computer={8} key={key}>
                          {
                            <TextBox {...field} />
                          }
                        </Grid.Column>
                      ))
                    }

                    {
                      (initialValues.role === ROLES.Surveyor || initialValues.role === ROLES['CIMR Surveyor'] || initialValues.role === ROLES['Keller Surveyor']) &&
                      <Grid.Column mobile={16} tablet={16} computer={8}>
                        <FormField type={FormFieldType.Select} {...selProps} />
                      </Grid.Column>
                    }
                    <Grid.Column width={16} textAlign="right">
                      {
                        viewFormButtons.map((buttProps: ButtonProps, key: number) => (
                          <Button key={key} {...buttProps} />
                        ))
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ViewForm;
