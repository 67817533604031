import React, {SyntheticEvent} from 'react';
import {Button, Grid, List, Search, Tab, TabProps} from '../Inputs';
import {QuestionsList, ResponseList} from './index';
import {Draggable} from '../DnD';
import {initFalsy} from '../../constants';
import {Answer, BN, Question, SearchProps} from '../../model';
import {useActions} from '../../redux/actions';
import * as questActions from '../../redux/actions/questions';
import * as ansActions from '../../redux/actions/answers';
import {sleep} from '../../utils/objectUtils';


type QAListProps = {
  onAddQ?: () => void;
  onAddR?: () => void;
  onDel?: (id: BN, isQ: boolean) => Promise<void>;
  onEditQ?: Function;
  onEditR?: Function;
  onTabChange: (index: number) => void;
  searchValue: string;
  setSearchValue: Function;
  tabIndex: number;
  isQsBuilder?: boolean
};

type DraggableQAItem = {
  qa: Question | Answer;
  onEdit?: Function;
  onlyList: boolean;
  type: string;
};

const QAList = (props: QAListProps) => {
  const
    {isQsBuilder, onAddQ, onAddR, onDel, onEditQ, onEditR, onTabChange, searchValue, setSearchValue, tabIndex} = props,
    [isLoadingQA, setLoadingQA] = React.useState(initFalsy),
    searchProps = {
      className: 'mb15 w100',
      fluid: !initFalsy,
      loading: isLoadingQA,
      minCharacters: 2,
      placeholder: 'Search...',
      showNoResults: initFalsy,
      value: searchValue,
    },
    que = useActions(questActions),
    ans = useActions(ansActions),
    panes = [
      {
        menuItem: 'Questions',
        render: () => (
          <Tab.Pane className="px0 pb0" loading={isLoadingQA}>
            <QuestionsList onAddQ={onAddQ} onDel={onDel} onEditQ={onEditQ} />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Response',
        render: () => (
          <Tab.Pane className="px0 pb0" loading={isLoadingQA}>
            <ResponseList onAddR={onAddR} onDel={onDel} onEditR={onEditR} />
          </Tab.Pane>
        )
      }
    ],
    fetchQs = async (searchVal?: string) => {
      setLoadingQA(!initFalsy);
      await que.fetchQuestions(searchVal, isQsBuilder);
      await ans.fetchAnswers(searchVal, isQsBuilder);
      setLoadingQA(initFalsy);
    },
    clearSearchValue = () => {
      setSearchValue('');
      return fetchQs();
    },
    onChange = (_e: React.MouseEvent<HTMLDivElement>, {activeIndex}: TabProps) => {
      onTabChange(activeIndex as number);
      return clearSearchValue()
    },
    onSearchChange = async (_e: SyntheticEvent, data: SearchProps) => {
      setSearchValue(data.value as string);
      if (searchValue && searchValue.length <= 1) {
        return sleep(0);
      }
      // await fetchQs(data.value);
    },
    firstCall = () => {
      const search = (searchValue && searchValue.length >= 1) ? searchValue : undefined;
      fetchQs(search).then();

      return () => {
        que.flushQuestions();
        ans.flushAnswers();
      };
    };

  React.useLayoutEffect(firstCall, [searchValue]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={14}>
          <Search {...searchProps} onSearchChange={onSearchChange} />
        </Grid.Column>
        <Grid.Column width={2}>
          <Button icon='close' className="btnSquare" primary onClick={() => clearSearchValue()} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Tab className="tabStyle" panes={panes} activeIndex={tabIndex} onTabChange={onChange} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export const DraggableListItem = ({qa, onEdit, onlyList, type}: DraggableQAItem) => {
  const
    titleContent = (
      <>
        <List.Content className="hand-pointer" onClick={() => onEdit ? onEdit(qa) : {}} content={qa.title} />
      </>
    ),
    dragProps = {children: titleContent, item: qa, type};

  return (
    onlyList ? (<Draggable {...dragProps} />) : (titleContent)
  );
}

export default QAList;
