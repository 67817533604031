import React from 'react';
import {Card, Form, Grid, TextArea, TextAreaProps} from '../Inputs';
import {initFalsy} from '../../constants';


interface CSurPosNegProps {
  header?: string;
  items: TAProps[];
  negativeComment?: string;
  positiveComment?: string;
}

interface TAProps extends TextAreaProps {
  disabled?: boolean;
  label?: string;
  value: string;
}

const TA = ({disabled, label, value, ...custom}: TAProps) => {
  const textAreaProps = {className: 'textarea w100', disabled, rows: 5, spellCheck: !initFalsy, value}
  return (
    <Form.Field className="ui form">
      <label>{label}</label>
      <TextArea {...textAreaProps} {...custom} />
    </Form.Field>
  )
}

const CallSurPosNeg: React.FC<CSurPosNegProps> = ({header, items}) => {
  const iTruthy = !initFalsy;
  return (
    <Card fluid={iTruthy}>
      {
        header &&
        <Card.Header>
          <h2 className="subTitle">{header}</h2>
        </Card.Header>
      }
      <Card.Content>
        <Grid>
          {
            items.map((fProp, idx) => (
              <Grid.Row key={idx}>
                <Grid.Column computer={16} tablet={16} mobile={16}>
                  <TA {...fProp} />
                </Grid.Column>
              </Grid.Row>
            ))
          }
        </Grid>
      </Card.Content>
    </Card>
  );
}

export default CallSurPosNeg;
