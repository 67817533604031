import React from 'react';
import {QuestionnairesList} from '../../components/Questionnaire';


const PMQuestionnaireList = () => {
  return (
    <>
      <QuestionnairesList />
    </>
  );
};


export default PMQuestionnaireList;
