import React from 'react';
import {line} from 'd3-shape';
import {Layer, ResponsiveBar} from '@nivo/bar';
import {BoxLegendSvg, LegendProps} from '@nivo/legends';
import {va} from '../../utils/arrayUtils';
import {BAR_PRIMARY_COLOR, initFalsy, LINE_COLOR} from '../../constants';
import {T} from '../../model';
import {pez} from '../../utils/stringUtils';

type LG = Array<{ dataFrom: 'indexes' | 'keys' } & LegendProps>;

interface ChartMainProps extends BarChartProps {
  availableColors: string[];
  axisBottomLabel: string;
  axisLeftLabel: string;
  height: string;
  legendLabels: string[];
  lineValueLabel?: string;
}

interface BarChartProps {
  data: T[];
  includeLine?: boolean;
  indexBy: string;
  keys: string[];
  layers?: Layer[];
}

interface BarLegendProps {
  height: number;
  legends: LegendProps[];
  noResultMsg?: string;
  width: number;
}

interface LayerProps {
  bars: T[];
  data: T[];
  keys: string[];
  xScale: Function;
  yScale: Function;
}

const BarLegend = ({height, legends, width}: BarLegendProps) => {
  const legendProps = {containerHeight: height, containerWidth: width};
  return legends.map((legend, key) => (
    <BoxLegendSvg {...legendProps} {...legend} key={key} />
  ))
};

const LineLayer = ({bars, keys, yScale}: LayerProps) => {
  const _bars = bars.filter(i => i.data.id === keys[0]);
  let lg;
  if (va(_bars) && _bars.length > 1) {
    lg = line()
      .x((bar: T) => bar.x + bar.width / 2)
      .y((bar: T) => yScale(bar?.data?.data?.lineValue));
    lg = lg(_bars) as string;
  }
  return (
    <>
      <path strokeWidth="2" d={lg} fill="none" stroke={LINE_COLOR} />
      {
        _bars.map(bar => {
          const circleProps: React.SVGProps<SVGCircleElement> = {
            cx: bar.x + bar.width / 2,
            cy: yScale(bar.data.data.lineValue),
            fill: 'white',
            key: bar?.key,
            r: 4,
            stroke: LINE_COLOR,
            style: {pointerEvents: 'none'}
          };

          return (
            <circle {...circleProps} />
          )
        })
      }
    </>
  );
};

const BarChart = (barProps: BarChartProps) => {
  return (
    <ResponsiveBar {...barProps} />
  )
};

const Chart: React.FC<ChartMainProps> = (props: ChartMainProps) => {
  const {
    availableColors,
    axisBottomLabel,
    axisLeftLabel,
    height,
    includeLine,
    keys,
    legendLabels,
    lineValueLabel,
    ...otherProps
  } = props;

  const hasMultipleBars = va(keys) && keys.length > 1;
  const legendColors: T = {};
  legendLabels.forEach((label, id) => {
    legendColors[label] = availableColors[id];
  });
  const keysColors: T = {};
  keys.forEach((key, id) => {
    keysColors[key] = availableColors[id];
  });

  const legend: T = {
    anchor: 'top',
    dataFrom: 'keys',
    data: legendLabels.map((label, id) => ({color: legendColors[label], id, label})),
    direction: 'row',
    itemDirection: 'left-to-right',
    itemHeight: 10,
    itemsSpacing: includeLine ? 100 : 50,
    itemWidth: 250,
    justify: initFalsy,
    symbolSize: 30,
    translateX: 0,
    translateY: -40
  }
  const layers = (
    includeLine ?
      ['grid', 'axes', 'bars', LineLayer, 'markers', BarLegend] :
      ['grid', 'axes', 'bars', 'markers', BarLegend]
  ) as Layer[];
  const barProps: T = {
    ...otherProps,
    axisBottom: {legend: axisBottomLabel, legendPosition: 'middle', legendOffset: 50},
    axisLeft: {legend: axisLeftLabel, legendPosition: 'middle', legendOffset: -75},
    borderRadius: 1,
    colors: hasMultipleBars ? (a: T) => (keysColors[a.id] || BAR_PRIMARY_COLOR) : BAR_PRIMARY_COLOR,
    keys,
    label: (d: T) => pez(d.data[`${d.id}Value`], pez(d.data.barValue)),
    labelSkipHeight: 16,
    labelSkipWidth: 16,
    labelTextColor: 'black',
    layers,
    legends: [legend] as LG,
    margin: {top: 50, right: 25, bottom: 60, left: 100},
    padding: 0.75,
    tooltip: (input: T) => {
      return (
        <>
          {
            includeLine &&
            <>
              {`${lineValueLabel}: `}<strong>{input.data.lineValue}</strong> <br />
            </>
          }

          {
            keys.map((k, i) => {
              return (
                <React.Fragment key={i}>
                  {input.data[`${k}Label`]}:&nbsp;
                  <strong>{input.data[`${k}Value`]}</strong>
                  <br />
                </React.Fragment>
              )
            })
          }
        </>
      )
    },
    theme: {
      tooltip: {
        basic: {whiteSpace: 'pre', display: 'flex', alignItems: 'center'},
        container: {
          background: '#EBEFF1',
          borderRadius: '2px',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
          position: 'fixed'
        }
      },
    }
  };

  return (
    <div style={{height}}>
      <BarChart {...barProps} />
    </div>
  );
};


export default Chart;
