import {ActionType, ApiEngine, CCStatusUpd, CleanResponse, Dispatcher, SaveCleaned, T} from '../../model';
import {setAlert, setSuccess} from './alert';
import {flushProjects} from './projects';
import {CCStatus, PLSort, SortDir} from '../../constants';
import {vo} from '../../utils/objectUtils';
import {byKey, va} from '../../utils/arrayUtils';

const apiPath = 'comments';

type FetchComments = (managerId: number, projectId: number, quesId: number, useLambda: boolean) => (dispatch: Function, _store: Function, api: ApiEngine) => Promise<T>;
type FetchCommentProjects = (skip: number, limit: number, orderBy: string, orderDir: string, managerId: number, search: string) => (dispatch: Function, _store: Function, api: ApiEngine) => Promise<T>;
type SaveCC = (payload: SaveCleaned) => (dispatch: Function, _store: Function, api: ApiEngine) => Promise<T>;
type UpdCCStatus = (markCleaned?: number) => (dispatch: Function, _store: Function, api: ApiEngine) => Promise<T>;


/**
 * Flush Comments
 */
export const flushComments: () => Dispatcher = () => async (dispatch) => {
  return dispatch({type: ActionType.COMMENTS_FLUSH, payload: {}});
};

/**
 * Flush Comments Projects
 */
export const flushCommentProjects: () => Dispatcher = () => async (dispatch) => {
  return dispatch(flushProjects());
};

/**
 * Fetch Comments
 * @param managerId
 * @param projectId
 * @param quesId
 * @param useLambda
 * @returns
 */
export const fetchComment: FetchComments = (managerId, projectId, quesId, useLambda) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const query = {managerId, projectId, quesId, useLambda};
    const {data}: CleanResponse = await api.get(apiPath, null, query);
    dispatch({type: ActionType.COMMENTS_FETCH, payload: data});
    return data;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Projects for PM comment cleaner with CC progress data
 * @param skip
 * @param limit
 * @param orderBy
 * @param orderDir
 * @param managerId
 * @param search
 */
export const fetchCommentProjects: FetchCommentProjects = (skip, limit, orderBy, orderDir, managerId, search) => async (dispatch, _store, api) => {
  try {
    const apiPathCCProjects = `${apiPath}/projects`;
    const _isSplOrderCase = orderBy === PLSort.OldestUncleanedSurveyedOn
    const query = {limit, managerId, orderBy, orderDir, search, skip};

    const {data}: CleanResponse = !_isSplOrderCase ? await api.get(apiPathCCProjects, null, query) : {data: _store().projects};

    if (_isSplOrderCase) {
      const isAsc = (orderDir === SortDir.ASC);
      va(data.rows) && data.rows.sort(byKey(orderBy, isAsc));
    }

    return dispatch({type: ActionType.PULL_PROJECTS, payload: data});
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Save Cleaned Comments
 * @param payload
 */
export const saveCleanedComment: SaveCC = (payload) => async (dispatch, _store, api) => {
  try {
    const res: CleanResponse = await api.post(apiPath, payload);
    dispatch({type: ActionType.COMMENTS_CLEAN, payload: {}});
    dispatch(setSuccess(res.message));
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Set status (defaults to unclean)
 * @param markCleaned
 */
export const unCleanComment: UpdCCStatus = (markCleaned: number = CCStatus.NotCleaned) => async (dispatch, store, api) => {
  try {
    const {comments} = store();
    if (!vo(comments)) {
      return;
    }
    const {id, projectId} = comments;
    const payload: CCStatusUpd = {id, markCleaned, projectId};
    await api.put(apiPath, payload);
    dispatch({type: ActionType.COMMENTS_UNCLEAN, payload: {}});
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
}
