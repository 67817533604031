import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router';
import {useActions} from '../../redux/actions';
import {Loader} from '../../components';
import {ButtonProps, SelectValue, StoreT, VisitInfo} from '../../model';
import {Button, Card, ChkBox, Grid} from '../../components/Inputs';
import * as projectActions from '../../redux/actions/projects';
import {va} from '../../utils/arrayUtils';
import {initFalsy} from '../../constants';
import {Path} from '../../routes';


const AdditionalInfo: React.FC = () => {
  const hist = useHistory();
  const {id} = useParams();
  const pAction = useActions(projectActions);
  const visitInfos: VisitInfo[] = useSelector((state: StoreT) => state.visitInfos) || [];
  const [loading, isLoading] = React.useState(initFalsy);
  const [fields, setFields]: [VisitInfo[], Function] = React.useState(visitInfos);
  const onUpdate = async () => {
    isLoading(!initFalsy);
    const field = va(fields) ? fields.filter((i: any) => i.show === !initFalsy) : [];
    await pAction.updateVisitInfo({fields: field, projectId: id});
    isLoading(initFalsy);
  };
  const goBackButtonProps: ButtonProps = {
    circular: !initFalsy,
    className: 'float-right',
    icon: 'undo',
    onClick: () => hist.push(Path.Projects),
    primary: !initFalsy
  };
  const savButtProps: ButtonProps = {
    className: 'mt15',
    content: 'Update',
    onClick: onUpdate,
    primary: !initFalsy,
    type: 'button'
  };
  const frEffect = () => {
    isLoading(!initFalsy);
    const isValidProject = !initFalsy;  
    pAction.getVisitInfo(id, isValidProject)
      .then((vi: VisitInfo[]) => {
        setFields(vi);
        isLoading(initFalsy);
      });

    return () => pAction.flushVisitInfo();
  };

  React.useEffect(frEffect, []);

  return (
    <>
      <Grid>
        {
          loading &&
          <Loader />
        }

        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">Project Roster Additional Info</h1>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column computer={8} tablet={12} mobile={16}>
            <Card className="mb20">
              <Card.Content>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <h3 className="w100 subHeaderInner">
                        <span>Selectable Call details</span>
                        <Button {...goBackButtonProps} />
                      </h3>
                    </Grid.Column>
                  </Grid.Row>
                  {
                    !isLoading && !va(fields) &&
                    <p>No records found.</p>
                  }

                  {
                    va(fields) &&
                    <>
                      <Grid.Row>
                        {
                          fields.map(({field, show}, i) => {
                            const name = `field-${i}`;
                            const onChange = (value: SelectValue) => {
                              const index = fields.findIndex((vi: VisitInfo) => vi.field === value);
                              fields[index].show = !fields[index].show;
                              setFields(Object.assign([], fields));
                            };
                            const chkProps = {checked: show, label: field, name, onChange: onChange, value: field};
                            return (
                              <Grid.Column computer={4} key={i} mobile={16} tablet={8}>
                                <ChkBox {...chkProps} />
                              </Grid.Column>
                            );
                          })
                        }
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column>
                          <Button {...savButtProps} />
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  }
                </Grid>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default AdditionalInfo;
