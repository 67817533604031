import React from 'react';
import {Button, GenModelProps, Model} from '../Inputs';
import {useAutoLogout} from './index';
import {useActions} from '../../redux/actions';
import * as authActions from '../../redux/actions/auth';
import {CONFIRM_MESSAGES, initFalsy} from '../../constants';
import {ButtonProps} from '../../model';
import {secondsToTime} from '../../utils/dateUtils';


interface AutoLogoutProps {
  keepVisible?: boolean;
  shwAlertIn: number;
  waitTime: number;
}

const AutoLogout = ({keepVisible, shwAlertIn, waitTime}: AutoLogoutProps) => {
  const _sessionTimeEnd = 0;
  const [isPopupOpen, setPopupOpen] = React.useState(initFalsy);
  const _logoutTimer = useAutoLogout(waitTime, isPopupOpen);
  const {LogOut, Load} = useActions(authActions);
  const actionProps: ButtonProps = {
    content: 'Yes, Please',
    primary: !initFalsy,
    onClick: () => {
      Load();
      setPopupOpen(initFalsy)
    },
    type: 'button'
  };
  const modelProps: GenModelProps = {
    actions: (<Button  {...actionProps} />),
    content: `In ${_logoutTimer} seconds you will be automatically logged out.`,
    header: CONFIRM_MESSAGES.LOGOUT,
    initialize: isPopupOpen,
    onCancel: () => setPopupOpen(initFalsy),
    closeOnDimmerClick: initFalsy,
    closeOnEscape: initFalsy,
    size: 'small'
  };
  const firstRun = () => {
    if (_logoutTimer <= shwAlertIn) {
      setPopupOpen(!initFalsy);
    }
  };

  if (_logoutTimer <= _sessionTimeEnd) {
    LogOut();
  }

  React.useEffect(firstRun, [_logoutTimer, shwAlertIn]);

  return (
    <>
      {
        keepVisible &&
        <div className="float-right">
          <i className="far fa-clock mr10" title="Session Time" />
          {
            secondsToTime(_logoutTimer)
          }
        </div>
      }
      {
        isPopupOpen &&
        <Model {...modelProps} />
      }
    </>
  );
}

export default AutoLogout;
