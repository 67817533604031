import React from 'react';
import {Button, Card, Form, FormField, FormFieldType, Grid, SemanticWIDTHS} from '../Inputs';
import {ButtonProps, FormFieldProps, FormProps, MiniAccount, ProjectsList, SelectValue, } from '../../model';
import {enumAsAO, va} from '../../utils/arrayUtils';
import {joinAsFullName} from '../../utils/common';
import {Frequency, initFalsy} from '../../constants';


interface FiltersProps extends FormProps {
  isManager: boolean;
  projects: ProjectsList;
  surveyors: Array<MiniAccount>;
  fromDate: Date;
  defaultDate: Date,
  setStartDate: Function;
}

type cols = { computer: SemanticWIDTHS, tablet: SemanticWIDTHS }
type FFPs = (FormFieldProps & { cols: cols });

const Filters: React.FC<FiltersProps> = (props) => {
  const
    initDt = '',
    {handleSubmit, isManager, isSubmitting, projects, surveyors, resetForm, setFieldValue, fromDate, defaultDate, setStartDate} = props,
    [selProjectId, setProjectId] = React.useState(),
    [surveyorId, setSurveyorId] = React.useState(),
    [earningsFreq, setEarningsFreq] = React.useState(),
    onClear = (isStart: boolean) => {
      setStartDate(undefined)
      isStart ? setFieldValue('startDate', initDt) : setFieldValue('endDate', initDt);
    },
    proOpts = projects?.rows.map(p => ({'text': p.name, 'value': p.id})),
    surveyorOpts = va(surveyors) ? surveyors.map(s => ({
      'text': `${joinAsFullName(s?.firstName, s?.lastName)} ${s?.email}`,
      'value': s?.id
    })) : [],
    earningsByOpts = enumAsAO(Frequency, 'text', 'value'),
    filterFields: FFPs[] = [
      {
        cols: {computer: 4, tablet: 8},
        label: 'From',
        name: 'startDate',
        onClear: () => onClear(!initFalsy),
        type: FormFieldType.DatePicker,
        value: defaultDate,
        setValue: setStartDate
      },
      {
        cols: {computer: 4, tablet: 8},
        label: 'To',
        name: 'endDate',
        onClear: () => onClear(initFalsy),
        type: FormFieldType.DatePicker
      },
      {
        className: '',
        cols: {computer: 4, tablet: 8},
        defaultValue: selProjectId,
        fluid: initFalsy,
        label: 'Project',
        name: 'projectId',
        onChange: (value: SelectValue) => setProjectId(value),
        options: proOpts,
        placeholder: 'Select Project',
        type: FormFieldType.Select
      },
      {
        className: '',
        cols: {computer: 4, tablet: 8},
        defaultValue: earningsFreq,
        fluid: initFalsy,
        label: 'By',
        name: 'earningsFreq',
        onChange: (value: SelectValue) => setEarningsFreq(value),
        options: earningsByOpts,
        placeholder: 'Select',
        type: FormFieldType.Select
      },
      {
        className: isManager ? '' : 'invisible',
        cols: {computer: 4, tablet: 8},
        defaultValue: surveyorId,
        fluid: initFalsy,
        label: 'Surveyor',
        name: 'surveyorId',
        onChange: (value: SelectValue) => setSurveyorId(value),
        options: surveyorOpts,
        placeholder: 'Select Surveyor',
        type: FormFieldType.Select
      }
    ],
    onReset = () => {
      setStartDate(fromDate);
      resetForm();
    },
    buttons = [
      {content: 'Filter', disabled: isSubmitting, loading: isSubmitting, primary: !initFalsy},
      {content: 'Reset', className: 'ml10', onClick: () => onReset(), secondary: !initFalsy}
    ];

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Card>
          <Card.Content>
            <Form className="filters" onSubmit={handleSubmit}>
              <Grid>
                <Grid.Row>
                  {
                    filterFields.map(({cols, ...f}, k) => (
                      <Grid.Column key={k} mobile={16} {...cols}>
                        <FormField {...f} />
                      </Grid.Column>
                    ))
                  }
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    {
                      buttons.map((btProps: ButtonProps, idx) => (
                        <Button key={idx} {...btProps} />
                      ))
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Filters;
