import React from 'react';
import {Loader} from '../index';
import {initFalsy} from '../../constants';
import {T} from '../../model';

interface IProps {
  url: string;
  height?: number;
  width?: number;
}

export default function IframeGoogleDoc(props: IProps) {
  const
    minWaitInSec = 3,
    [iframeTimeoutId, setIframeTimeoutId] = React.useState(undefined),
    iframeRef: T = React.useRef(null),
    [loader, setLoader] = React.useState(initFalsy),
    iframeLoaded = () => {
      clearInterval(iframeTimeoutId);
      setLoader(initFalsy);
    },
    updateIframeSrc = async () => {
      if (iframeRef.current) {
        iframeRef.current.src = props.url;
      }
    },
    iFrameProps = {
      height: props.height || '800',
      onError: updateIframeSrc,
      onLoad: iframeLoaded,
      ref: iframeRef,
      src: props.url,
      width: props.width || '100%'
    },
    fse = () => {
      const time = 1000 * minWaitInSec;
      const intervalId = window.setInterval(updateIframeSrc, time);
      setIframeTimeoutId(intervalId as T);
      setLoader(!initFalsy);
    };

  React.useEffect(fse, []);

  return (
    <>
      {
        loader &&
        <Loader />
      }

      <iframe title="Document Viewer" {...iFrameProps} />
    </>
  );
};
