import React from 'react';
import {initFalsy} from '../../constants';
import {ProjectsDetails} from '../../components/Projects';


const ProjectDetail = () => {
  return (
    <ProjectsDetails isManager={initFalsy} />
  );
};


export default ProjectDetail;

