import * as React from 'react';
import {SyntheticEvent} from 'react';
import {BtnSwitch, Button, Grid, Search, Select} from '../../components/Inputs';
import {AccountsList, ViewEditAccount} from '../../components/Admin';
import {Loader} from '../../components';
import {useActions} from '../../redux/actions';
import * as userAccActions from '../../redux/actions/users';
import * as languagesActions from '../../redux/actions/languages';
import {enumAsArray} from '../../utils/arrayUtils';
import {isValidSearch, mapSelectOption} from '../../utils/common';
import {sleep} from '../../utils/objectUtils';
import {ButtonProps, SearchProps, SelectValue, T} from '../../model';
import {DEFAULT_RECORDS_PER_PAGE, Del, initFalsy, ROLES as UserRoles} from '../../constants';


type V = SelectValue
type ActionType = { id: number | undefined, action: string };


const AccountManagement: React.FC = () => {
  const
    initPageNo = 1,
    userRoles = enumAsArray(UserRoles, !initFalsy),
    userAction = useActions(userAccActions),
    langAction = useActions(languagesActions),
    [miniLoader, setLoader] = React.useState(initFalsy),
    [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE),
    [activePage, setPageChange] = React.useState(initPageNo),
    [state, setAction]: [ActionType, Function] = React.useState({
      id: undefined,
      action: ''
    }),
    [searchVal, onSearch]: [T, Function] = React.useState(null),
    [curRole, setRole]: [V, Function] = React.useState(userRoles[-1]),
    [status, setUserStatus]: [T, Function] = React.useState(null),
    searchProps = {
      loading: miniLoader,
      showNoResults: initFalsy,
      placeholder: 'Search...'
    },
    selectProps = {
      clearable: !initFalsy,
      name: 'userRole',
      options: mapSelectOption(userRoles.sort(), !initFalsy),
      placeholder: 'Filter By Type...'
    },
    accCsvExpo: ButtonProps = {content: 'Export', icon: 'file excel', primary: !initFalsy},
    onChangeType = (val: boolean) => status === val ? setUserStatus(null) : setUserStatus(val),
    reportButtProps: ButtonProps[] = [
      {active: status === !initFalsy, content: Del.AC, onClick: () => onChangeType(!initFalsy)},
      {as: 'div', className: 'or', key: 'or'},
      {active: status === initFalsy, content: Del.DE, onClick: () => onChangeType(initFalsy)}
    ],
    onSearchChange = async (_e: SyntheticEvent, data: SearchProps) => {
      if (isValidSearch(data.value as string, miniLoader)) {
        return sleep(0);
      }

      onSearch(data.value);
    },
    onAction = async (object: ActionType) => {
      const {id, action} = object;
      setLoader(!initFalsy);

      switch (action) {
        case 'view':
          await userAction.pullUserInfo(id);
          await langAction.fetchLanguages(undefined, undefined, undefined, undefined, initFalsy);
          break;
        default:
          Object.assign(object, {id: undefined, action: ''});
          break;
      }
      setAction(object);
      setLoader(initFalsy);
    },
    onClose = () => {
      const obj = Object.assign({}, {id: undefined, action: ''});
      setAction(obj);
    },
    exportAccountCsv = () => {
      setLoader(!initFalsy);
      const role = UserRoles[curRole];
      userAction.exportAccCSV(role, searchVal, status);
      setLoader(initFalsy);
    },
    accountsListProps = {
      onAction,
      activePage,
      limit,
      role: UserRoles[curRole],
      search: searchVal,
      setPageChange,
      setPageSize,
      status
    };

  return (
    <>
      {
        miniLoader &&
        <Loader />
      }
      {
        state.action !== 'view' &&
        <Grid>
          <Grid.Row className="headerTop">
            <Grid.Column width={16}>
              <h1 className="mainTitle">User Directory</h1>
              <Search {...searchProps} onSearchChange={onSearchChange} />
              <Select {...selectProps} onChange={(value: SelectValue) => setRole(value)} />
              <Button {...accCsvExpo} onClick={() => exportAccountCsv()} />
              <BtnSwitch buttonProps={reportButtProps} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }

      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            {
              state.id && state.action === 'view' &&
              <ViewEditAccount onCancel={onClose} {...state} />
            }
          </Grid.Column>

          <Grid.Column width={16}>
            {
              !state.id &&
              <AccountsList {...accountsListProps} />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default AccountManagement;
