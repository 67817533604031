import request from 'superagent';
import {IRequestParams, T} from './model';
import {SESSION_AUTH_KEY} from './constants';
import {get} from './utils/storage';
import {pez} from './utils/stringUtils';


const concatAuthHeader = (authToken: string): string => {
  return authToken ? 'Bearer ' + authToken : '';
};

export abstract class ApiService {
  protected async executeRequest<S>(params: IRequestParams) {
    let authorization: string = '';
    authorization = concatAuthHeader(pez(get(SESSION_AUTH_KEY) as string));

    return new Promise<S>((resolve, reject) => {
      let req: request.Request = request(params.method, params.url)
        .set('Content-Type', 'application/json');

      const queryParameters = params.queryParameters;
      if (queryParameters) {
        req.query(queryParameters);
      }

      if (authorization) {
        req.set('Authorization', authorization);
      }

      if (params.body) {
        req.send(params.body);
      }

      req.end((error: T, response: request.Response) => {
        if (error || !response.ok) {
          if (response && response.body) {
            reject(Object.assign({}, response.body, {code: Number(error.status)}));
            return;
          }
          reject(error);
        } else {
          const res = response.body || response.text;
          resolve(res);
        }
      });
    });
  }
}

export class Api extends ApiService {
  baseApiUrl: string;

  constructor(url: string) {
    super();
    this.baseApiUrl = url;
  }

  public async get(url: string, data: T, query: T) {
    const requestParams: IRequestParams = {
      method: 'GET',
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query
    };
    return this.executeRequest(requestParams);
  }

  public async post(url: string, data?: T, query?: T) {
    const requestParams: IRequestParams = {
      method: 'POST',
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query
    };
    return this.executeRequest(requestParams);
  }

  public async put(url: string, data: T, query: T) {
    const requestParams: IRequestParams = {
      method: 'PUT',
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query
    };
    return this.executeRequest(requestParams);
  }

  public async patch(url: string, data: T, query: T) {
    const requestParams: IRequestParams = {
      method: 'PATCH',
      url: `${this.baseApiUrl}/${url}`,
      body: data,
      queryParameters: query
    };
    return this.executeRequest(requestParams);
  }

  public async delete(url: string, data?: T) {
    const requestParams: IRequestParams = {
      method: 'DELETE',
      url: `${this.baseApiUrl}/${url}`,
      body: data
    };
    return this.executeRequest(requestParams);
  }
}
