import React, {SyntheticEvent} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {CST_TIMEZONE, DEFAULT_RECORDS_PER_PAGE, initFalsy, ROLES, SortDir} from '../../constants';
import {Loader} from '../index';
import {Grid, Icon, Search} from '../Inputs';
import {List} from '../Generic';
import {getSkipLimit, isValidSearch} from '../../utils/common';
import {IconProps, SearchProps, ST, StoreT} from '../../model';
import {Path} from '../../routes';
import {useActions} from '../../redux/actions';
import * as serviceRecoveryActions from '../../redux/actions/serviceRecoveries';
import {va} from '../../utils/arrayUtils';
import {usDateTime} from '../../utils/dateUtils';
import {sleep} from '../../utils/objectUtils';

interface Props {
  isManager?: boolean;
}

const ServiceRecoveries: React.FC<Props> = ({isManager}) => {
  const initPageNo = 1;
  const hist = useHistory();
  const {srProjects, user} = useSelector((state: StoreT) => ({
    user: state.auth.user,
    srProjects: state.srProjects
  }));
  const {rows, count} = srProjects || {};
  const initSort: { column: string; direction: ST } = {column: 'name', direction: 'ASC'};
  const {fetchSRProjects, flushSRProjects} = useActions(serviceRecoveryActions);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [loader, isLoading] = React.useState(initFalsy);
  const [sortVars, setSortVars] = React.useState(initSort);
  const [miniLoader, setLoader] = React.useState(initFalsy);
  const headers = ['Client', 'Project', 'Oldest Uncleaned', 'Open Client Report Exports'];
  const sortKeys = ['client.name', initSort.column];
  const widthCss = ['w30', 'w30', 'w40'];
  const skip = getSkipLimit(activePage, limit);
  const actionIconProp: IconProps = {
    className: 'hand-pointer',
    name: 'eye',
    title: 'Open Client Report Exports',
    type: 'pro'
  };
  const list = va(rows) ? rows.map((i) => {
    const {client, id, name, oldestUncleanedSurveyedOn} = i;
    return {
      clientName: client?.name,
      name,
      oldestUncleaned: oldestUncleanedSurveyedOn ? usDateTime(oldestUncleanedSurveyedOn, CST_TIMEZONE) : '-',
      action: (
        <>
          <Icon {...actionIconProp} onClick={() => onAction(id!)} />
        </>
      )
    };
  }) : [];
  const onAction = (id: number) => {
    const path = user.role === ROLES.Operations ? Path.OPServiceRecoveries : Path.PMServiceRecoveries;
    const pathWithProjectId = `${path}/${id}`;
    hist.push(pathWithProjectId);
  };
  const fetchRecoveries = async (skip: number, lim: number, sortOn: string, sortBy: string = SortDir.ASC, search?: string) => {
    isLoading(!initFalsy);
    const managerId = isManager ? user.id : undefined;
    await fetchSRProjects(skip, lim, sortOn, sortBy, managerId, search);
    isLoading(initFalsy);
  };
  const firstLoadEffect = () => {
    isLoading(!initFalsy);
    fetchRecoveries(skip, limit, sortVars.column, sortVars.direction)
      .then(() => isLoading(initFalsy));

    return () => flushSRProjects();
  };
  const navFux = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    fetchRecoveries(skip, gridSize, sortVars.column, sortVars.direction).then();
  };
  const onSort = (column: string, direction: ST) => {
    setSortVars({column, direction});
    fetchRecoveries(skip, limit, column, direction).then();
  };
  const onSearchChange = (_e: SyntheticEvent, data: SearchProps) => {
    if (isValidSearch(data.value as string, loader)) {
      return sleep(0);
    }

    isLoading(!initFalsy);
    setLoader(!initFalsy);
    fetchRecoveries(skip, limit, sortVars.column, sortVars.direction, data.value)
      .then(() => isLoading(initFalsy))
      .then(() => setLoader(initFalsy));
  };
  const searchProps = {
    loading: miniLoader,
    placeholder: 'Search Client & Projects...',
    showNoResults: initFalsy
  };
  const gridProps = {activeSortKey: sortVars.column, headers, list, onSort, sortKeys, widthCss};
  const pgProps = {activePage, navigate: navFux, pageSize: limit, setPageChange, setPageSize, totalRecords: count};

  React.useEffect(firstLoadEffect, []);

  return (
    <>
      {
        loader &&
        <Loader />
      }

      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">Client Data Exports</h1>
            <Search className="text-left" {...searchProps} onSearchChange={onSearchChange} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <List gridProps={gridProps} pgProps={pgProps} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default ServiceRecoveries;
