import React from 'react';
import {SRProjects} from '../../components/ServiceRecoveries';

const ServiceRecoveries = () => {
  return (
    <SRProjects />
  );
};

export default ServiceRecoveries;
