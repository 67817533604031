import React from 'react';
import {Earnings} from '../../components/Surveyors';
import {initFalsy} from '../../constants';

const SurveyorEarnings = () => {
  return (
    <>
      <Earnings isSurveyor={!initFalsy}/>
    </>
  );
};

export default SurveyorEarnings;
