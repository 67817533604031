import React from 'react';
import {Account, CallLog, ChkProps, CLUPayload, IconProps, onTAC, RC, T} from '../../model';
import {CallDetail, CallSurComments} from '../Generic';
import {Button, Confirm, Grid, Icon, Model} from '../Inputs';
import {Questionnaire} from '../Questionnaire';
import {
  CONFIRM_MESSAGES,
  initFalsy,
  Language,
  PatientType,
  RETURN_KEY_CODE,
  ROLES,
  RosterKeys,
  SurCallResponse
} from '../../constants';
import {enumAsAO, va} from '../../utils/arrayUtils';
import {vo} from '../../utils/objectUtils';
import {pez, plb} from '../../utils/stringUtils';
import {asPhone} from '../../utils/validation';


interface CLRProps {
  callInfo: CallLog;
  onCancel: () => void;
  onUpdate: (updPayload: CLUPayload) => Promise<void>;
  users: Account
}

type ParserFux = ({field, value}: { field: string; value: string; }) => { label: string; value: string; };

const CallLogResponse: React.FC<CLRProps> = (props) => {
  const strEmpty = '';
  const {callInfo, users} = props;
  const initPosCmt = vo(callInfo) ? plb(callInfo.positive) : strEmpty;
  const initNegCmt = vo(callInfo) ? plb(callInfo.negative) : strEmpty;
  const initDNC = vo(callInfo) ? callInfo.result === SurCallResponse['Do Not Call'] : initFalsy;
  const initPS: boolean = vo(callInfo) ? callInfo.isPartial : initFalsy;
  const initQAR: boolean = vo(callInfo) ? callInfo.isQARemoval : initFalsy;
  const initEsc = vo(callInfo && callInfo.escalation) ? callInfo.escalation.notes : strEmpty;
  const initLang = vo(callInfo) ? callInfo.surveyLanguageId || callInfo.languageId : Language.English;
  const [newResult, setNewResult] = React.useState(0);
  const [positive, setPosCmt] = React.useState(initPosCmt);
  const [selLanguage, setLanguage] = React.useState(initLang);
  const [negative, setNegCmt] = React.useState(initNegCmt);
  const [dnc, setDNC] = React.useState(initDNC);
  const [ps, setPartialSurvey] = React.useState(initPS);
  const [confirmClose, setConfirmClose] = React.useState(initFalsy);
  const [escalation, setEscalation] = React.useState(initFalsy);
  const [escalationComment, setEscalationCmt] = React.useState(initEsc);
  const [qar, setQARemoval] = React.useState(initQAR);
  const {
    age,
    callId,
    chpw,
    departmentName,
    dos,
    ethnicity,
    gender,
    guarantor,
    language,
    patientFullName,
    payerType,
    phoneCell,
    phoneHome,
    providerName,
    providerType,
    race,
    siteName,
    surveyLanguageId,
    typeId
  } = callInfo;
  const btnFields = enumAsAO(SurCallResponse);
  const onEscalationClose = () => {
    setEscalation(initFalsy);
  };
  const firstRun = () => {
    setNewResult(callInfo.result);
    setPosCmt(callInfo.positive);
    setNegCmt(callInfo.negative);

    return () => {
      props.onCancel();
    };
  };
  const onEscalation: onTAC = (_e, {value}) => setEscalationCmt(value as string);
  const saveCall = (newResult: number) => {
    setNewResult(newResult);
    const result = newResult === SurCallResponse['Agreed to Survey'] ? initFalsy : !initFalsy;
    setPartialSurvey(result);
  };
  const dataFields = [
    {label: RosterKeys.PatientName, value: patientFullName},
    {label: RosterKeys.CellPhone, value: asPhone(phoneCell)},
    {label: RosterKeys.HomePhone, value: asPhone(phoneHome)},
    {label: 'Date Of Service', value: new Date(dos).toUSDateString()},
    {label: RosterKeys.Age, value: age},
    {label: RosterKeys.Sex, value: gender},
    {label: RosterKeys.ProviderName, value: providerName},
    {label: RosterKeys.Center, value: siteName},
    {label: RosterKeys.Department, value: departmentName},
    {label: RosterKeys.GuarantorName, value: guarantor},
    {label: RosterKeys.Language, value: language?.name},
    {label: RosterKeys.CHPW, value: chpw},
    {label: RosterKeys.PatientType, value: PatientType[typeId]},
    {label: RosterKeys.ProviderType, value: providerType},
    {label: RosterKeys.PayerType, value: payerType},
    {label: RosterKeys.Ethnicity, value: ethnicity},
    {label: RosterKeys.Race, value: race}
  ];
  const setPRSurvey = () => {
    setPartialSurvey(!ps);
    if (callInfo && callInfo.isPartial) {
      setNewResult(SurCallResponse['Agreed to Survey']);
    }
  };
  const chkBoxes: ChkProps[] = [
    {
      checked: !!escalationComment,
      label: 'Escalate to your Project Manager',
      name: 'escalate',
      onChange: () => setEscalation(!escalation)
    },
    {checked: dnc, label: 'Add to Do Not Call List', name: 'dnc', onChange: () => setDNC(!dnc)},
    {
      checked: ps,
      disabled: (!initPS && newResult === SurCallResponse['Agreed to Survey']),
      label: 'Partial Survey',
      name: 'ps',
      onChange: () => setPRSurvey()
    },
    {checked: qar, label: 'QA Removal', name: 'qar', onChange: () => setQARemoval(!qar)}
  ];
  const callResponseProps = {
    buttonFields: btnFields,
    captureResponse: saveCall,
    initResult: newResult || callInfo.result
  };
  const captureResponse = async () => {
    const result = !!newResult ? newResult : callInfo.result;
    const surveyCompletedOn = (result === SurCallResponse['Agreed to Survey'] && !ps) ? new Date().toISOString() : null;
    const payload: CLUPayload = {
      callId,
      callLogId: callInfo.id as number,
      escalation: escalationComment,
      escalationId: callInfo?.escalation?.id,
      // isCountSurvey: ps,
      isPartial: ps,
      isQARemoval: qar,
      negative,
      positive,
      projectId: callInfo.projectId,
      result,
      surveyLanguageId: users.role === ROLES.Operations || users.role === ROLES['Project Manager'] ? selLanguage : surveyLanguageId,      // prevents a surveyor for that matter to update survey language once a survey is submitted in relation to the survey rate being different for other languages. And by not allowing to change language afterwards and get un-due advantage.
      surveyCompletedOn
    };

    await props.onUpdate(payload);
  };
  const fieldValueParser: ParserFux = ({field, value}) => ({label: field, value});
  const visitInfos = (va(callInfo?.call?.visit?.visitInfo) ? callInfo?.call?.visit?.visitInfo : []) as T[];
  const additionalFields = va(visitInfos) ? visitInfos.filter((cf) => cf.show) : [];
  const listItems = va(additionalFields) ?
    dataFields.concat(additionalFields.map(fieldValueParser)) :
    dataFields;
  const callDetailsProps = {
    listItems,
    listMainTitle: pez(callInfo.project?.name),
    listSubTitle: ('Call Log Details') as RC
  };
  const questionnaireProps = {
    // addLogicScroll: !initFalsy,
    agreedToSurvey: !initFalsy,
    callId: callInfo.id,
    callResponseProps,
    captureResponse,
    chkBoxes,
    languageId: callInfo.surveyLanguageId,
    pipeItems: dataFields.concat(visitInfos.map(fieldValueParser)),
    projectId: callInfo.projectId,
    ps,
    qar,
    setLanguage,
    surveyCallId: callInfo?.callId,
    surveyLangId: selLanguage,
    surveys: callInfo?.call?.survey,
  };
  const onPositiveCmt: onTAC = (_e, {value}) => setPosCmt(value as string);
  const onNegativeCmt: onTAC = (_e, {value}) => setNegCmt(value as string);
  const onKeyDown = (_e: T) => {
    if (_e.keyCode === RETURN_KEY_CODE) {
      _e.preventDefault();
    }
  };
  const callComments = [
    {label: 'Positive', placeholder: 'Positive ', value: positive, onChange: onPositiveCmt, onKeyDown},
    {label: 'Negative', placeholder: 'Negative', value: negative, onChange: onNegativeCmt, onKeyDown}
  ];
  const escComments = [
    {
      placeholder: 'Add Notes', value: escalationComment, onChange: onEscalation
    }
  ];
  const closeProps: IconProps = {
    className: 'close-modal float-right hand-pointer red',
    name: 'close',
    size: 'large',
    title: 'Cancel'
  };
  const onConfirm = () => {
    setConfirmClose(!initFalsy);
  };
  const escalationActions = (
    <>
      <Button content="Save and Return" onClick={onEscalationClose} primary={!initFalsy} />
      <Button content="Cancel" onClick={onEscalationClose} />
    </>
  );
  const escalationModelContent = (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <CallSurComments header="Provider Notes" items={escComments} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
  const escModelProps = {
    actions: escalationActions,
    centered: !initFalsy,
    content: escalationModelContent,
    initialize: escalation,
    onCancel: onEscalationClose
  };
  const confirmButton = (
    <Button color="red" content="Yes" primary={initFalsy} type="button" />
  );
  const confirmProps = {
    content: CONFIRM_MESSAGES.CALL_LOG_SURVEY_EXIT,
    header: 'Survey Exit',
    confirmButton,
    open: confirmClose,
    onCancel: () => setConfirmClose(initFalsy),
    onConfirm: props.onCancel
  };

  React.useEffect(firstRun, []);

  return (
    <Grid>
      <Grid.Row className="headerTop no-print">
        <Grid.Column width={16}>
          <h1 className="mainTitle">Call Log Details</h1>
          <Icon onClick={onConfirm} {...closeProps} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={6} tablet={6} mobile={16} className="no-print">
          <CallDetail {...callDetailsProps} />
          <CallSurComments header="Comments" items={callComments} />
        </Grid.Column>
        <Grid.Column computer={10} tablet={10} mobile={16}>
          <Grid.Row>
            <Grid.Column width={16}>
              {
                vo(callInfo) && !!callInfo.projectId &&
                <Questionnaire {...questionnaireProps} />
              }
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>


      {
        confirmClose &&
        <Confirm {...confirmProps} />
      }

      {
        escalation &&
        <Model {...escModelProps} />
      }
    </Grid>
  );
};

export default CallLogResponse;
