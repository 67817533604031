import React from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {Card} from '../../components/Inputs';
import {PublicLayoutProps} from '../../model';
import {setPageTitle} from '../../utils/common';
import {__NODE_ENV__, __REACT_APP_ENV__} from '../../constants';


const PublicLayout: React.FC<PublicLayoutProps> = (props: PublicLayoutProps) => {
  const {resetToken} = useParams();
  const loc = useLocation();
  const pathname = loc.pathname === '/' ? 'Cross Roads' : loc.pathname;
  const isResetForm = !!resetToken;
  setPageTitle(pathname);
  return (
    <div className="logInWrap">
      <small className="invisible">You are running this application in <b>{__NODE_ENV__}</b> mode.</small>
      <small className="invisible">And server is <b>{__REACT_APP_ENV__}</b>.</small>
      <Card className={`loginFormCard ${isResetForm ? 'resetForm' : ''}`}>
        <Card.Content>
          {
            props && props.children
          }
        </Card.Content>
      </Card>
    </div>
  );
};

export default PublicLayout;
