import React from 'react';
import {useActions} from '../../redux/actions';
import * as authActions from '../../redux/actions/auth';
import {AUTH_TOKEN_REFRESH_IN} from '../../constants';

const useRefreshToken = () => {
  const _sessionTimeEnd = 0;
  const {Load} = useActions(authActions);

  const [sessionTimer, setSessionTimer] = React.useState(AUTH_TOKEN_REFRESH_IN);
  const resetTimer = (_newStartTime: number) => {
    setSessionTimer(_newStartTime);
  };
  const refreshToken = async () => {
    await Load();
  };
  const sessionTimerEff = () => {
    const myIntervalCB = () => {
      if (sessionTimer > 0) {
        const val = sessionTimer - 1;
        setSessionTimer(val);
      }
    };
    const myIntervalTimeout = 1000;
    const myInterval = setInterval(myIntervalCB, myIntervalTimeout);

    if (sessionTimer <= _sessionTimeEnd) {
      refreshToken().then(() => resetTimer(AUTH_TOKEN_REFRESH_IN));
    }

    return () => {
      clearInterval(myInterval);
    };
  };

  React.useEffect(sessionTimerEff, [sessionTimer]);
};

export default useRefreshToken;
