import React, {SyntheticEvent} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {BtnSwitch, Card, Grid, GridView, Icon, Paging, ProgressGoals, Search} from '../Inputs';
import {Loader} from '../../components';
import {ButtonProps, IconProps, SearchProps, ST, StoreT, SurList2, T} from '../../model';
import {Path} from '../../routes';
import {useActions} from '../../redux/actions';
import * as userAccActions from '../../redux/actions/users';
import {va} from '../../utils/arrayUtils';
import {getSkipLimit, isValidSearch, joinAsFullName} from '../../utils/common';
import {sleep} from '../../utils/objectUtils';
import {DEFAULT_RECORDS_PER_PAGE, GoalsHeader, initFalsy, ROLES} from '../../constants';


interface SUVProps {
  isPM?: boolean;
}

const Surveyors = ({isPM}: SUVProps) => {
  const initPageNo = 1;
  const sortKeys = ['firstName'];
  const hist = useHistory();
  const sessionUser = useSelector((state: StoreT) => state.auth.user);
  const {count, rows}: SurList2 = useSelector((state: StoreT) => state.surveyors) || [];
  const userAction = useActions(userAccActions);
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [loader, isLoading] = React.useState(initFalsy);
  const [searchVal, onSearch]: [T, Function] = React.useState('');
  const [isWeeklySelected, setWeekly] = React.useState(!initFalsy);
  const initSort: { column: string; direction: ST } = {column: 'firstName', direction: 'ASC'};
  const [sortVars] = React.useState(initSort);
  const goalsHeader = isWeeklySelected ? `${GoalsHeader.Weekly} / ${GoalsHeader.WeeklyTotal}` : `${GoalsHeader.RP} / ${GoalsHeader.RPTotal}`;
  const headers = ['Surveyor Name', goalsHeader, 'View Project(s)'];
  const onAction = async (p: T) => {
    let path = '';
    switch (sessionUser.role) {
      case ROLES['CIMR Manager']:
        path = Path.CIMRSurveyorProjects;
        break;
      case ROLES.Operations:
        path = Path.OPSSurveyorProjects;
        break;
      case ROLES['Project Manager']:
        path = Path.MySurveyorProjects;
        break;
    }
    const mySurRS = {projectId: p.id, name: p.name};
    const pathWithProjectId = `${path}/${p.id}`;
    hist.push(pathWithProjectId, mySurRS);
  };
  const onChangeType = (isWeekly: boolean) => {
    setWeekly(isWeekly);
    const skip = getSkipLimit(activePage, limit);
    fetchSurveyors(skip, limit, sortVars.column, sortVars.direction, isWeekly)
  };
  const navFux = async (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    fetchSurveyors(skip, gridSize, sortVars.column, sortVars.direction, isWeeklySelected).then();
  };
  const onSort = async (cc: string, direction: ST) => {
    const skip = getSkipLimit(activePage, limit);
    await fetchSurveyors(skip, limit, cc, direction, isWeeklySelected);
  };
  const fetchSurveyors = async (skip = 0, lim = DEFAULT_RECORDS_PER_PAGE, sortOn = sortVars.column, sortBy = sortVars.direction, isWeekly: boolean = !initFalsy) => {
    isLoading(!initFalsy);
    const id = isPM ? sessionUser.id : undefined;
    const type = sessionUser.role === ROLES['CIMR Manager'] ? ROLES['CIMR Surveyor'] : undefined;
    await userAction.getSurveyUserList(skip, lim, sortOn, sortBy, id, isWeekly, type, searchVal);
    isLoading(initFalsy);
  };
  const firstRunEffect = () => {
    fetchSurveyors().then();

    return () => userAction.flushSurveyUserList();
  };
  const onSearchChange = (_e: SyntheticEvent, data: SearchProps) => {
    if (isValidSearch(data.value as string, loader)) {
      return sleep(0);
    }

    onSearch(data.value);
  };
  const searchProps = {
    loading: loader,
    onSearchChange: onSearchChange,
    placeholder: 'Search Surveyor...',
    showNoResults: initFalsy,
  };
  const actionIconProp: IconProps = {className: 'hand-pointer', name: 'eye', title: 'Surveyor Projects', type: 'pro'};
  const reportButtProps: ButtonProps[] = [
    {active: isWeeklySelected === !initFalsy, content: 'Current Week', onClick: () => onChangeType(!initFalsy)},
    {as: 'div', className: 'or', key: 'or'},
    {active: isWeeklySelected === initFalsy, content: 'Reporting Period', onClick: () => onChangeType(initFalsy)}
  ];
  const userSurveys = va(rows) ? rows.map(({surveyor, surveyorCompGoals, surveyorGoals, surveysRP}) => ({
    _id: surveyor?.id,
    name: joinAsFullName(surveyor?.firstName, surveyor?.lastName),
    wg: (
      <>
        <ProgressGoals compGoals={surveyorCompGoals} reqGoals={surveyorGoals} surveysRP={surveysRP} />
      </>
    ),
    action: (
      <>
        <Icon {...actionIconProp} onClick={() => onAction(surveyor)} />
      </>
    )
  })) : [];
  const gridProps = {headers, list: userSurveys, onSelect: onAction, onSort, sortKeys};
  const pgProps = {activePage, navigate: navFux, pageSize: limit, setPageChange, setPageSize, totalRecords: count};

  React.useEffect(firstRunEffect, [searchVal]);

  return (
    <>
      {
        loader &&
        <Loader />
      }

      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">My Surveyors</h1>
            <BtnSwitch buttonProps={reportButtProps} />
            <Search {...searchProps} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={16} tablet={16} mobile={16}>
            <Card fluid>
              <Card.Content>
                <GridView {...gridProps} />

                <Paging {...pgProps} />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default Surveyors;
