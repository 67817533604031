import React from 'react';
import {connect} from 'react-redux';
import {HotKeys} from 'react-hotkeys';
import {Account, BN, Language, NS, ProjectsList, StoreT, SurveyComment, T} from '../../model';
import {Button, Card, FormWrap, GenModelProps, Grid, Model} from '../Inputs';
import {CallDetail} from '../Generic';
import {Loader, ToastAlert} from '../index';
import {CCS, CF} from './index';
import {CCConstants} from './CCStructure';
import {useActions} from '../../redux/actions';
import * as commentActions from '../../redux/actions/comments';
import * as languagesActions from '../../redux/actions/languages';
import * as projectActions from '../../redux/actions/projects';
import * as userActions from '../../redux/actions/users'
import {CCStatus, CommentType, CST_TIMEZONE, initFalsy, ROLES, RosterKeys} from '../../constants';
import {va} from '../../utils/arrayUtils';
import {getTextContent, joinAsFullName} from '../../utils/common';
import {usDateTime} from '../../utils/dateUtils';
import isMobileDevice from '../../utils/deviceUtils';
import {piz} from '../../utils/numUtils';
import {vo} from '../../utils/objectUtils';
import {pez, rmSpecialChar} from '../../utils/stringUtils';


interface CCProps {
  commentInfo: SurveyComment;
  languages: Language[];
  projects: ProjectsList;
  projectId?: BN;
  users: Account[];
}

const mapStore = (store: StoreT) => {
  return {
    commentInfo: store.comments,
    languages: store.languages,
    projects: store.projects,
    users: store.users as Account[]
  }
}

const CC: React.FC<CCProps> = (props) => {
  const JsKeyCode = 'ctrl+enter';
  const strEmpty = '';
  const initNSM = {negative: strEmpty, normal: strEmpty, positive: strEmpty};
  const {commentInfo, languages, projects, projectId, users} = props;

  const _commentAction = useActions(commentActions);
  const _langAction = useActions(languagesActions);
  const _proAction = useActions(projectActions);
  const _userAction = useActions(userActions);

  const [useLambda, setUseLambda] = React.useState(initFalsy);
  const [pid, setProject] = React.useState(projectId);
  const [managerId, setPM] = React.useState(strEmpty as NS);
  const [quesId, setQuesId] = React.useState(strEmpty as NS);
  const [loader, isLoading] = React.useState(initFalsy);
  const [withoutNSM, setWithoutNSM] = React.useState(initNSM);
  const [withNSM, setWithNSM] = React.useState(initNSM);
  const [includePositive, setIncludePositive] = React.useState(initFalsy);
  const [includeNegative, setIncludeNegative] = React.useState(initFalsy);
  const [isOpenPositive, setIsPositive] = React.useState(initFalsy);
  const [isOpenNegative, setIsNegative] = React.useState(initFalsy);
  const [positiveComment, setPosCmt] = React.useState(strEmpty);
  const [negativeComment, setNegCmt] = React.useState(strEmpty);
  const isPN = isOpenPositive || isOpenNegative;

  const getElemId = (isWO: boolean = initFalsy) => {
    return isWO ?
      (isOpenPositive ? CCConstants.PWONsm : (isOpenNegative ? CCConstants.NWONsm : CCConstants.WONsm)) :
      (isOpenPositive ? CCConstants.PWNsm : (isOpenNegative ? CCConstants.NWNsm : CCConstants.WNsm));
  };
  const captureContent = () => {
    const wTxtId = getElemId(initFalsy);
    const woTxtId = getElemId(!initFalsy);
    const wNSM = getTextContent(wTxtId);
    const woNSM = getTextContent(woTxtId);
    const wNsm = Object.assign(withNSM, {
      negative: isOpenNegative ? wNSM : withNSM.negative,
      normal: isPN ? withNSM.normal : wNSM,
      positive: isOpenPositive ? wNSM : withNSM.positive
    });
    const woNsm = Object.assign(withoutNSM, {
      negative: isOpenNegative ? woNSM : withoutNSM.negative,
      normal: isPN ? withoutNSM.normal : woNSM,
      positive: isOpenPositive ? woNSM : withoutNSM.positive
    });
    setWithNSM(wNsm);
    setWithoutNSM(woNsm);
  };
  const clearEditable = (elemId: string) => {
    const el = document.getElementById(elemId);
    if (el) {
      el.innerText = strEmpty;
      el.innerHTML = strEmpty;
    }
  };
  const onOpenPositive = () => {
    setIsPositive(!initFalsy);
  };
  const onOpenNegative = () => {
    setIsNegative(!initFalsy);
  };
  const onSavePN = async () => {
    captureContent();
    if (isPN) {
      if (isOpenPositive) {
        setIncludePositive(!initFalsy);
      }
      if (isOpenNegative) {
        setIncludeNegative(!initFalsy);
      }
    }
    setIsPositive(initFalsy);
    setIsNegative(initFalsy);
  };
  const onClosePN = async () => {
    setIsPositive(initFalsy);
    setIsNegative(initFalsy);
  };
  const _fetchComment = async () => {
    isLoading(!initFalsy);
    const _projectId = pid || projectId;
    const commentRes: SurveyComment = await _commentAction.fetchComment(managerId, _projectId, quesId, useLambda);
    if (vo(commentRes)) {
      const [negative, positive] = [commentRes.negative?.normal, commentRes.positive?.normal];
      const [negativeWO, positiveWO] = [commentRes.negative?.cleaned, commentRes.positive?.cleaned];
      const [negativeNSM, positiveNSM] = [commentRes.negative?.nsm, commentRes.positive?.nsm];
      const woNsm = Object.assign({}, {
        negative: pez(negativeWO, negative),
        normal: commentRes.normal as string,
        positive: pez(positiveWO, positive)
      });
      const wNsm = Object.assign({}, {
        negative: pez(negativeNSM),
        normal: strEmpty,
        positive: pez(positiveNSM)
      });
      setWithoutNSM(woNsm);
      setWithNSM(wNsm);
      setPosCmt(positive);
      setNegCmt(negative);
      if (!!quesId) {
        setQuesId(commentRes.question?.id);
      }
    }

    isLoading(initFalsy);
  };
  const onUnClean = async () => {
    return _commentAction.unCleanComment();
  };
  const onCCFlush = async () => {
    return _commentAction.flushComments();
  };
  const onSave = async () => {
    if (!vo(commentInfo)) {
      return;
    }

    isLoading(!initFalsy);
    captureContent();

    const payload = {
      projectId: commentInfo.projectId
    };

    const isPositive = commentInfo.type === CommentType.P;
    const isSpecialCase = commentInfo.type !== CommentType.OE;
    const rmChar = '\\xa0';         // Special characters remove from the comment cleaning section
    if (isSpecialCase) {
      if (isPositive) {
        Object.assign(payload, {
          positiveId: commentInfo.positive.id,
          positiveWNSM: rmSpecialChar(withNSM.normal, rmChar, ''),
          positiveWoNSM: rmSpecialChar(withoutNSM.normal, rmChar, ''),
        });
      } else {
        Object.assign(payload, {
          negativeId: commentInfo.negative.id,
          negativeWNSM: rmSpecialChar(withNSM.normal, rmChar, ''),
          negativeWoNSM: rmSpecialChar(withoutNSM.normal, rmChar, '')
        });
      }
    } else {
      Object.assign(payload, {
        id: commentInfo.id,
        nsm: rmSpecialChar(withNSM.normal, rmChar, ''),
        cleaned: rmSpecialChar(withoutNSM.normal, rmChar, '')
      });
    }

    if (includePositive) {
      Object.assign(payload, {
        positiveId: commentInfo.positive.id,
        positiveWNSM: rmSpecialChar(withNSM.positive, rmChar, ''),
        positiveWoNSM: rmSpecialChar(withoutNSM.positive, rmChar, ''),
      });
    }
    if (includeNegative) {
      Object.assign(payload, {
        negativeId: commentInfo.negative.id,
        negativeWNSM: rmSpecialChar(withNSM.negative, rmChar, ''),
        negativeWoNSM: rmSpecialChar(withoutNSM.negative, rmChar, ''),
      });
    }

    await _commentAction.saveCleanedComment(payload);

    setWithNSM(initNSM);
    setWithoutNSM(initNSM);
    setIncludePositive(initFalsy);
    setIncludeNegative(initFalsy);

    await _fetchComment()
    isLoading(initFalsy);
  };
  const firstStep = () => {
    const [sortOn, sortBy] = ['name', 'ASC'];

    const _projectId = pid || projectId;

    _langAction.fetchProjectLanguages(piz(_projectId))
      .then(_fetchComment)
      .then(async () => {
        _proAction.flushProjects();
        await _userAction.fetchUsers(ROLES['Project Manager'], '', initFalsy);
        await _proAction.fetchProjects(0, Number.MAX_SAFE_INTEGER, sortOn, sortBy, undefined, undefined, !initFalsy, !initFalsy, initFalsy, '', ['managerId']);
      });

    return () => {
      onUnClean().then(onCCFlush);
    };
  };
  const getLanguageName = (langId: number) => {
    return languages.find(l => l.id === langId)?.name;
  }
  const callDetail = vo(commentInfo) ? [
    {label: 'Question Label', value: commentInfo.question.title},
    {label: RosterKeys.PatientName, value: commentInfo.ch.patientFullName},
    {label: 'Surveyor Name', value: joinAsFullName(commentInfo.ch.user.firstName, commentInfo.ch.user.lastName)},
    {label: 'Organization Name', value: pez(commentInfo.vi && commentInfo.vi.value)},
    {label: RosterKeys.Department, value: commentInfo.ch.departmentName},
    {label: RosterKeys.Center, value: commentInfo.ch.siteName},
    {label: RosterKeys.ProviderName, value: commentInfo.ch.providerName},
    {label: RosterKeys.Language, value: getLanguageName(commentInfo.ch.languageId)},
    {label: `Survey ${RosterKeys.Language}`, value: getLanguageName(commentInfo.ch.surveyLanguageId)},
    {label: 'Date Of Service: ', value: new Date(commentInfo.ch.dos).toUSDateString()},
    {label: 'Surveyed On: ', value: usDateTime(commentInfo.ch.surveyedOn, CST_TIMEZONE)}
  ] : [];
  const cbp = {className: `mt10 ${isMobileDevice ? '' : 'ml10'}`, secondary: !initFalsy};
  const pnHeader = `${isPN ? (isOpenPositive ? 'Positive' : 'Negative') : 'Uncleaned'} Comments`;
  const buttonProps = [
    {...cbp, content: 'Open Positive', onClick: () => onOpenPositive()},
    {...cbp, content: 'Open Negative', onClick: () => onOpenNegative()},
    {
      ...cbp,
      content: 'Save',
      className: `mt10 ${isMobileDevice ? '' : 'ml10'}`,
      disabled: loader,
      primary: !initFalsy,
      loading: loader,
      secondary: initFalsy,
      onClick: onSave
    }
  ];
  const commentCleanerProps = {
    additionalButtons: isPN ? [] : buttonProps,
    captureContent,
    comment: isOpenPositive ? positiveComment : (isOpenNegative ? negativeComment : commentInfo?.normal as string),
    commentInfo,
    getElemId,
    isOpenNegative,
    isOpenPositive,
    isPN,
    pnHeader,
    setWithNSM,
    setWithoutNSM,
    withNSM,
    withoutNSM
  };
  const onActions = (
    <>
      <Button content="Save and Return" onClick={onSavePN} primary={!initFalsy} />
      <Button content="Cancel" onClick={onClosePN} />
    </>
  );

  const modelContent = (
    <Grid>
      {
        isPN && commentInfo && (
          (isOpenPositive && commentInfo.positive?.markCleaned === CCStatus.Processing) ||
          (!isOpenPositive && commentInfo.negative?.markCleaned === CCStatus.Processing)
        ) &&
        <ToastAlert severity="yellow" message={'Warning! This comment is being cleaned by someone else!!!'} />
      }
      {
        isPN && commentInfo && (
          (isOpenPositive && commentInfo.positive?.markCleaned === CCStatus.Cleaned) ||
          (!isOpenPositive && commentInfo.negative?.markCleaned === CCStatus.Cleaned)
        ) &&
        <ToastAlert severity="green" message={'Warning! This comment is already cleaned.'} />
      }
      <Grid.Row>
        {
          (isPN) &&
          <Grid.Column width={16}>
            <CCS {...commentCleanerProps} />
          </Grid.Column>
        }
      </Grid.Row>
    </Grid>
  );
  const modelProps: GenModelProps = {
    actions: onActions,
    centered: !initFalsy,
    content: modelContent,
    header: pnHeader,
    initialize: isOpenPositive ? isOpenPositive : isOpenNegative,
    onCancel: onClosePN,
    size: 'large'
  };
  const filtersFormConfig = {
    children: CF,
    clearEditable,
    displayName: 'Comment-Cleaner-filter',
    hasRows: vo(commentInfo),
    initialValues: {managerId, projectId: pid, quesId},
    isLoading,
    managerId,
    managers: users,
    onCCFlush,
    onSubmit: async () => {
      await onUnClean();
      await _fetchComment();
    },
    onUnClean,
    pid,
    projects,
    quesId,
    questions: commentInfo?.questions,
    setPM,
    setProject,
    setQuesId,
    setUseLambda,
    useLambda,
  };
  const keyMap: T = {CONTRACT: {sequence: JsKeyCode}};
  const handlers = {CONTRACT: (_event: T) => onSave().then()};
  const hotProps = {handlers, keyMap};

  React.useEffect(firstStep, []);

  return (
    <HotKeys {...hotProps}>
      {
        loader &&
        <Loader />
      }

      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">Comment Cleaning</h1>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <CallDetail listItems={callDetail} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Card>
              <Card.Content>
                {
                  va(users) && vo(projects) &&
                  <FormWrap {...filtersFormConfig} />
                }
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            {
              !isPN &&
              <CCS {...commentCleanerProps} />
            }
          </Grid.Column>
        </Grid.Row>

        {
          isPN &&
          <Model {...modelProps} />
        }
      </Grid>
    </HotKeys>
  );
};

export default connect(mapStore)(CC);
