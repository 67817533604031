import * as React from 'react';
import {SyntheticEvent} from 'react';
import AdminDashboard from './AdminDashboard';
import {BtnSwitch, Button, Grid, Search, Select} from '../../components/Inputs';
import {AccountsList, AddAccount, ViewEditAccount} from '../../components/Admin';
import {Loader} from '../../components';
import {useActions} from '../../redux/actions';
import * as userAccActions from '../../redux/actions/users';
import * as languagesActions from '../../redux/actions/languages';
import {enumAsArray} from '../../utils/arrayUtils';
import {mapSelectOption, isValidSearch} from '../../utils/common';
import {sleep} from '../../utils/objectUtils';
import {ButtonProps, SearchProps, SelectValue, T} from '../../model';
import {DEFAULT_RECORDS_PER_PAGE, Del, initFalsy, ROLES as UserRoles} from '../../constants';


type V = SelectValue
type ActionType = { id: number | undefined, action: string };


const AccountManagement: React.FC = () => {
  const
    initPageNo = 1,
    userRoles = enumAsArray(UserRoles, !initFalsy),
    userAction = useActions(userAccActions),
    langAction = useActions(languagesActions),
    [miniLoader, setLoader] = React.useState(initFalsy),
    [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE),
    [activePage, setPageChange] = React.useState(initPageNo),
    [state, setAction]: [ActionType, Function] = React.useState({
      id: undefined,
      action: '',
      isAdmin: !initFalsy
    }),
    [isAdd, setIsAdd] = React.useState(initFalsy),
    [searchVal, onSearch]: [T, Function] = React.useState(null),
    [status, setUserStatus]: [T, Function] = React.useState(null),
    [curRole, setRole]: [V, Function] = React.useState(userRoles[-1]),
    searchProps = {
      loading: miniLoader,
      showNoResults: initFalsy,
      placeholder: 'Search...'
    },
    selectProps = {
      clearable: !initFalsy,
      name: 'userRole',
      options: mapSelectOption(userRoles.sort(), !initFalsy),
      placeholder: 'Filter By Role...'
    },
    addBtnProps: ButtonProps = {icon: 'plus', labelPosition: 'left', primary: !initFalsy},
    accCsvExpo: ButtonProps = {content: 'Export', icon: 'file excel', primary: !initFalsy},
    onChangeType = (val: boolean) => status === val ? setUserStatus(null) : setUserStatus(val),
    reportButtProps: ButtonProps[] = [
      {active: status === !initFalsy, content: Del.AC, onClick: () => onChangeType(!initFalsy)},
      {as: 'div', className: 'or', key: 'or'},
      {active: status === initFalsy, content: Del.DE, onClick: () => onChangeType(initFalsy)}
    ],
    doEdit = (id: number) => onAction({id, action: 'edit'}),
    onSearchChange = async (_e: SyntheticEvent, data: SearchProps) => {
      if (isValidSearch(data.value as string, miniLoader)) {
        return sleep(0);
      }

      onSearch(data.value);
    },
    onShowCancelAddUser = () => setIsAdd(!isAdd),
    onAction = async (object: ActionType) => {
      const {id, action} = object;
      setLoader(!initFalsy);

      switch (action) {
        case 'view':
        case 'edit':
          await userAction.pullUserInfo(id);
          await langAction.fetchLanguages(undefined, undefined, undefined, undefined, initFalsy);
          break;
        case 'invite':
        case 'delete':
        case 'lock':
          await sleep(50);
          break;
        default:
          Object.assign(object, {id: undefined, action: ''});
          break;
      }
      Object.assign(object, {isAdmin: !initFalsy})
      setAction(object);
      setLoader(initFalsy);
    },
    onClose = () => {
      const obj = Object.assign({}, {id: undefined, action: ''});
      setAction(obj);
    },
    exportAccountCsv = () => {
      setLoader(!initFalsy);
      const role = UserRoles[curRole];
      userAction.exportAccCSV(role, searchVal, status);
      setLoader(initFalsy);
    },
    accountsListProps = {
      activePage,
      limit,
      isAdmin: !initFalsy,
      onAction,
      reload: isAdd,
      role: UserRoles[curRole],
      search: searchVal,
      setPageChange,
      setPageSize,
      status
    };

  return (
    <AdminDashboard>

      {
        miniLoader &&
        <Loader />
      }

      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">Account Management</h1>
            <Search {...searchProps} onSearchChange={onSearchChange} />
            <Select {...selectProps} onChange={(value: SelectValue) => setRole(value)} />
            <Button {...addBtnProps} content="Add Account" onClick={onShowCancelAddUser} />
            <Button {...accCsvExpo} onClick={() => exportAccountCsv()} />
            <BtnSwitch buttonProps={reportButtProps} />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {
        isAdd &&
        <AddAccount onCancel={onShowCancelAddUser} userRoles={userRoles} />
      }

      {
        state.id && (state.action === 'view' || state.action === 'edit') &&
        <ViewEditAccount onCancel={onClose} doEdit={doEdit} {...state} />
      }

      {
        !state.id &&
        <AccountsList {...accountsListProps} />
      }

    </AdminDashboard>
  );
};

export default AccountManagement;
