import React from 'react';
import {useHistory, useParams} from 'react-router';
import {connect} from 'react-redux';
import {Loader} from '../../components';
import {CallsHistoryChart} from '../../components/Calls';
import {BtnSwitch, Button, GenModelProps, Grid, Model} from '../../components/Inputs';
import {Account, ButtonProps, ChartData, LabelField, Project, StoreT, T} from '../../model';
import {useActions} from '../../redux/actions';
import * as prjActions from '../../redux/actions/projects';
import * as clActions from '../../redux/actions/calls';
import {initFalsy, PROJECT_STATUS, ROLES, StatusCode} from '../../constants';
import {Path} from '../../routes';
import {aMaxVal, va} from '../../utils/arrayUtils';
import {joinAsFullName, noop, surveyMaxValue} from '../../utils/common';
import {asCurrency} from '../../utils/numUtils';
import * as usrActions from '../../redux/actions/users';
import {ViewEditAccount} from '../../components/Admin';
import {pez} from '../../utils/stringUtils';


interface SurProjectDetailsProps {
  callsHistory: ChartData[];
  projectInfo: Project;
  user: Account;
}

const SurProjectDetails: React.FC<SurProjectDetailsProps> = ({callsHistory, projectInfo, user}) => {
  const {id} = useParams();
  const hist = useHistory();
  const [isWeeklySelected, setWeekly] = React.useState(!initFalsy);
  const [loader, isLoading] = React.useState(initFalsy);
  const [isAssignedQues, setIsAssignedQues] = React.useState(initFalsy);
  const [isShowPMPopup, setShowPMPopup] = React.useState(initFalsy);
  const projectActions = useActions(prjActions);
  const callActions = useActions(clActions);
  const userActions = useActions(usrActions);
  const CIMRRoles = [ROLES['CIMR Surveyor'], ROLES['CIMR Manager']]
  const isCIMR = CIMRRoles.includes(user.role);
  const completedSurvey = va(callsHistory) ? aMaxVal(callsHistory.map(c => c.cSurveys)) : 0;
  const onShowPM = async () => {
    isLoading(!initFalsy);
    await userActions.pullUserInfo(projectInfo?.managerId);
    setShowPMPopup(!initFalsy);
    isLoading(initFalsy);
  };
  const onHidePMPopup = () => {
    setShowPMPopup(initFalsy);
  };
  const proDetails: LabelField[] = [
    {
      as: 'a',
      label: 'Project Manager',
      onClick: onShowPM,
      value: joinAsFullName(projectInfo?.user?.firstName, projectInfo?.user?.lastName),
      title: 'Project Manager Contact Information'
    },
    {label: 'Time Zone', value: pez(projectInfo.timezone as string)},
    {label: 'Status', value: PROJECT_STATUS[projectInfo.statusId]},
    {label: `Current ${isWeeklySelected ? 'Week' : 'Report'} Count`, value: completedSurvey}
  ];
  const callNow = () => {
    // hist.push(`${Path.SurveyorProjectCall}/${id}`);
    const path = user.role === ROLES.Surveyor ? `${Path.SurveyorProjectSurvey}/${id}` : `${Path.CCSProjectSurvey}/${id}`;
    hist.push(path);
  };
  const onChangeType = (isWeekly: boolean) => {
    isLoading(!initFalsy);
    callActions.fetchSurCallHistory(id, user.id, isWeekly, initFalsy);
    setWeekly(isWeekly);
    isLoading(initFalsy);
  };
  const callBtnProps: ButtonProps = {
    icon: 'phone',
    labelPosition: 'left',
    className: projectInfo.isLocked ? 'rowLocked' : 'float-right',
    primary: projectInfo.isLocked ? initFalsy : !initFalsy,
    content: 'Call Now',
    onClick: projectInfo.isLocked ? noop : callNow
  };
  const reportButtProps: ButtonProps[] = [
    {active: isWeeklySelected === !initFalsy, content: 'Current Week', onClick: () => onChangeType(!initFalsy)},
    {as: 'div', className: 'or', key: 'or'},
    {active: isWeeklySelected === initFalsy, content: 'Reporting Period', onClick: () => onChangeType(initFalsy)}
  ];
  const pmModelContentProps = {
    action: 'view',
    id: projectInfo?.user?.id,
    isModel: !initFalsy,
    onCancel: onHidePMPopup
  };
  const pmModelProps: GenModelProps = {
    content: (<ViewEditAccount {...pmModelContentProps} />),
    initialize: isShowPMPopup,
    onCancel: onHidePMPopup,
    size: 'small'
  };

  const firstRun = () => {
    isLoading(!initFalsy);
    projectActions.getProjectInfo(id)
      .then((p: Project) => {
        if (p.statusCode === StatusCode.Unauthorized) {
          isLoading(!initFalsy);
          setIsAssignedQues(!initFalsy);
        } else {
          callActions.fetchSurCallHistory(id, user.id, isWeeklySelected, initFalsy)
            .then(() => isLoading(initFalsy));
        }
      })
      .catch(() => {
        isLoading(initFalsy);
        setIsAssignedQues(!initFalsy);
      });

    return () => {
      projectActions.flushProjectInfo();
      callActions.flushCallInfo();
    }
  };

  if (!isCIMR) {
    const earnings = projectInfo.paymentRate * completedSurvey;
    const _moreProDet = [
      {label: `Current ${isWeeklySelected ? 'Week' : 'Report'} Earnings`, value: asCurrency(earnings)}
    ];
    _moreProDet.forEach(itm => proDetails.push(itm));
  }

  React.useEffect(firstRun, []);

  return (
    <>
      {
        loader &&
        <Loader />
      }
      {
        !isAssignedQues &&
        <Grid>
          <Grid.Row className="headerTop">
            <Grid.Column width={16}>
              <h1 className="mainTitle">Project Details</h1>
              <BtnSwitch buttonProps={reportButtProps} />
              <Button {...callBtnProps} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched>
            <CallsHistoryChart
              callsHistory={callsHistory}
              isLock={projectInfo.isLocked}
              isWeeklySelected={isWeeklySelected}
              maxValue={surveyMaxValue(callsHistory)}
              proDetails={proDetails}
              projectName={projectInfo.name}
            />
          </Grid.Row>

          {
            isShowPMPopup &&
            <Model {...pmModelProps} />
          }
        </Grid>
      }
    </>
  );
};

const mapToProps = (state: StoreT) => {
  return {
    callsHistory: state.callsHistory as T,
    projectInfo: state.projectInfo,
    user: state.auth.user
  };
};

export default connect(mapToProps)(SurProjectDetails);
