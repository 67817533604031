import React from 'react';
import {useLocation} from 'react-router';
import {CC} from '../../components/CommentCleaner';
import {T} from '../../model';
import {vo} from '../../utils/objectUtils';

const CommentCleaning = () => {
  const loc = useLocation();
  const ccProps = vo(loc.state as T) ? loc.state : {};

  return (
    <CC {...ccProps} />
  );
};

export default CommentCleaning;
