import {ActionType, ApiEngine, CleanResponse, Dispatcher, T} from '../../model';
import {setAlert, setSuccess} from './alert';

const apiPath = 'escalations';

type FetchEsc = (skip: number, limit: number, orderBy: string, orderDir: string, projectId: number, surveyorId: number, from: string, to: string, status: boolean, managerId?: number) => (dispatch: Function, _store: Function, api: ApiEngine) => Promise<T>;
type FetchEscById = (id: number) => (dispatch: Function, _store: Function, api: ApiEngine) => Promise<T>;
type UpdateEscStatus = (payload: { callHisId: number; id: number }) => (dispatch: Function, _store: Function, api: ApiEngine) => Promise<T>;


/**
 * Flush Escalations
 */
export const flushEscalations: () => Dispatcher = () => async (dispatch) => {
  return dispatch({type: ActionType.ESCALATION_FLUSH, payload: {}});
};

/**
 * Flush Escalation
 */
export const flushEscalation: () => Dispatcher = () => async (dispatch) => {
  return dispatch({type: ActionType.ESCALATION_FLUSH_BY_ID, payload: {}});
};

/**
 * Fetch Escalations
 * @param managerId
 * @param skip
 * @param limit
 * @param orderBy
 * @param orderDir
 * @param surveyorId
 * @param from
 * @param to
 * @param projectId
 * @param status
 * @returns
 */
export const fetchAllEscalation: FetchEsc = (skip, limit, orderBy, orderDir, surveyorId, from, to, projectId, status, managerId) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const query = {from, limit, managerId, orderBy, orderDir, projectId, skip, status, surveyorId, to};
    const data = await api.get(apiPath, null, query);
    dispatch({type: ActionType.ESCALATION_FETCH, payload: data});
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Escalation By Id
 * @returns payload
 */
export const fetchEscalationById: FetchEscById = (id) => async (dispatch, _store, api) => {
  try {
    const apiFetchEscByIdPath = `${apiPath}/${id}`;
    const data = await api.get(apiFetchEscByIdPath);
    dispatch({type: ActionType.ESCALATION_FETCH_BY_ID, payload: data});
    return data;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Update Escalation Resolution Status
 * @param payload
 */
export const updateEscalationStatus: UpdateEscStatus = (payload: { callHisId: number, id: number }) => async (dispatch, _store, api) => {
  try {
    const res: CleanResponse = await api.put(apiPath, payload);
    dispatch({type: ActionType.UPDATE_ESCALATION_NOTES, payload: res.data});
    dispatch(setSuccess(res.message));
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
}
