import React from 'react';
import {useHistory, useParams} from 'react-router';
import {connect} from 'react-redux';
import {DndWrapper, Droppable, ItemTypes} from '../DnD';
import {Button, Card, Grid, List, Select} from '../Inputs';
import {
  Answer,
  ButtonProps,
  Project,
  QAProjects,
  Question,
  QuestionnaireItem,
  reOrderProps,
  SelectProps,
  SelectValue,
  StoreT,
  T
} from '../../model';
import {Path} from '../../routes';
import {MiniType, QAList, QATree} from './index';
import {AnswerType, initFalsy} from '../../constants';
import {useActions} from '../../redux/actions';
import * as alertActions from '../../redux/actions/alert';
import * as ansActions from '../../redux/actions/answers';
import * as questActions from '../../redux/actions/questions';
import * as questionnaireActions from '../../redux/actions/questionnaire';
import {aMaxVal, enumAsAO, va} from '../../utils/arrayUtils';
import {piz} from '../../utils/numUtils';
import {sleep, vo} from '../../utils/objectUtils';
import {Loader} from '../index';


interface QBProps {
  buttons: ButtonProps[];
  goBackProps: ButtonProps;
  isOps?: boolean;
  questionnaire: QuestionnaireItem[];
}

type QAT = (Question | Answer) & { type: string; };

interface QreProps extends QuestionnaireItem {
}

enum QATab {
  Questions,
  Answers
}


const QuestionnaireBuilderMain: React.FC<QBProps> = ({buttons, goBackProps, isOps}) => {
  const
    {id} = useParams(),
    hist = useHistory(),
    questionnairesResponse: T[] = [],
    isAdd = id === 'add',
    [tabQAIndex, setTabQAIndex] = React.useState(0),
    [selProjectId, setProjectId] = React.useState(),
    [searchValue, setSearchValue] = React.useState(''),
    [loading, setLoading] = React.useState(initFalsy),
    [qnDropValue, setQnDropValue] = React.useState(''),
    [items, loadQuestionnaire] = React.useState([] as Partial<QuestionnaireItem>[]),
    [droppedQnItem, setQA] = React.useState({} as Partial<QuestionnaireItem>),
    [editingQnItem, setEQA] = React.useState({} as Partial<QuestionnaireItem>),
    [droppedQuestion, setQuestions] = React.useState({} as Partial<Question>),
    [droppedAnswer, setResponses] = React.useState([] as Partial<Answer>[]),
    [showSave, setShowSave] = React.useState(initFalsy),
    [isAssignedQues, setIsAssignedQues] = React.useState(initFalsy),
    [startFresh, setStartFresh] = React.useState(initFalsy),
    [selTypeId, setAnsType]: [T, Function] = React.useState(null),
    [getEditQuesId, setEditQuesId] = React.useState(0),
    [editTypeId, setEditType] = React.useState(initFalsy),
    [isEdit, setEdit] = React.useState(initFalsy),
    [projects, setProjects]: [Project[], Function] = React.useState([]),
    qsBuilderBottom = 'questionnaire-bottom',
    alert = useActions(alertActions),
    que = useActions(questActions),
    ans = useActions(ansActions),
    qre = useActions(questionnaireActions),
    proOpts = projects?.map(p => ({'text': p.name, 'value': p.id})),
    onTabChange = async (tabIdx: number) => {
      await sleep(50);
      setTabQAIndex(tabIdx);
    },
    onClear = async () => {
      setQA({});
      setAnsType(null);
      setQuestions({});
      setResponses([]);
      setShowSave(initFalsy);
      setStartFresh(initFalsy);
      setSearchValue('');
      setQnDropValue('');
      await onTabChange(QATab.Questions);
    },
    refreshQAList = () => {
      const search = '', isQsBuilder = !initFalsy;
      return Promise.race([
        que.fetchQuestions(search, isQsBuilder),
        ans.fetchAnswers(search, isQsBuilder)
      ]);
    },
    onAnswerDel = async (selectedAnswer: T) => {
      const {projectId, ansId, sequence} = selectedAnswer;
      await qre.removeResponse(projectId, ansId, sequence);
      const index = editingQnItem.answer?.findIndex((ans: Answer) => ans.id === selectedAnswer.ansId) as number;
      (editingQnItem.answer as [])?.splice(index, 1);
      setQA(editingQnItem);
      if (editingQnItem.answer?.length === 0) {
        await cancelEdit()
      }
    },
    onDel = async (sequenceId: number) => {
      await sleep(50);
      await qre.removeQuestionnaire(id, sequenceId)
        .then((data: QreProps[]) => {
          loadQuestionnaire(data as QuestionnaireItem[]);
        });
    },
    onEdit = async (props: T) => {
      const editingQuestionnaireItem = props;
      setEditQuesId(editingQuestionnaireItem.quesId);
      setEdit(!initFalsy);
      if (editingQuestionnaireItem.type === AnswerType.Multiple || editingQuestionnaireItem.type === AnswerType.Single) {
        setEditType(editingQuestionnaireItem.type === AnswerType.Multiple);
      }
      const answers = droppedAnswer.concat(props.answer);
      setResponses(answers);
      setEQA({...editingQuestionnaireItem, answer: answers as Answer[]});
      setSearchValue('');
      await onTabChange(QATab.Answers);
    },
    onChangeSequence = async (projectId: number, sequence: number, isInc: boolean) => {
      await qre.changeQuestionnaireSequence(projectId, sequence, isInc);

      const
        currIndex = items.findIndex((i) => i.sequence === sequence),
        selectedQA = items[currIndex],
        affectedIndex = isInc ? currIndex + 1 : currIndex - 1,
        affectedItem = items[affectedIndex];

      selectedQA.sequence = affectedItem.sequence;
      affectedItem.sequence = sequence;

      items[currIndex] = affectedItem;
      items[affectedIndex] = selectedQA;

      loadQuestionnaire(Object.assign([], items));
    },
    reOrderResponsePayload = async (cId: number, nId: number, rePosition: number, reOrderData: reOrderProps) => {
      const {isInc} = reOrderData;
      questionnairesResponse.push({id: cId, rPosition: rePosition}, {
        id: nId,
        rPosition: isInc ? rePosition - 1 : rePosition + 1
      });
      await qre.updateQuestionnaireResponse({questionnairesResponse});
    },
    onReOrderResponse = async (reOrderData: reOrderProps) => {
      const {rPosition, value} = reOrderData;
      if (!rPosition) {
        return;
      }
      for (let i = 0; i < value.length; i++) {
        if (reOrderData.isInc) {
          if (value[i].rPosition === rPosition) {
            // (cId) current id of the questionnaire response position
            const cId = value[i].qId;
            // (nId) Next id of the questionnaire response position
            const nId = value[i + 1].qId;
            const rePosition = value[i + 1].rPosition;
            const reUpdateResponse = await reOrderResponsePayload(cId, nId, rePosition, reOrderData).then(fetchQ);
            const updateReOrderVal = va(reUpdateResponse) ? reUpdateResponse.filter((i: T) => i.quesId === getEditQuesId) : [];
            setEQA(updateReOrderVal[0]);
          }
        } else if (value[i].rPosition === rPosition) {
          const cId = value[i].qId;
          const nId = value[i - 1].qId;
          const rePosition = value[i - 1].rPosition;
          const reUpdateResponse = await reOrderResponsePayload(cId, nId, rePosition, reOrderData).then(fetchQ);
          const updateReOrderVal = va(reUpdateResponse) ? reUpdateResponse.filter((i: T) => i.quesId === getEditQuesId) : [];
          setEQA(updateReOrderVal[0]);
        }
      }
    },
    updateQA = async (questionnaires: T) => {
      const editTypeIds = !editTypeId ? 1 : 2
      const payload = {editTypeIds, questionnaires};
      await qre.updateQuestionnaire(payload);
      await refreshQAList();
      await cancelEdit();
    },
    cancelEdit = async () => {
      const
        index = items.findIndex(i => i.sequence === editingQnItem.sequence),
        eQA: Partial<QuestionnaireItem> = items[index];

      eQA.answer = editingQnItem.answer;
      items[index] = eQA;
      await onTabChange(QATab.Questions);
      loadQuestionnaire(items);
      setEdit(initFalsy);
      setEditType(initFalsy);
      setQA({});
      setResponses([]);

      onClear()
        .then(fetchQ)
        .then((data) => loadQuestionnaire(data as QuestionnaireItem[]));
    },
    onSave = async () => {
      await sleep(50);
      const {answer, question} = droppedQnItem;
      const sequence = aMaxVal((items || []).map(i => i.sequence || 0)) + 1;
      const projectId = !isAdd ? piz(id) : piz(selProjectId);
      const quesId = question?.id;
      const questionnaires = va(answer) ?
        answer?.map(qa => ({ansId: qa.id, projectId, quesId, sequence, type: selTypeId, rPosition: qa.rPosition})) :
        [{projectId, quesId, sequence, type: selTypeId}];
      const payload = {questionnaires};
      await qre.addQuestionnaire(payload, projectId)
        .then((data: QreProps[]) => {
          onClear();
          refreshQAList();
          loadQuestionnaire(data as QuestionnaireItem[]);
        });
      if (isAdd) {
        hist.push(`${isOps ? Path.OPQuestionnaireList : Path.PMQuestionnaireList}/${projectId}`);
      }
    },
    onTypeChange = (value: SelectValue) => {
      setShowSave(initFalsy);
      setAnsType(value);
      if (value === AnswerType.Scripted || value === AnswerType['Open Ended']) {
        setShowSave(!initFalsy);
        setResponses([]);
        setQA({...droppedQnItem, answer: []});
      }
    },
    onDrop = async (item: QAT) => {
      setQnDropValue('');
      const {type, id, title} = item;
      if (type === ItemTypes.QUESTION) {
        const que = {id, title};
        const qa = {question: que as Question}
        const alreadyHas = items?.find(a => a.question?.title === title);
        if (alreadyHas) {
          const message = `${title} - This Question already exists in Questionnaire`;
          setQnDropValue(message);
          alert.setAlert({message, statusCode: 0});
          return;
        }
        setQuestions(que);
        setQA(qa);
        await onTabChange(QATab.Answers);
        setStartFresh(!initFalsy);
      } else if (type === ItemTypes.RESPONSE) {
        const rPosition = droppedAnswer.length === 0 ? 1 : droppedAnswer.length + 1;
        const selItem = {id, title, rPosition};
        const answers = droppedAnswer.concat([selItem]);
        setResponses(answers);
        setQA({...droppedQnItem, answer: answers as Answer[]});
        setShowSave(!initFalsy);
      }
      setSearchValue('');
      document.getElementById(qsBuilderBottom)?.scrollIntoView({block: 'end', behavior: 'smooth'});
    },
    onEditDrop = async (item: QAT) => {
      const {id, title} = item;
      const selItem = {id, title};
      const answers = droppedAnswer?.concat([selItem]);
      setResponses(answers as Partial<Answer>[]);
      setEQA({...editingQnItem, answer: answers as Answer[]});
    },
    fetchQ = async () => {
      if (id && !isAdd) {
        return await qre.fetchQuestionnaire(id);
      } else {
        await qre.fetchProjectAvailable()
          .then(({available}: QAProjects) => setProjects(available));
      }
    },
    cancelButtonProps = {
      content: 'Start Over',
      className: 'float-right',
      icon: 'angle double left',
      onClick: onClear,
      type: 'button'
    },
    saveButtonProps = {
      content: 'Save',
      className: 'float-right ml10',
      disabled: (isAdd && !selProjectId),
      icon: 'save',
      onClick: onSave,
      primary: !initFalsy,
      type: 'button'
    },
    selectTypeProps: SelectProps = {
      clearable: !initFalsy,
      className: 'w20',
      hasFirstEmptyOpt: !initFalsy,
      label: 'Select Type',
      name: 'type',
      onChange: onTypeChange,
      options: enumAsAO(AnswerType) || [],
      placeholder: 'Select Type',
      value: selTypeId
    },
    selectProjProps: SelectProps = {
      clearable: !initFalsy,
      hasFirstEmptyOpt: !initFalsy,
      name: 'projects',
      options: proOpts || [],
      label: 'Select Project',
      placeholder: 'Select Project'
    },
    qaListProps = {
      onTabChange,
      searchValue,
      setSearchValue,
      tabIndex: tabQAIndex,
      isQsBuilder: !initFalsy
    },
    droppedQsBProps = {
      isAdd: !initFalsy,
      isBuilder: !initFalsy,
      items: [droppedQnItem],
      onDel: onClear
    },
    editQsBProps = {
      cancelEdit,
      editTypeId,
      isAdd: !initFalsy,
      isBuilder: !initFalsy,
      isEdit,
      items: [editingQnItem],
      onAnswerDel,
      onDel: onClear,
      onEditAnswer: onEdit,
      reOrderResponse: onReOrderResponse,
      setEditType,
      updateQA
    },
    normalQsBProps = {
      isBuilder: !initFalsy,
      items,
      onChange: onChangeSequence,
      onDel,
      onEditAnswer: onEdit
    },
    firstRun = () => {
      setLoading(!initFalsy);
      fetchQ()
        .then((data: QreProps[]) => {
          if (va(data)) {
            loadQuestionnaire(data as QuestionnaireItem[]);
          } else if (!isAdd) {
            setLoading(!initFalsy);
            setIsAssignedQues(!initFalsy);
          }
          setLoading(initFalsy);
        }).catch((err: T) => {
          setLoading(!initFalsy);
          console.error(err);
        });

      return () => {
        qre.flushQuestionnaire();
        qre.flushQuestionnaires();
        qre.flushQsLogic();
      };
    };

  React.useEffect(firstRun, []);

  return (
    <Grid>
      {
        loading &&
        <Loader />
      }

      <DndWrapper>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">Questionnaire Builder</h1>
            <Button {...goBackProps} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column computer={6} mobile={16} tablet={16}>
            <Card fluid={!initFalsy}>
              <Card.Header>
                <h2 className="subTitle">Question Bank</h2>
              </Card.Header>
              <Card.Content>
                {
                  isAdd &&
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <Select {...selectProjProps} onChange={(value: SelectValue) => setProjectId(value)} />
                      </Grid.Column>
                      {
                        !selProjectId &&
                        <Grid.Column width={16}>
                          <h3 className="w100 subHeaderInner">
                            <span>Select a Project to Start Building Questionnaire</span>
                          </h3>
                        </Grid.Column>
                      }
                    </Grid.Row>
                  </Grid>
                }
                <div className="questionnaireBuilder">
                  <QAList {...qaListProps} />
                </div>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column computer={10} mobile={16} tablet={16}>
            <Card fluid={!initFalsy}>
              <Card.Header>
                <h2 className="subTitle">Questionnaire</h2>
              </Card.Header>
              {!isAssignedQues && <Card.Content>
                <Grid>
                  <Grid.Row>
                    <Grid.Column className="mb10" width={16}>
                      <List horizontal className="responseTypeAbbreviation">
                        {
                          enumAsAO(MiniType).map((i, idx) => {
                            return (
                              <List.Item key={idx}><b>{i.text}</b> - {AnswerType[i.value]}</List.Item>
                            )
                          })
                        }
                      </List>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column className="questionnaireList mb10" width={16}>
                      {
                        va(items) && !isEdit &&
                        <QATree {...normalQsBProps} />
                      }
                      {
                        vo(droppedQnItem) && !isEdit &&
                        <>
                          <QATree {...droppedQsBProps} />
                          <div id={qsBuilderBottom} />
                        </>
                      }
                      {
                        vo(editingQnItem) && isEdit &&
                        <QATree {...editQsBProps} />
                      }
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      {
                        !vo(droppedQuestion) && !isEdit &&
                        <Droppable
                          accept={ItemTypes.QUESTION} dropMsg={qnDropValue || 'Add New Question'} onComplete={onDrop}
                        />
                      }
                      {
                        vo(droppedQuestion) && selTypeId && (selTypeId === AnswerType.Single || selTypeId === AnswerType.Multiple) && !isEdit &&
                        <Droppable accept={ItemTypes.RESPONSE} onComplete={onDrop} />
                      }
                      {
                        isEdit && vo(editingQnItem) && editingQnItem.type !== AnswerType.Scripted &&
                        <Droppable accept={ItemTypes.RESPONSE} onComplete={onEditDrop} />
                      }
                    </Grid.Column>
                  </Grid.Row>
                  {
                    (showSave || startFresh) && !isEdit &&
                    <Grid.Row className="mt10">
                      <Grid.Column mobile={16} tablet={10} computer={10}>
                        <Select {...selectTypeProps} />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        {
                          showSave &&
                          <Button {...saveButtonProps} />
                        }
                        {
                          startFresh &&
                          <Button {...cancelButtonProps} />
                        }
                      </Grid.Column>
                    </Grid.Row>
                  }
                  {
                    va(items) && !(showSave || startFresh) && !isEdit &&
                    <Grid.Row className="mt15">
                      <Grid.Column width={16}>
                        {
                          buttons.map((bp, key) => (
                            <Button key={key} {...bp} />
                          ))
                        }
                      </Grid.Column>
                    </Grid.Row>
                  }
                </Grid>
              </Card.Content>}
            </Card>
          </Grid.Column>
        </Grid.Row>
      </DndWrapper>
    </Grid>
  );
};

const MapProps = (state: StoreT) => {
  return {
    projects: state.projects,
    questionnaire: state.questionnaire,
    user: state.auth.user
  };
};

export default connect(MapProps)(QuestionnaireBuilderMain);
