import {
  ActionType,
  ApiEngine,
  CleanResponse,
  PutSamplingPriorities,
  Sampling,
  SamplingAdd,
  ST,
  StoreT,
  T
} from '../../model';
import {setAlert, setSuccess} from './alert';
import {SamplingParams} from '../../constants';


const apiPath = 'sampling';

/**
 * Flush Sampling
 */
export const flushSampling = () => async (dispatch: Function) => {
  try {
    dispatch(flushSamplingProviders());
    dispatch({type: ActionType.PM_SAMPLING_FLUSH, payload: []});
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Flush Sampling Providers
 */
export const flushSamplingProviders = () => async (dispatch: Function) => {
  try {
    dispatch({type: ActionType.PM_DEPARTMENTS_FLUSH, payload: []});
    dispatch({type: ActionType.PM_PROVIDERS_FLUSH, payload: []});
    dispatch({type: ActionType.PM_SITES_FLUSH, payload: []});
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Sampling_Params
 * @param projectId
 * @param type
 */
export const fetchSamplingParams = (projectId: number, type: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const query = {projectId, type};
    const {data}: CleanResponse = await api.get(apiPath, null, query);
    const {samplings, samplingProviders} = data;

    dispatch({type: ActionType.PM_SAMPLING_FETCH, payload: samplings});
    const samplingType = type !== -1 ? type : samplings[0]?.typeId;
    _dispatchSampProviders(samplingType, samplingProviders, dispatch);
    return data;
  } catch (err) {
    throw err;
  }
};

/**
 * Add new sampling
 * @param data
 * @param type
 */
export const saveSampling = (data: SamplingAdd, type: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const res: CleanResponse = await api.post(apiPath, data);
    const {samplings, samplingProviders} = res.data;
    dispatch({type: ActionType.PM_SAMPLING_ADD, payload: samplings});
    _dispatchSampProviders(type, samplingProviders, dispatch);
    dispatch(setSuccess(res.message));
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Update Sampling Params
 * @param data
 * @param type
 */
export const putSampling = (data: PutSamplingPriorities, type: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const res: CleanResponse = await api.put(apiPath, data);
    const {samplings, samplingProviders} = res.data;
    dispatch({type: ActionType.PM_SAMPLING_UPDATE, payload: samplings});
    _dispatchSampProviders(type, samplingProviders, dispatch);
    dispatch(setSuccess(res.message));
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Update Sampling Providers Required Survey Numbers
 * @param data
 */
export const updateSamplingProviders = (data: Sampling) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const res: CleanResponse = await api.put(`${apiPath}/providers`, data);
    const {samplings} = res.data;
    dispatch({type: ActionType.PM_SAMPLING_UPDATE, payload: samplings});
    dispatch(setSuccess(res.message));
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Additional Sampling Operations - Max Calls and Look Back Days & Final Counts
 * @param data
 */
export const additionalSampOpn = (data: T) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    await dispatch(flushSamplingProviders());
    const res: CleanResponse = await api.post(`${apiPath}/operations`, data);
    dispatch({type: ActionType.PM_SAMPLING_ADDITIONAL_OPN});
    dispatch(setSuccess(res.message));
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Sort Sampling Providers Columns
 * @param projectId
 * @param type
 * @param search
 * @param orderBy
 * @param orderDir
 * @param skip
 * @param limit
 */
export const fetchSamplingProviders = (projectId: number, type: number, search: string, orderBy: string, orderDir: ST, skip?: number, limit?: number) => async (dispatch: Function, _store: () => StoreT, api: ApiEngine) => {
  try {
    await dispatch(flushSamplingProviders());
    if (orderBy === 'surveyRemainingP') {
      orderBy = 'surveyRemaining'
    }
    const query = {limit, orderBy, orderDir, projectId, search, skip, type};
    const {data}: CleanResponse = await api.get(`${apiPath}/providers`, null, query);
    _dispatchSampProviders(type, data, dispatch);
    return data;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Remove Sampling Params
 * @param id
 * @param projectId
 * @param type
 * @param onlyOne
 */
export const removeSampling = (id: number, projectId: number, type: number, onlyOne: boolean) => async (dispatch: Function, _s: Function, api: ApiEngine) => {
  try {
    if (onlyOne) {
      const message = 'There should be at least one Sampling parameter';
      dispatch(setAlert({statusCode: 0, message}));
      return;
    }

    const data = {id, projectId};
    const res: CleanResponse = await api.delete(apiPath, data);
    const {samplings, samplingProviders} = res.data;
    dispatch({type: ActionType.PM_SAMPLING_DEL, payload: samplings});
    _dispatchSampProviders(type, samplingProviders, dispatch);
    dispatch(setSuccess(res.message));
    return;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

const _dispatchSampProviders = (type: number, data: T[], dispatch: Function) => {
  switch (type) {
    case SamplingParams.Department:
      dispatch({type: ActionType.PM_DEPARTMENTS, payload: data});
      break;
    case SamplingParams.Provider:
      dispatch({type: ActionType.PM_PROVIDERS, payload: data});
      break;
    case SamplingParams.Site:
      dispatch({type: ActionType.PM_SITES, payload: data});
      break;
  }
}
