import React from 'react';
import {JsEvents} from '../../constants';

const useAutoLogout = (startTime: number, isSpecialCase?: boolean) => {
  const [sessionTimer, setSessionTimer] = React.useState(startTime);
  const allEvents = [
    JsEvents.Click,
    JsEvents.KeyPress,
    JsEvents.Load,
    JsEvents.MouseDown,
    JsEvents.MouseMove,
    JsEvents.MouseUp,
    JsEvents.Scroll,
    JsEvents.Wheel
  ];

  const sessionTimerEff = () => {
    const myIntervalCB = () => {
      if (sessionTimer > 0) {
        const val = sessionTimer - 1;
        setSessionTimer(val);
      }
    };
    const myIntervalTimeout = 1000;
    const myInterval = setInterval(myIntervalCB, myIntervalTimeout);
    const resetTimeout = () => {
      setSessionTimer(startTime);
    };
    const events = !isSpecialCase ? allEvents : [JsEvents.Click];
    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    return () => {
      clearInterval(myInterval);
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  };

  React.useEffect(sessionTimerEff);

  return sessionTimer;
};

export default useAutoLogout;
