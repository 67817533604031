import React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {AutoLogout, useRefreshToken} from './AutoLogout';
import {Menu} from '../components/Inputs';
import {Path} from '../routes';
import {Account, RC, StoreT} from '../model';
import {useActions} from '../redux/actions';
import * as authActions from '../redux/actions/auth';
import * as userAccActions from '../redux/actions/users';
import {joinAsFullName, checkIfSurCallPath} from '../utils/common';
import {sleep, vo} from '../utils/objectUtils';
import {
  initFalsy,
  ON_NORMAL_SHOW_ALERT_IN_TIME,
  ON_NORMAL_USER_TIME,
  ON_SURVEY_SHOW_ALERT_IN_TIME,
  ON_SURVEY_WAIT_TIME
} from '../constants';

type H = { expanded?: boolean | undefined }

const Header: React.FC<H> = (props: H & RC) => {
  useRefreshToken();
  const authAction = useActions(authActions);
  const user: Partial<Account> = useSelector((state: StoreT) => state.auth.user) || {};
  const hist = useHistory();
  const [loggingOut, setLoggingOut] = React.useState(initFalsy);
  const loc = hist.location;
  const isSurCallPath = checkIfSurCallPath(loc.pathname);
  const isProfile = loc.pathname === Path.MyProfile;
  const userAction = useActions(userAccActions);
  const onLogout = async () => {
    setLoggingOut(!initFalsy);
    await sleep(100);
    await authAction.LogOut();
  };
  const renderLink = async () => {
    await userAction.flushUserInfo();
    const path = Path.MyProfile
    hist.push(path);
  };
  const menuItemLogoutProp = {active: loggingOut, href: undefined, onClick: onLogout};
  const menuItemProfileProp = {active: isProfile, href: undefined, content: 'My Profile', onClick: () => renderLink()}; // to: Path.MyProfile
  const {firstName, lastName, email} = user;
  const autoLogoutProps = {
    shwAlertIn: isSurCallPath ? ON_SURVEY_SHOW_ALERT_IN_TIME : ON_NORMAL_SHOW_ALERT_IN_TIME,
    waitTime: isSurCallPath ? ON_SURVEY_WAIT_TIME : ON_NORMAL_USER_TIME
  }

  return (
    <header className={props.expanded ? 'expanded-class no-print' : 'not-expanded-class no-print'}>
      {
        vo(user) &&
        <Menu>
          <Menu.Menu position="right">
            <Menu.Item>
              <AutoLogout keepVisible={!initFalsy} {...autoLogoutProps} />
            </Menu.Item>
            <Menu.Item>
              <i className="far fa-user mr10" />Hello! &nbsp;
              <strong>{joinAsFullName(firstName, lastName) || email}</strong>
            </Menu.Item>
            <Menu.Item {...menuItemProfileProp} />
            <Menu.Item {...menuItemLogoutProp}>
              Logout
              {
                loggingOut &&
                <i className="ml5 fas fa-spinner fa-spin float-right" />
              }
              {
                !loggingOut &&
                <i className="ml5 fas fa-sign-out-alt float-right" />
              }
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      }
    </header>
  );
};

export default Header;
