import React from 'react';
import {useHistory} from 'react-router-dom';
import {useFormik} from 'formik';
import {useActions} from '../../redux/actions';
import {LoginBG} from '../index';
import {Button, Form, Grid, TextBox} from '../Inputs';
import {hasError, validate} from '../../utils/validation';
import {sleep} from '../../utils/objectUtils';
import * as accActions from '../../redux/actions/accounts';
import {FormikValues} from '../../model';
import {initFalsy} from '../../constants';


const ForgotPassword: React.FC = () => {
  const accountActions = useActions(accActions);
  const hist = useHistory();

  const requiredFields = ['email'];
  const forgotPassFrm = useFormik({
    initialValues: {email: ''},
    onSubmit: async (values: FormikValues, {resetForm}) => {
      try {
        await accountActions.sendTokenPwdForgot(values.email);
        sleep(50).then(() => hist.push('/'));
      } catch (ex) {
        resetForm();
      }
    },
    validate: (values: FormikValues) => validate(requiredFields, values)
  });

  return (
    <Grid>
      <Grid.Row>
        <LoginBG />
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <div className="loginForm text-center text-white">
            <h2 className="mb-0 f24">FORGOT PASSWORD</h2>
            <Form className="pt-4 pl-0" onSubmit={forgotPassFrm.handleSubmit}>
              <TextBox
                name="email"
                placeholder="Enter Email Address"
                type="email"
                required={!initFalsy}
                error={hasError(forgotPassFrm.errors['email'])}
                onChange={forgotPassFrm.handleChange}
                value={forgotPassFrm.values.email}
                autoComplete="off"
              />
              <Button
                type="submit"
                primary={!initFalsy}
                loading={forgotPassFrm.isSubmitting}
                disabled={forgotPassFrm.isSubmitting}
                content="Submit"
              />
            </Form>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ForgotPassword;
