import React from 'react';
import {QuestionnairesList} from '../../components/Questionnaire';
import {initFalsy} from '../../constants';


const OPQl = () => {
  return (
    <>
      <QuestionnairesList isOps={!initFalsy} />
    </>
  );
};


export default OPQl;
