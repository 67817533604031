import React, {SyntheticEvent} from 'react';
import {ChkProps} from '../../model';
import {Checkbox, Form} from './index';

const ChkBox: React.FC<ChkProps> = (props: ChkProps) => {
  const {errors, labelInLeft, label, values, setFieldValue, error, onChange, ...custom} = props;
  const labelToShow = labelInLeft ? '' : label;
  const handleChange = (e: SyntheticEvent, data: ChkProps) => {
    if (onChange) {
      onChange(data.value);
      e.preventDefault();
    }
  };
  return (
    <Form.Field>
      {
        labelInLeft &&
        <label>{label}</label>
      }
      <Checkbox label={labelToShow} error={error} {...custom} onChange={handleChange} />
    </Form.Field>
  );
};

export default ChkBox;
