import React from 'react';
import {CommentCleaning} from '../../components/CommentCleaner';


const CommentCleaningMaster = () => {
  return (
    <CommentCleaning />
  );
};

export default CommentCleaningMaster;
