import React from 'react';
import {Button, Card, Form, FormFieldGroup, FormFieldType, Grid, SemanticWIDTHS} from '../Inputs';
import {ButtonProps, FormFieldProps, FormProps, MiniAccount, ProjectsList, SelectValue} from '../../model';
import {initFalsy, SurCallResponse} from '../../constants';
import {enumAsAO, va} from '../../utils/arrayUtils';
import isMobileDevice from '../../utils/deviceUtils';
import { joinAsFullName } from '../../utils/common';

interface Props extends FormProps {
  projects: ProjectsList;
  surveyors: Array<MiniAccount>;
}


type cols = { computer: SemanticWIDTHS, tablet: SemanticWIDTHS }
type FFPs = (FormFieldProps & { cols: cols });

const Filters: React.FC<Props> = (props) => {
  const
    surveyCompText = 'Survey Complete',
    {surveyors, handleSubmit, isSubmitting, projects, resetForm, setFieldValue, values} = props,
    initDt = '',
    [selProjectId, setProjectId] = React.useState(),
    [selSurveyorId, setSurveyorId] = React.useState(),
    onClear = (isStart: boolean) => {
      isStart ? setFieldValue('startDate', initDt) : setFieldValue('endDate', initDt);
    },
    csOpts = enumAsAO(SurCallResponse),
    proOpts = projects?.rows.map(p => ({'text': p.name, 'value': p.id})),
    surOpts = va(surveyors) ? surveyors.map((i: MiniAccount) => ({
      text: `${joinAsFullName(i?.firstName, i?.lastName)} ${i?.email}`,
      value: i?.id
    })) : [],
    agreeSurveyIdx = csOpts.findIndex(i => i.value === SurCallResponse['Agreed to Survey']),
    filterFields: FFPs[] = [
      {
        cols: {computer: 8, tablet: 8},
        defaultValue: selSurveyorId,
        fluid: initFalsy,
        label: 'Surveyor',
        name: 'surveyorId',
        onChange: (value: SelectValue) => setSurveyorId(value),
        options: surOpts,
        placeholder: 'Select Surveyor',
        type: FormFieldType.Select
      },
      {
        cols: {computer: 8, tablet: 8},
        defaultValue: selProjectId,
        fluid: initFalsy,
        label: 'Project',
        name: 'projectId',
        onChange: (value: SelectValue) => setProjectId(value),
        options: proOpts,
        placeholder: 'Select Project',
        type: FormFieldType.Select
      },
      {
        cols: {computer: 3, tablet: 6},
        label: 'From',
        name: 'startDate',
        onClear: () => onClear(!initFalsy),
        type: FormFieldType.DatePicker
      },
      {
        cols: {computer: 3, tablet: 6},
        className: 'float-left',
        label: 'To',
        name: 'endDate',
        onClear: () => onClear(initFalsy),
        type: FormFieldType.DatePicker
      },
      {
        className: 'float-right float-left mt30',
        cols: {computer: 3, tablet: 4},
        label: 'Unresolved',
        name: 'status',
        type: FormFieldType.CheckBox,
        value: values.status
      }
    ],
    submitButton = {
      className: 'mt10',
      content: 'Filter',
      disabled: isSubmitting,
      loading: isSubmitting,
      primary: !initFalsy,
      type: 'submit'
    },
    cancelButton = {
      content: 'Reset',
      className: `mt10 ${isMobileDevice ? '' : 'ml10'}`,
      onClick: resetForm,
      secondary: !initFalsy
    },
    buttons = [submitButton, cancelButton];

  if (va(csOpts) && agreeSurveyIdx > -1) {
    csOpts.splice(agreeSurveyIdx, 1, {...csOpts[agreeSurveyIdx], text: surveyCompText});
  }

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Card>
          <Card.Content>
            <Form className="filters" onSubmit={handleSubmit}>
              <Grid>
                <Grid.Row>
                  {
                    filterFields.map(({cols, ...f}, k) => (
                      <Grid.Column key={k} mobile={16} {...cols}>
                        <FormFieldGroup fields={f} />
                      </Grid.Column>
                    ))
                  }
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column computer={16} mobile={8}>
                    {
                      buttons.map((btProps: ButtonProps, idx) => (
                        <Button key={idx} {...btProps} />
                      ))
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Filters;
