import React from 'react';
import {useParams} from 'react-router';
import {
  BE,
  BN,
  ButtonProps,
  DepartmentList,
  Project,
  ProjectLanguageList,
  ProviderList,
  PutLanguageSampling,
  PutSamplingPriorities,
  Sampling,
  SamplingAdd,
  SearchProps,
  SelectValue,
  SiteList,
  ST,
  SU,
  T
} from '../../model';
import {DragNDrop} from '../../components/DnD';
import {Button, Card, ChkBox, Confirm, Grid, Icon, Model} from '../../components/Inputs';
import {ProjectName} from '../../components/Projects';
import {AdditionalOperations, FC as FinalCount, LSP, Providers} from '../../components/SamplingParams';
import {Loader} from '../../components';
import {CONFIRM_MESSAGES, initFalsy, SamplingParams, StatusCode} from '../../constants';
import {useActions} from '../../redux/actions';
import * as languageActions from '../../redux/actions/languages';
import * as projectActions from '../../redux/actions/projects';
import * as sam from '../../redux/actions/sampling'
import {aMaxVal, enumAsArray, sum, va} from '../../utils/arrayUtils';
import {enumVal, sleep, vo} from '../../utils/objectUtils';
import {mapSelectOption} from '../../utils/common';
import {piz} from '../../utils/numUtils';
import {connect} from 'react-redux';


type ISA = { lookBack: number; maxCalls: number };
type SamNu = Sampling[] | null;
type RPT = (typeId?: number) => Promise<T>;

interface SPReduxProps {
  departments: DepartmentList;
  languages: ProjectLanguageList[];
  projectInfo: Project;
  providers: ProviderList;
  samplingParams: Sampling[];
  sites: SiteList;
}

const _Sampling: React.FC<SPReduxProps> = (props: SPReduxProps) => {
  const {departments, projectInfo, providers, samplingParams, sites} = props;
  const initSampling = -1;
  const langSampleId = 999;
  const initSamAdditional: ISA = {lookBack: 0, maxCalls: 0};
  const initSort: { column: string; direction: ST } = {column: 'name', direction: 'ASC'};
  const {id}: T = useParams();
  const samA = useActions(sam);
  const lang = useActions(languageActions);
  const projectA = useActions(projectActions);

  const [addAvailSamp, setAvailSamp]: [string[], Function] = React.useState([] as Array<string>);
  const [changedSamp, setChangedSamp]: [SamNu, Function] = React.useState(null);
  const [deleting, isDeleting] = React.useState(initFalsy);
  const [delOpen, showDelConfirm]: [BN, Function] = React.useState(initFalsy);
  const [isEditReq, editRequired] = React.useState(initFalsy as BN);
  const [isUpdateReady, setUpdateReady] = React.useState(initFalsy);
  const [langUpdating, isLangUpdating] = React.useState(initFalsy);
  const [loader, isProceeding] = React.useState(initFalsy);
  const [loading, isLoading] = React.useState(initFalsy);
  const [mInitializer, setModalInitializer] = React.useState(initFalsy);
  const [refreshing, setRefreshing] = React.useState(initFalsy);
  const [samAdditional, setSamAddChange] = React.useState(initSamAdditional);
  const [samAdditionalUpdating, isSamAdditionalUpdating] = React.useState(initFalsy);
  const [samRequiredUpdating, isSamRequiredUpdating] = React.useState(initFalsy);
  const [searchVal, onSearch]: [SU, Function] = React.useState();
  const [sortVars, setSortVars] = React.useState(initSort);
  const [surveyRequired, setSurveyRequired]: [BE, Function] = React.useState('');
  const [totals, setTotals] = React.useState([0] as T[]);
  const [type, setType] = React.useState(initSampling);
  const [updating, isUpdating] = React.useState(initFalsy);
  const [isAssignedQues, setIsAssignedQues] = React.useState(initFalsy);

  const languagesList = props.languages.map(({projectLanguage, name}) => {
    const {eligibleEnc, encounters, lastEncounter, remaining, samplingId, surveys} = projectLanguage || {};
    return {
      name,
      surveys,
      surveyRemaining: remaining,
      recentEncounter: lastEncounter,
      eligibleEnc,
      encounters,
      samplingId
    }
  });
  const fetchSamProviders = async (search: SU = searchVal, typeId = type, sortOn = 'name', sortBy = 'ASC', skip = 0, limit = 50) => {
    if (typeId === initSampling) {
      return;
    }
    if (typeId === SamplingParams.Language) {
      return await lang.fetchProjectLanguages(id);
    }
    const resp = await samA.fetchSamplingProviders(piz(id), typeId, search, sortOn, sortBy, skip, limit);
    calcTot(resp.requiredtotal);
    return resp;
  };
  const calcTot = (arr: T[], isLang: boolean = initFalsy) => {
    if (!va(arr)) {
      return;
    }
    setRefreshing(!initFalsy);
    const bolder = (n: number) => (<b>{n}</b>);
    const tot = {};
    if (!isLang) {
      Object.assign(tot, {
        name: '',
        surveyRequired: bolder(piz(arr.map(i => {
          const isSurveyNum = Math.sign(i.surveyRequired);
          return isSurveyNum !== -1 ? piz(i.surveyRequired) : 0;
        }))),
        surveys: bolder(piz(arr.map(a => a.surveys))),
        surveyRemaining: bolder(piz(arr.map(a => {
          const isSurveyNum = Math.sign(a.surveyRemaining);
          return isSurveyNum !== -1 ? piz(a.surveyRemaining) : 0;
        }))),
        percentRemaining: '',
        recentEncounter: '',
        eligibleEnc: bolder(piz(arr.map(a => a.eligibleEnc))),
        encounters: bolder(piz(arr.map(a => a.encounters)))
      });
    } else {
      Object.assign(tot, {
        name: '',
        surveys: bolder(sum(arr.map(a => a.surveys))),
        surveyRemaining: bolder(sum(arr.map(a => {
          const isSurveyNum = Math.sign(a.surveyRemaining);
          return isSurveyNum !== -1 ? piz(a.surveyRemaining) : 0;
        }))),
        recentEncounter: '',
        eligibleEnc: bolder(sum(arr.map(a => a.eligibleEnc))),
        encounters: bolder(sum(arr.map(a => a.encounters)))
      });
    }
    setTotals([tot]);
    setRefreshing(initFalsy);
  };
  const refreshProviders: RPT = async (typeId: number = type) => {
    setRefreshing(!initFalsy);
    const data = await fetchSamProviders(searchVal, typeId, sortVars.column, sortVars.direction);
    if (data) {
      calcTot(data.requiredtotal);
    }
    setRefreshing(initFalsy);
    return data;
  };
  const onFinalCounts = async () => {
    await samA.additionalSampOpn({...samAdditional, projectId: id, isFinalCount: !initFalsy});
    setTotals([]);
    onProAction(initSampling);
  };
  const onProceed = async () => {
    isProceeding(!initFalsy);

    const samplingPayload = addAvailSamp.map((curSelected, indexPriority) => {
      const hasSamplings = va(samplingParams);
      return ({
        typeId: enumVal(SamplingParams, curSelected),
        priority: hasSamplings ? aMaxVal(samplingParams.map(s => s.priority)) + indexPriority + 1 : indexPriority
      });
    });
    const samPayload: SamplingAdd = {
      projectId: piz(id as string),
      samplingPayload
    };
    await samA.saveSampling(samPayload, type);

    isProceeding(initFalsy);
    setModalInitializer(initFalsy);
    setType(initSampling);
    setAvailSamp([]);
  };
  const onDelete = async (sampId: number) => {
    isDeleting(!initFalsy);
    isProceeding(!initFalsy);
    await samA.removeSampling(sampId, id, type, samplingParams.length === 1);
    isDeleting(initFalsy);
    showDelConfirm(initFalsy);
    isProceeding(initFalsy);
    setType(initSampling);
  };
  const onProAction = async (samplingType: number, _sampProviders: T = {}, deSelect: boolean = initFalsy) => {
    let requiredTotal: T[] = [];
    const data: DepartmentList | ProviderList | SiteList = await refreshProviders(samplingType);
    setType(initSampling);
    switch (samplingType) {
      case SamplingParams.Provider:
        requiredTotal = va(providers.requiredtotal) ? providers.requiredtotal : data.requiredtotal;
        calcTot(requiredTotal);
        setType(SamplingParams.Provider);
        break;
      case SamplingParams.Site:
        requiredTotal = va(sites.requiredtotal) ? sites.requiredtotal : data.requiredtotal;
        calcTot(requiredTotal);
        setType(SamplingParams.Site);
        break;
      case SamplingParams.Department:
        setType(SamplingParams.Department);
        requiredTotal = va(departments.requiredtotal) ? departments.requiredtotal : data.requiredtotal;
        calcTot(requiredTotal);
        break;
      case SamplingParams.Language:
        calcTot(languagesList, !initFalsy);
        setType(SamplingParams.Language);
        break;
    }
    if (!deSelect && type === samplingType) {
      setType(initSampling);
      setSortVars(initSort);
    } else if (deSelect) {
      setType(type);
    }
  };
  const onReqChange = async (psdId: number, newVal: string) => {
    isLoading(!initFalsy);
    const samPayload = {
      id: psdId,
      projectId: piz(id as string),
      surveyRequired: newVal,
      typeId: type
    };
    await samA.updateSamplingProviders(samPayload);
    editRequired(initFalsy);
    isLoading(initFalsy);
  };
  const setOrder = (newOrder: number[]) => {
    const newSamp = newOrder.map((o, p) => {
      const {id, projectId, typeId} = samplingParams.find(s => s.id === o) as Sampling;
      return {id, priority: p, projectId, typeId};
    })
    setChangedSamp(newSamp);
    setUpdateReady(!initFalsy);
  };
  const onLangSamUpdate = async (payload: PutLanguageSampling) => {
    isLangUpdating(!initFalsy);
    await lang.updLangSampling(payload);
    isLangUpdating(initFalsy);
  };
  const onLangSort = async (column: string, direction: ST) => {
    setSortVars({column, direction});
    await lang.fetchProjectLanguages(id);
  };
  const onUpdate = async () => {
    isUpdating(!initFalsy);

    const payload: PutSamplingPriorities = {
      samplingPriorities: (changedSamp || []).map((a: T) => {
        const {typeId, ...rest} = a;
        return rest;
      })
    };
    await samA.putSampling(payload, type);

    setChangedSamp(null);
    setUpdateReady(initFalsy);
    isUpdating(initFalsy);
  };
  const onSamAdditionalUpdate = async () => {
    isSamAdditionalUpdating(!initFalsy);
    await samA.additionalSampOpn({...samAdditional, projectId: id});
    isSamAdditionalUpdating(initFalsy);
  };
  const onSurveyRequiredUpdate = async () => {
    isSamRequiredUpdating(!initFalsy);
    isLoading(!initFalsy);
    const tmpType = type;
    setType(initSampling);
    const samPayload = {
      id: 0,
      projectId: piz(id as string),
      surveyRequired,
      typeId: type,
      updateAll: !initFalsy
    };
    await samA.updateSamplingProviders(samPayload);
    await refreshProviders(type);
    isSamRequiredUpdating(initFalsy);
    setSurveyRequired('');
    setType(tmpType);
    isLoading(initFalsy);
  };
  const onSort = async (column: string, direction: ST) => {
    setRefreshing(!initFalsy);
    setSortVars({column, direction});
    setRefreshing(initFalsy);
  };
  const onSearchChange = async (data: SearchProps) => {
    if (data.value && data.value.length <= 1) {
      onSearch(null);
      return sleep(0);
    }

    setRefreshing(!initFalsy);
    onSearch(data.value);
    setRefreshing(initFalsy);
  };
  const fetchSPs = async (deSelect: boolean = initFalsy) => {
    isLoading(!initFalsy);
    samA.fetchSamplingParams(id, type)
      .then((data: T) => {
        const {samplings, samplingProviders} = data;
        if (va(samplings)) {
          const samplingType = type !== initSampling ? type : samplings[0].typeId;
          onProAction(samplingType, samplingProviders, deSelect);
        }
        return lang.fetchProjectLanguages(id);
      });

    isLoading(initFalsy);
    projectA.getProjectInfo(id)
      .then((p: Project) => {
        if (p.statusCode === StatusCode.Unauthorized) {
          isLoading(!initFalsy);
          setIsAssignedQues(!initFalsy);
        } else {
          setSamAddChange({
            lookBack: p.lookBackDays,
            maxCalls: p.maxCalls
          })
        }
      })
      .catch((err: T) => {
        setIsAssignedQues(!initFalsy);
        console.error(err);
      });
  };
  const firstRun = () => {
    fetchSPs().then();

    return () => {
      lang.flushLanguages();
      projectA.flushProjectInfo();
      samA.flushSampling();
    };
  };

  const updButt = {content: 'Update', primary: !initFalsy};
  const samplingButtons: ButtonProps[] = [
    {
      content: 'Add Priority',
      className: 'mr10 mt15',
      primary: !initFalsy,
      onClick: () => setModalInitializer(!initFalsy)
    },
    {...{}, ...updButt, disabled: !isUpdateReady, loading: updating, onClick: onUpdate}
  ];
  const availableSamplingParams = enumAsArray(SamplingParams, !initFalsy);
  const samplingMethods = mapSelectOption(availableSamplingParams as string[]);
  const samAdditionalUpdateButtProps = {
    ...{},
    ...updButt,
    loading: samAdditionalUpdating,
    onClick: onSamAdditionalUpdate
  };
  const samProvidersBulkUpdateButtProps = {
    ...{},
    ...updButt,
    loading: samRequiredUpdating,
    onClick: onSurveyRequiredUpdate
  };
  const modalActions = (
    <>
      <Button
        content="Proceed"
        icon="angle double right"
        labelPosition="right"
        loading={loader}
        onClick={onProceed}
        primary={!initFalsy}
      />
    </>
  );
  const modalContent = (
    <>
      {
        (va(samplingMethods) ? samplingMethods : []).map((f, i) => {
          if (f.text === SamplingParams[SamplingParams.Language]) {
            return '';
          }
          const tfProps = {
            checked: (
              addAvailSamp.includes(f.value as string) ||
              (va(samplingParams) ? samplingParams : []).map(i => SamplingParams[i.typeId])
                .includes(f.value as string)
            ),
            disabled: ((va(samplingParams) ? samplingParams : [])).map(i => SamplingParams[i.typeId])
              .includes(f.value as string),
            label: f.text,
            name: 'samplingParams',
            onChange: (val: SelectValue) => {
              const set = new Set(addAvailSamp);
              if (set.has(val)) {
                set.delete(val);
              } else {
                set.add(val);
              }
              setAvailSamp(Array.from(set))
            },
            value: f.value
          };
          return (
            <ChkBox className="customCheckGroup" {...tfProps} key={i} />
          );
        })
      }
    </>
  );
  const renderItemDesc = (sampId: number, desc: string) => (
    <>
      <Icon color="grey" name="arrows alternate vertical" />
      {desc}
      <Icon
        className="float-right hand-pointer"
        color="grey"
        name="trash alternate outline"
        onClick={() => showDelConfirm(sampId)}
        title="Delete Sampling Parameter"
      />
    </>
  );
  const getSamplingItems = ((va(changedSamp) ? changedSamp : samplingParams) || []).map(s => ({
    id: s.id as number,
    content: renderItemDesc(s.id as number, SamplingParams[s.typeId])
  }));
  const confirmButton = (
    <Button color="red" content="Yes, Delete" loading={deleting} primary={initFalsy} type="button" />
  );

  const buttonGroups: T[] = [];

  if (va(samplingParams)) {
    samplingParams.forEach(s => {
      buttonGroups.push(
        <Button
          content={SamplingParams[s.typeId]}
          key={`b-${s.typeId}`}
          onClick={() => onProAction(s.typeId)}
          positive={s.typeId === type}
        />
      )
      buttonGroups.push(
        <Button.Or key={`bo-${s.typeId}`} />
      )
    });
  } else if (va(samplingParams) && type === initSampling) {
    onProAction(samplingParams[0].typeId).then();
  }

  if (va(samplingMethods) && samplingMethods.find(i => i.text === SamplingParams[SamplingParams.Language])) {
    samplingMethods.pop();
  }

  buttonGroups.push(
    <Button
      content={SamplingParams[langSampleId]}
      key={langSampleId}
      onClick={() => onProAction(langSampleId)}
      positive={type === langSampleId}
    />
  );

  const samAdditionalFields = [
    {
      label: 'Look Back Days',
      onChange: (e: T) => setSamAddChange({...{}, ...samAdditional, lookBack: e.target.value}),
      type: 'number',
      value: samAdditional.lookBack
    },
    {
      label: 'Max Calls Allowed',
      onChange: (e: T) => setSamAddChange({...{}, ...samAdditional, maxCalls: e.target.value}),
      type: 'number',
      value: samAdditional.maxCalls
    }
  ];
  const providerProps = {
    buttProps: samProvidersBulkUpdateButtProps,
    calcTot: refreshProviders,
    editRequired: editRequired,
    fetchSamProviders: fetchSamProviders,
    isEditReq: isEditReq,
    languages: languagesList,
    onLangSort: onLangSort,
    onReqChange: onReqChange,
    onSearch: onSearchChange,
    onSort: onSort,
    refreshing: refreshing,
    samSwitch: buttonGroups,
    searchVal: searchVal,
    setSR: setSurveyRequired,
    sortVars: sortVars,
    totals: totals,
    type: type,
    updating: samRequiredUpdating,
  };

  React.useEffect(firstRun, []);

  return (
    <Grid>
      {
        loading &&
        <Loader />
      }

      <Grid.Row className="headerTop">
        <Grid.Column width={16}>
          <h1 className="mainTitle">Sampling Parameters&nbsp;
            <ProjectName name={projectInfo.name} isLocked={projectInfo.isLocked} />
          </h1>
          <FinalCount className="float-right" onFinalCounts={onFinalCounts} />
        </Grid.Column>
      </Grid.Row>

      {!isAssignedQues && <Grid.Row stretched>
        <Grid.Column computer={8} tablet={16} mobile={16}>
          <Card fluid={!initFalsy} className="mb20">
            <Card.Header><h2 className="subTitle">Order Priorities</h2></Card.Header>
            <Card.Content>
              <Grid>
                <Grid.Row>
                  <Grid.Column computer={16} tablet={16} mobile={16}>
                    {
                      va(samplingParams) &&
                      <DragNDrop items={getSamplingItems} onComplete={setOrder} />
                    }
                  </Grid.Column>
                  {
                    isUpdateReady &&
                    <Grid.Column computer={16} tablet={16} mobile={16} className="mt20">
                      <h2 className="w100 subHeaderInner mb10">
                        Order is changed but not saved, To Save the changes Press Update. </h2>
                    </Grid.Column>
                  }
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16} className="text-right">
                    {
                      samplingButtons.map((buttonProp, idx) => (
                        <span key={idx}>
                          <Button className="mt15" {...buttonProp} />
                        </span>
                      ))
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {
                mInitializer &&
                <Model
                  initialize={mInitializer}
                  content={modalContent}
                  header={'Add Sampling Parameters'}
                  onCancel={() => setModalInitializer(initFalsy)}
                  actions={modalActions}
                />
              }

              {
                delOpen &&
                <Confirm
                  content={CONFIRM_MESSAGES.SAMPLE_PARAM}
                  header="Delete Sampling Parameter"
                  confirmButton={confirmButton}
                  open={!!delOpen}
                  onCancel={() => showDelConfirm(initFalsy)}
                  onConfirm={() => onDelete(delOpen as unknown as number)}
                />
              }
            </Card.Content>
          </Card>
          {
            vo(samAdditional) &&
            <AdditionalOperations fields={samAdditionalFields} updateButtProps={samAdditionalUpdateButtProps} />
          }
        </Grid.Column>
        <Grid.Column computer={8} tablet={16} mobile={16}>
          <LSP languages={props.languages} updating={langUpdating} onUpdate={onLangSamUpdate} />
        </Grid.Column>
      </Grid.Row>}

      {!isAssignedQues && <Grid.Row>
        <Providers {...providerProps} />
      </Grid.Row>}
    </Grid>
  );
};

const mapStateToProps = (state: SPReduxProps) => {
  return {
    departments: state.departments,
    languages: state.languages,
    projectInfo: state.projectInfo,
    providers: state.providers,
    samplingParams: state.samplingParams,
    sites: state.sites
  };
};

export default connect(mapStateToProps)(_Sampling);
