import React from 'react';
import {Button, Card, Form, FormFieldGroup, FormFieldType, Grid, SemanticWIDTHS} from '../Inputs';
import {ButtonProps, FormFieldProps, FormProps, MiniAccount, ProjectsList, SelectValue} from '../../model';
import {initFalsy, SurCallResponse} from '../../constants';
import {enumAsAO, va} from '../../utils/arrayUtils';
import isMobileDevice from '../../utils/deviceUtils';
import {joinAsFullName} from '../../utils/common';


interface Props extends FormProps {
  exporting: boolean;
  isManager: boolean;
  onReset: Function;
  projects: ProjectsList;
  surveyors: Array<MiniAccount>;
  onCallCSVExport: Function;
}


type cols = { computer: SemanticWIDTHS, tablet: SemanticWIDTHS }
type FFPs = (FormFieldProps & { cols: cols });

const Filters: React.FC<Props> = (props) => {
  const {
    exporting,
    handleSubmit,
    isManager,
    isSubmitting,
    onCallCSVExport,
    projects,
    resetForm,
    setFieldValue,
    surveyors,
    values,
  } = props;
  const initDt = '';
  const [selProjectId, setProjectId] = React.useState();
  const [surveyorId, setSurveyorId] = React.useState();
  const [responseType, setResponseType] = React.useState();
  const onExport = () => onCallCSVExport(values);
  const onReset = () => {
    resetForm();
    props.onReset();
  };
  const onClear = (isStart: boolean) => {
    isStart ? setFieldValue('startDate', initDt) : setFieldValue('endDate', initDt);
  };
  const csOpts = enumAsAO(SurCallResponse);
  const proOpts = projects?.rows.map(p => ({'text': p.name, 'value': p.id}));
  const surveyorOpts = va(surveyors) ? surveyors.map(s => ({
    'text': `${joinAsFullName(s?.firstName, s?.lastName)} ${s?.email}`,
    'value': s?.id
  })) : [];
  const commonSPs = {className: 'float-right'};
  const agreeSurveyIdx = csOpts.findIndex(i => i.value === SurCallResponse['Agreed to Survey']);

  if (va(csOpts) && agreeSurveyIdx > -1) {
    const surveyCompText = 'Survey Complete';
    csOpts.splice(agreeSurveyIdx, 1, {...csOpts[agreeSurveyIdx], text: surveyCompText});
  }

  const filterFields: FFPs[] = [
    {
      className: '',
      cols: {computer: 4, tablet: 8},
      defaultValue: responseType,
      fluid: initFalsy,
      label: 'Call Status',
      name: 'responseType',
      onChange: (value: SelectValue) => setResponseType(value),
      options: csOpts,
      placeholder: 'Select Response',
      type: FormFieldType.Select
    },
    {
      className: '',
      cols: {computer: 4, tablet: 8},
      defaultValue: selProjectId,
      fluid: initFalsy,
      label: 'Project',
      name: 'projectId',
      onChange: (value: SelectValue) => setProjectId(value),
      options: proOpts,
      placeholder: 'Select Project',
      type: FormFieldType.Select
    },
    {
      cols: {computer: 4, tablet: 8},
      label: 'From',
      name: 'startDate',
      onClear: () => onClear(!initFalsy),
      type: FormFieldType.DatePicker
    },
    {
      cols: {computer: 4, tablet: 8},
      className: 'float-left',
      label: 'To',
      name: 'endDate',
      onClear: () => onClear(initFalsy),
      type: FormFieldType.DatePicker
    },
    {
      ...commonSPs,
      className: 'float-left mt30',
      cols: {computer: 4, tablet: 8},
      label: 'Is Complete',
      name: 'isComplete',
      toggle: !initFalsy,
      type: FormFieldType.CheckBox,
      value: values.isComplete
    },
    {
      ...commonSPs,
      className: 'float-left',
      cols: {computer: 4, tablet: 8},
      label: 'Search Phone Number...',
      name: 'searchPhone',
      type: FormFieldType.Text,
      value: values.searchPhone
    },
    {
      className: isManager ? '' : 'invisible',
      cols: {computer: 4, tablet: 8},
      defaultValue: surveyorId,
      fluid: initFalsy,
      label: 'Surveyor',
      name: 'surveyorId',
      onChange: (value: SelectValue) => setSurveyorId(value),
      options: surveyorOpts,
      placeholder: 'Select Surveyor',
      type: FormFieldType.Select
    }
  ];
  const exportButtonProps = {icon: 'file excel', loading: exporting, onClick: onExport, primary: !initFalsy};
  const buttons = [
    {content: 'Filter', className: 'mt10', disabled: isSubmitting, loading: isSubmitting, primary: !initFalsy},
    {content: 'Reset', className: `mt10 ${isMobileDevice ? '' : 'ml10'}`, onClick: onReset, secondary: !initFalsy},
    {content: 'Export', className: `mt10 ${isMobileDevice ? '' : 'ml10'}`, type: 'button', ...exportButtonProps}
  ];

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        <Card>
          <Card.Content>
            <Form className="filters" onSubmit={handleSubmit}>
              <Grid>
                <Grid.Row>
                  {
                    filterFields.map(({cols, ...f}, k) => (
                      <Grid.Column key={k} mobile={16} {...cols}>
                        <FormFieldGroup fields={f} />
                      </Grid.Column>
                    ))
                  }
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column computer={16} mobile={8}>
                    {
                      buttons.map((btProps: ButtonProps, idx) => (
                        <Button key={idx} {...btProps} />
                      ))
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid.Row>
  );
};

export default Filters;
