import React from 'react';
import {Form, Grid, Radio} from './index';
import {ChkProps, NS, RadioProps, SelectValue} from '../../model';


type Opts = { text: string, value: NS, key?: number };
const RadioGroup: React.FC<RadioProps> = (props: RadioProps) => {
  const {error, input, label, labelCss, meta, onChange, options = [], value = '', ...custom} = props;
  const [selectedValue, setSelectedValue]: [SelectValue, Function] = React.useState(value);
  const name = (input && input.name) || (custom && custom.name);
  const hasError = !!error;
  const handleChange = (e: React.SyntheticEvent, data: ChkProps) => {
    const {value} = data;
    // don't change in disabled mode.
    if (custom.disabled) {
      return;
    }

    if (onChange) {
      // find item
      const item = options.find((o: Opts) => o.value === value);
      // return the exact type value
      onChange(item.value);
      e.preventDefault();
    }

    // Set State
    setSelectedValue(value);
  };
  const fr = () => {
    if (props.defaultValue) {
      setSelectedValue(props.defaultValue);
    }
  };

  React.useEffect(fr);

  return (
    <Form.Field error={hasError} className={custom.className || ''}>
      <label className={labelCss}>{label}</label>
      <Grid>
        <Grid.Row>
          {
            options.map((item: RadioProps, key: number) => (
              <Grid.Column key={item.key || key} width={4}>
                <Radio
                  className={item.className}
                  name={name}
                  label={item.text}
                  value={item.value}
                  checked={selectedValue === item.value}
                  onChange={handleChange}
                  {...custom}
                />
              </Grid.Column>
            ))
          }
        </Grid.Row>
      </Grid>
    </Form.Field>
  );
};

export default RadioGroup;
