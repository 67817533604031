import React from 'react';
import {pez} from '../../utils/stringUtils';
import {T} from '../../model';

type proName = {
  className?: string;
  element?: 'div' | 'p' | 'span' | 'td';
  qText?: string;
}

const TextMarkup: React.FC<proName> = (markupProps) => {
  const {element, qText, ...rest} = markupProps;
  const HTML_BREAK = '<br />';
  const addHtmlBreak = (str: string) => {
    if (!str) return '';
    const matcher = /\r\n|\n|\n\n/g;

    return str.replace(matcher, HTML_BREAK);
  };
  const custom: T = rest || {};
  const __elem = pez(element, 'span');
  const __html = addHtmlBreak(pez(qText));

  custom.dangerouslySetInnerHTML = {__html};
  return React.createElement(__elem, custom);
};

export default TextMarkup;
