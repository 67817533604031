import {combineReducers} from 'redux';
import * as accReducer from './accounts';
import * as alertReducer from './alert';
import * as ansReducer from './answers';
import * as appReducer from './nav';
import * as assignmentsReducer from './assignments';
import * as authReducer from './auth';
import * as escalationReducer from './escalations';
import * as callReducer from './calls';
import * as clientReducer from './clients';
import * as commentsReducer from './comments';
import * as languagesReducer from './languages';
import * as projectReducer from './projects';
import * as questionnaireReducer from './questionnaire';
import * as queReducer from './questions';
import * as rostersReducer from './rosters';
import * as serviceRecovReducer from './serviceRecoveries';
import * as surveyorErReducer from './surveyorEarnings';
import * as samplingReducer from './sampling';
import * as usersReducer from './users';
import {RootState} from '../../model';


export default () =>
  combineReducers<RootState>({
    ...accReducer,
    ...alertReducer,
    ...ansReducer,
    ...appReducer,
    ...appReducer,
    ...assignmentsReducer,
    ...authReducer,
    ...escalationReducer,
    ...callReducer,
    ...clientReducer,
    ...commentsReducer,
    ...languagesReducer,
    ...projectReducer,
    ...questionnaireReducer,
    ...queReducer,
    ...rostersReducer,
    ...serviceRecovReducer,
    ...surveyorErReducer,
    ...samplingReducer,
    ...usersReducer
  });
