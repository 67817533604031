import React from 'react';
import {useSelector} from 'react-redux';
import {
  Answer,
  BN,
  ButtonProps,
  FieldArrayRenderProps,
  FormFieldProps,
  FormikValues,
  FormProps,
  LangAnswers,
  LangQuestions,
  Question,
  StoreT
} from '../../model';
import {Button, Confirm, FieldArray, Form, FormField, FormFieldType, FormWrap, Grid} from '../Inputs';
import {MiniLoader} from '../Loader';
import {useActions} from '../../redux/actions';
import * as ansActions from '../../redux/actions/answers';
import * as questActions from '../../redux/actions/questions';
import {va} from '../../utils/arrayUtils';
import isMobileDevice from '../../utils/deviceUtils';
import {answerSchema, questionSchema} from '../../utils/validation';
import {CONFIRM_MESSAGES, initFalsy} from '../../constants';


interface AQFProps extends FormProps {
  isQ: boolean;
  onCancel: Function;
  tabIndex: number;
}

type FFProps = FormFieldProps;
type QuestionAnswerBankProps = {
  edit: Question | Answer;
  isAdd: boolean;
  isQ: boolean;
  loading: boolean;
  onCancel: Function;
  onSave: Function;
  tabIndex: number;
};

enum Tab {
  Questions,
  Answers
}

const AddQAForm = ({onCancel, handleSubmit, isSubmitting, isQ, resetForm, values}: AQFProps) => {
  const
    ansText = 'ansText',
    quesText = 'quesText',
    label = isQ ? 'Question Label' : 'Answer Label',
    labelText = isQ ? 'Question Text' : 'Answer Text',
    fieldArrName = isQ ? 'langQuestions' : 'langAnswers',
    [delId, setDelId]: [BN, Function] = React.useState(initFalsy),
    [isDeleting, setIsDeleting] = React.useState(initFalsy),
    ans = useActions(ansActions),
    que = useActions(questActions),
    languages = useSelector((st: StoreT) => st.languages),
    options = languages.map(l => ({text: l.name, value: l.id})),
    cancel = () => {
      resetForm();
      onCancel();
    },
    catF = {label: 'Category', name: 'category', type: FormFieldType.Text},
    titF = {label, name: 'title', type: FormFieldType.Text},
    langF = {label: 'Language', name: 'langId', options, placeholder: 'Select Languages', type: FormFieldType.DropDown},
    formJSON: FFProps[] = isQ ? [titF, catF] : [titF],
    formButtons: ButtonProps[] = [
      {content: 'Save', disabled: isSubmitting, loading: isSubmitting, primary: !initFalsy, type: 'submit'},
      {className: 'ml10', content: 'Cancel', onClick: cancel, type: 'button'}
    ],
    formFields: FFProps[] = [
      langF,
      {label: labelText, name: isQ ? quesText : ansText, type: FormFieldType.TextArea},
    ],
    confirmButtonProps = {
      className: 'red',
      content: 'Yes, Delete',
      loading: isDeleting,
      primary: initFalsy,
      type: 'button',
    },
    delConfirmProps = {
      content: CONFIRM_MESSAGES.QUESTION,
      header: 'Delete Question',
      confirmButton: (<Button {...confirmButtonProps} />),
      open: !!delId,
      onCancel: () => setDelId(initFalsy),
      onConfirm: async () => {
        setIsDeleting(!initFalsy);
        if (isQ) {
          que.removeLangQuestion(delId);
        } else {
          ans.removeLangAnswer(delId);
        }
        setIsDeleting(initFalsy);
        setDelId(initFalsy);
      },
    },
    renderQALangSampling = (_fps: FieldArrayRenderProps) => {
      const
        cb = {type: 'button'},
        addNewButtProps = {
          ...cb,
          className: 'float-right',
          primary: !initFalsy,
          content: 'Add New Language',
          onClick: () => _fps.push({langId: ''})
        },
        removeButtProps = {
          ...cb,
          className: isMobileDevice ? 'circular red float-right mb15' : 'circular red mt23',
          icon: 'trash alternate'
        },
        valueLang = va(values[fieldArrName]) && values[fieldArrName];

      return (
        <>
          {
            valueLang && valueLang.map((_c: (LangAnswers | LangQuestions), i: number) => {
              const onRemove = () => {
                if (_c.id) {
                  setDelId(_c.id);
                }
                _fps.remove(i);
              }
              return (
                <Grid.Row key={i}>
                  <Grid.Column width={16}><hr /></Grid.Column>
                  <Grid.Column computer={8} tablet={16} mobile={16}>
                    <FormField {...formFields[0]} name={`${fieldArrName}.${i}.langId`} />
                  </Grid.Column>
                  <Grid.Column width={16} />
                  <Grid.Column computer={13} tablet={13} mobile={16}>
                    <FormField {...formFields[1]} name={`${fieldArrName}.${i}.${[isQ ? quesText : ansText]}`} />
                  </Grid.Column>
                  <Grid.Column computer={3} tablet={3} mobile={16}>
                    <Button {...removeButtProps} onClick={onRemove} />
                  </Grid.Column>
                </Grid.Row>
              );
            })
          }
          <Grid.Row>
            <Grid.Column width={16}>
              <Button {...addNewButtProps} />
            </Grid.Column>
          </Grid.Row>
        </>
      );
    };


  return (
    <Form onSubmit={handleSubmit}>
      <Grid>
        <Grid.Row>
          {
            formJSON.map((fieldProps, key: number) => {
              return (
                <Grid.Column key={key} computer={8} tablet={16} mobile={16}>
                  {
                    <FormField {...fieldProps} />
                  }
                </Grid.Column>
              )
            })
          }
        </Grid.Row>

        <FieldArray name={fieldArrName} render={renderQALangSampling} />

        <Grid.Row>
          <Grid.Column width={16} className="mt15" textAlign="right">
            {
              formButtons.map((buttonProps: ButtonProps, key: number) => (
                <Button key={key} {...buttonProps} />
              ))
            }
          </Grid.Column>
        </Grid.Row>

        {
          delId &&
          <Confirm {...delConfirmProps} />
        }
      </Grid>
    </Form>
  );
};

const QABank: React.FC<QuestionAnswerBankProps> = (qaBankProps: QuestionAnswerBankProps) => {
  const {edit, isAdd, isQ, loading, onCancel, onSave, tabIndex} = qaBankProps;
  const {category = '', langQuestions = [{langId: ''}]} = edit as Question;
  const {langAnswers = [{langId: ''}]} = edit as Answer;
  const initVs = {title: edit.title};
  const children = isQ ? AddQAForm : AddQAForm;
  const displayName = isQ ? 'question_form' : 'answer_form';
  const initialValues = isQ ? {...initVs, category, langQuestions} : {...initVs, langAnswers};
  const validationSchema = isQ ? questionSchema : answerSchema;
  const frmConfig = {
    children,
    displayName,
    initialValues,
    isQ,
    onCancel,
    onSubmit: async (values: FormikValues, {resetForm}: FormikValues) => {
      try {
        const {title, category, langAnswers, langQuestions} = values;
        switch (tabIndex) {
          case Tab.Questions:
            const langQs = langQuestions.map(({id, langId, quesText}: LangQuestions) => ({id, langId, quesText}));
            const payloadQ = {title, category, langQuestions: langQs};
            onSave(isAdd, payloadQ);
            break;
          case Tab.Answers:
            const langAns = langAnswers.map(({id, langId, ansText}: LangAnswers) => ({id, langId, ansText}));
            const payloadA = {title, langAnswers: langAns};
            onSave(isAdd, payloadA);
            break;
        }
      } catch (err) {
        resetForm();
      }
    },
    tabIndex,
    validationSchema
  }
  return (
    <>
      {
        loading &&
        <Grid.Row>
          <Grid.Column width={16}>
            <MiniLoader content="Loading..." />
          </Grid.Column>
        </Grid.Row>
      }
      {
        (isAdd || (edit && edit.id)) &&
        <FormWrap {...frmConfig} />
      }
    </>
  );
};

export default QABank;
