import React from 'react';
import {useSelector} from 'react-redux';
import {Button, Card, Confirm, Grid, GridView, Icon, Paging, ProgressGoals, Select} from '../Inputs';
import {Loader} from '../index';
import {
  ASSIGNMENT_TYPE,
  CONFIRM_MESSAGES,
  DEFAULT_RECORDS_PER_PAGE,
  Del,
  initFalsy,
  PROJECT_STATUS,
  PROJECT_TYPE
} from '../../constants';
import {useActions} from '../../redux/actions';
import * as ap from '../../redux/actions/projects';
import {BN, ButtonProps, IconProps, SelectProps, SelectValue, ST, StoreT, T} from '../../model';
import {aSort, enumAsAO, va} from '../../utils/arrayUtils';
import {getSkipLimit, joinAsFullName} from '../../utils/common';
import {usDateFormat} from '../../utils/dateUtils';

interface ListProjectProps {
  onSelect: Function
}

const ListProject: React.FC<ListProjectProps> = (props: ListProjectProps) => {
  const initPageNo = 1;
  const headers = [
    'Project Name',
    'Project Deadline',
    'Progress',
    'Status',
    'Last QA',
    'Project Manager',
    'Tz',
    'Assignment Type',
    'Questionnaire(s)',
    'Action'
  ];
  const initSort: { column: string; direction: ST } = {column: 'name', direction: 'ASC'};
  const sortKeys = [initSort.column, '', '', 'statusId', '', 'user.firstName', 'timezone'];
  const projectA = useActions(ap);
  const users = useSelector((state: StoreT) => state.projects);
  const {rows = [], count} = users || {};
  const [limit, setPageSize] = React.useState(DEFAULT_RECORDS_PER_PAGE);
  const [activePage, setPageChange] = React.useState(initPageNo);
  const [typeId, setProjectTypes] = React.useState('');
  const [loader, isLoading] = React.useState(initFalsy);
  const [delOpen, showDelConfirm]: [BN, Function] = React.useState(initFalsy);
  const [deactivated, setDeactivated] = React.useState(initFalsy);
  const [isDelBtn, setDelBtn] = React.useState(!initFalsy);
  const [deleting, isDeleting]: [boolean, Function] = React.useState(initFalsy);
  const [sortVars, setSortVars] = React.useState(initSort);
  const fetchProjects = async (skip = 0, lim = DEFAULT_RECORDS_PER_PAGE, sortOn = sortVars.column, sortBy = sortVars.direction) => {
    isLoading(!initFalsy);
    await projectA.fetchProjects(skip, lim, sortOn, sortBy, typeId);
    isLoading(initFalsy);
  };
  const navFux = (activePage: number, gridSize: number) => {
    const skip = getSkipLimit(activePage, gridSize);
    fetchProjects(skip, gridSize, sortVars.column, sortVars.direction).then();
  };
  const onSort = (column: string, direction: ST) => {
    setSortVars({column, direction});
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, column, direction).then();
  };
  const fcl = () => {
    const skip = getSkipLimit(activePage, limit);
    fetchProjects(skip, limit, sortVars.column, sortVars.direction).then();

    return () => projectA.flushProjects();
  };
  const prjTypes = enumAsAO(PROJECT_TYPE).sort(aSort('text'));
  const prjTypesProps: SelectProps = {
    hasFirstEmptyOpt: !initFalsy,
    name: 'typeId',
    options: prjTypes,
    placeholder: 'Project Type'
  };
  const onDelete = (id?: number, deletedAt?: T) => {
    if (deletedAt !== undefined) {
      setDelBtn(!initFalsy);
      !!deletedAt ? setDeactivated(!initFalsy as T) : setDeactivated(initFalsy as T);
    } else {
      setDelBtn(initFalsy);
    }

    showDelConfirm(id);
  };
  const projects = va(rows) && rows.map(project => {
    const {deadline, id, isLocked, deletedAt, lastQA, name, questionnaire, statusId, timezone, user} = project;
    const deadlineCST = usDateFormat(deadline);
    const clickHandler = (id: number, mode: string) => props.onSelect({id, value: mode});
    const deactivateIconProps: IconProps = {
      name: !!deletedAt ? 'lock' : 'lock open',
      onClick: () => onDelete(id, deletedAt),
      title: !!deletedAt ? Del.RACTP : Del.DACTP
    };
    const actionIcons: IconProps[] = [
      {name: 'eye', title: 'View Details', onClick: () => clickHandler(id as number, 'view')},
      {name: 'edit', title: 'Edit Details', onClick: () => clickHandler(id as number, 'edit')},
      {name: 'trash alternate', title: 'Delete', onClick: () => onDelete(id)},
      deactivateIconProps,
    ];
    const Action = (
      actionIcons.map((iconProps: IconProps, key: number) => (
        <Icon key={key} className="hand-pointer" {...iconProps} />
      ))
    );
    return {
      _warn: deletedAt,
      _id: id,
      _lock: isLocked,
      name,
      deadlineCST,
      progress: (
        <ProgressGoals compGoals={project.compGoals} reqGoals={project.reqGoals} />
      ),
      status: PROJECT_STATUS[statusId],
      lastQA,
      managerId: joinAsFullName(user?.firstName, user?.lastName),
      timezone,
      assignmentType: ASSIGNMENT_TYPE.Assigned,
      questionnaire,
      Action
    };
  });
  const performDelOperation = async (id: BN) => {
    isDeleting(!initFalsy);
    await projectA.removeProject(id, !isDelBtn ? undefined : !deactivated ? !initFalsy : initFalsy);
    isDeleting(initFalsy);
    showDelConfirm(initFalsy);
  };
  const DelButton = () => {
    const content = !isDelBtn ? Del.YD : !deactivated ? Del.YDA : Del.YRA;
    const delButtProps: ButtonProps = {
      color: !isDelBtn ? 'red' : !deactivated ? 'red' : undefined,
      content,
      loading: deleting,
      onClick: () => performDelOperation(delOpen),
      primary: !isDelBtn ? initFalsy : !deactivated ? initFalsy : !initFalsy,
      type: 'button'
    };
    return (
      <Button {...delButtProps} />
    )
  };
  const delConfirmProps = {
    content: !isDelBtn ? CONFIRM_MESSAGES.PROJECT : !deactivated ? CONFIRM_MESSAGES.PROJECT_DEC : CONFIRM_MESSAGES.PROJECT_RAC,
    header: !isDelBtn ? Del.DP : !deactivated ? Del.DACTP : Del.RACTP,
    confirmButton: DelButton,
    open: !!delOpen,
    onCancel: () => showDelConfirm(initFalsy),
    onConfirm: () => performDelOperation(delOpen)
  };

  React.useEffect(fcl, [typeId]);

  return (
    <>
      {
        loader &&
        <Loader />
      }

      <Grid>
        <Grid.Row className="headerTop">
          <Grid.Column width={16}>
            <h1 className="mainTitle">Project Management</h1>
            <Select {...prjTypesProps} onChange={(value: SelectValue) => setProjectTypes(value)} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Card fluid>
              <Card.Content>
                <GridView headers={headers} list={projects} onSort={onSort} sortKeys={sortKeys} />

                <Paging
                  totalRecords={count}
                  navigate={navFux}
                  activePage={activePage}
                  pageSize={limit}
                  setPageSize={setPageSize}
                  setPageChange={setPageChange}
                />

                {
                  delOpen &&
                  <Confirm {...delConfirmProps} />
                }
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default ListProject;
