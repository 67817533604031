import {ActionType, ApiEngine, RSOTP, Sign, T, VAO} from '../../model';
import {flushAccounts} from './accounts';
import {flushAnswers} from './answers';
import {flushAssignments} from './assignments';
import {flushCallInfo, flushCallLogInfo, flushCallLogs} from './calls';
import {flushClientInfo, flushClients} from './clients';
import {flushLanguages} from './languages';
import {flushProjectInfo, flushProjects} from './projects';
import {flushQuestionnaire, flushQuestionnaires} from './questionnaire';
import {flushQuestions} from './questions';
import {flushRosters} from './rosters';
import {flushSampling} from './sampling';
import {flushSurveyUserList, flushUserAccounts} from './users';
import persists from '../configure';
import {clear, del, save} from '../../utils/storage';
import {initFalsy, SESSION_AUTH_KEY} from '../../constants';
import {setAlert, setSuccess} from './alert';
import {flc} from '../../utils/stringUtils';

const apiPath = 'sessions';

export const systemFlush = () => (dispatch: Function) => {
  dispatch(flushAccounts());
  dispatch(flushAnswers());
  dispatch(flushAssignments());
  dispatch(flushCallInfo());
  dispatch(flushCallLogs());
  dispatch(flushCallLogInfo());
  dispatch(flushClients());
  dispatch(flushClientInfo());
  dispatch(flushLanguages());
  dispatch(flushProjects());
  dispatch(flushProjectInfo());
  dispatch(flushQuestionnaires());
  dispatch(flushQuestionnaire());
  dispatch(flushQuestions());
  dispatch(flushRosters());
  dispatch(flushSampling());
  dispatch(flushUserAccounts());
  dispatch(flushSurveyUserList());
  return;
};

export const SignIn = (payload: Sign) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const {auth, cookieStatus, deviceIpAdd} = payload;
    dispatch({type: ActionType.LOGIN_BEGIN, payload: {}});
    const cookieVal = cookieStatus ? checkVerifyAuth(auth.email, cookieStatus) : initFalsy;
    Object.assign(auth, {
      userKey: !!deviceIpAdd ? btoa(deviceIpAdd) : '',
      isRemember: cookieVal ? !initFalsy : initFalsy
    });
    const resp = await api.post(apiPath, auth, {jwt: 1});
    dispatch({type: ActionType.ALERT, payload: {}});
    !!resp.accessToken && save(SESSION_AUTH_KEY, resp.accessToken);
    dispatch({type: ActionType.LOGIN_SUCCESS, payload: resp});

    return resp;
  } catch (err) {
    return dispatch(setAlert(err));
  }
};

export const LogOut = () => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    await api.delete(apiPath);
    dispatch({type: ActionType.LOGOUT, payload: {}});
    return dispatch(systemFlush());
  } catch (_e) {
    console.error(_e);
  } finally {
    await persists().persistStore.purge();
    del(SESSION_AUTH_KEY);
    clear();
  }
};

export const Load = () => async (dispatch: Function, store: Function, api: ApiEngine) => {
  try {
    const resp = await api.get(apiPath, null, null);
    const existingAuth = store().auth;
    const payload = Object.assign(existingAuth, {accessToken: resp.accessToken});
    save(SESSION_AUTH_KEY, resp.accessToken);
    dispatch({type: ActionType.LOAD_SESSION, payload});
    return;
  } catch (err) {
    return dispatch(setAlert(err));
  }
};

/**
 * Verify And Surveyor Via Otp
 */
export const verifyValidOtp = (data: VAO) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    dispatch({type: ActionType.LOGIN_BEGIN, payload: {}});
    const result = await api.post(`${apiPath}/otp-verification`, data);
    !!result.accessToken && save(SESSION_AUTH_KEY, result.accessToken);
    dispatch({type: ActionType.LOGIN_SUCCESS, payload: result});

    return result;
  } catch (err) {
    dispatch(setAlert(err));
    return err.statusCode;
  }
};

/**
 * Resend Otp
 */
export const resendOtp = (payload: RSOTP, type?: boolean) => async (dispatch: Function, store: Function, api: ApiEngine) => {
  try {
    const {data} = await api.post(`${apiPath}/send-otp`, payload);
    dispatch(setSuccess(data));

    if (type) {
      const existingAuth = store().auth;
      const {user} = existingAuth;
      user.phoneNumber = payload.phoneNumber;
      delete user.cimrList;
      dispatch({type: ActionType.LOGIN_SUCCESS, payload: {user}});
    }

    return data;
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Reset OTP Time
 */
export const resetOtpStatus = async (id: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    await api.put(`${apiPath}/otp-status`, {id});
    return dispatch(systemFlush());
  } catch (err) {
    dispatch(setAlert(err));
    await persists().persistStore.purge();
    del(SESSION_AUTH_KEY);
    clear();
    dispatch(systemFlush());
  }
};

const checkVerifyAuth = (email: string, cookieStatus: T) => {
  try {
    const arr = JSON.parse(cookieStatus);
    return arr.find((j: string) => flc(atob(j)) === flc(email));
  } catch (err) {
    return initFalsy;
  }
};
