import {
  ActionType,
  ApiEngine,
  CleanResponse,
  ClientsContacts,
  Modifier,
  NU,
  PDU,
  PILV,
  Project,
  ProjectsList,
  T
} from '../../model';
import {setAlert, setSuccess} from './alert';
import {initFalsy, MODIFIER_TABLE_TYPE, PLSort, ROLES, SortDir} from '../../constants';
import {vo} from '../../utils/objectUtils';
import {byKey, va} from '../../utils/arrayUtils';
import {formatAsPhone} from '../../utils/validation';

const projectApiPath = 'projects';


/**
 * Flush Admin Projects Store
 */
export const flushProjects = () => async (dispatch: Function) => {
  try {
    return dispatch({type: ActionType.PROJECTS_FLUSH, payload: null});
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Flush Project Info
 */
export const flushProjectInfo = () => async (dispatch: Function, _store: Function, _api: ApiEngine) => {
  return dispatch({type: ActionType.ADMIN_PROJECT_INFO_FLUSH, payload: {}});
};

/**
 * Flush Visit Infos
 */
export const flushVisitInfo = () => async (dispatch: Function, _store: Function, _api: ApiEngine) => {
  return dispatch({type: ActionType.PM_VISIT_INFO_FLUSH, payload: {}});
};

/**
 * Fetch Projects
 * @param skip
 * @param limit
 * @param orderBy
 * @param orderDir
 * @param typeId
 * @param userId
 * @param isWeekly
 * @param onlyNames
 * @param isEscalations
 * @param search
 * @param onlyNamesAttrs
 */
export const fetchProjects = (skip: number, limit: number, orderBy: string, orderDir: string, typeId: NU, userId: number | number[], onlyNames: boolean = initFalsy, isWeekly: boolean = !initFalsy, isEscalations?: boolean, search?: string, onlyNamesAttrs?: string[]) => async (dispatch: Function, store: Function, api: ApiEngine) => {
  try {
    const userInfo = store().auth.user;
    let _orderBy = orderBy;
    switch (orderBy) {
      case PLSort.LatestRosterUpload:
      case PLSort.UnresolvedEscalations:
      case PLSort.UndefinedSamplingParameters:
        _orderBy = '';
        break;
    }

    const query = {
      isEscalations,
      isWeekly,
      limit,
      onlyNames,
      onlyNamesAttrs,
      orderBy: _orderBy,
      orderDir,
      search,
      skip,
      typeId,
      userId
    };

    if (userInfo.role === ROLES.Admin) {
      Object.assign(query, {isAdmin: !initFalsy});
    }

    const projects = !!_orderBy ? await api.get(projectApiPath, null, query) : store().projects;

    const isAsc = orderDir === SortDir.DESC;
    switch (orderBy) {
      case PLSort.LatestRosterUpload:
        projects.rows.sort(byKey(PLSort.LatestRosterUpload, isAsc, !initFalsy));
        break;
      case PLSort.UnresolvedEscalations:
        projects.rows.sort(byKey(PLSort.UnresolvedEscalations, isAsc));
        break;
      case PLSort.UndefinedSamplingParameters:
        projects.rows.sort(byKey(PLSort.UndefinedSamplingParameters, isAsc));
        break;
    }

    return dispatch({type: ActionType.PULL_PROJECTS, payload: projects});
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Fetch Surveyors Assigned Projects
 * @param skip
 * @param limit
 * @param orderBy
 * @param orderDir
 * @param surveyorId
 * @param isWeekly
 */
export const fetchSurveyorProjects =
  (skip: number, limit: number, orderBy: string, orderDir: string, surveyorId: number, isWeekly: boolean = !initFalsy) =>
    async (dispatch: Function, _store: Function, api: ApiEngine) => {
      try {
        const query = {skip, limit, orderBy, orderDir, surveyorId, isWeekly};
        const projects = await api.get(`${projectApiPath}/surveyors`, null, query);
        return dispatch({type: ActionType.PULL_PROJECTS, payload: projects});
      } catch (err) {
        dispatch(setAlert(err));
      }
    };

type UAVs = [number, number, string, string, T];
/**
 * Fetch Questionnaire Projects
 */
export const fetchProjectList = (isOps: boolean, isManager: boolean, search?: string) => async (dispatch: Function, store: Function) => {
  const [limit, skip, sortBy, sortOn, undefinedValue]: UAVs = [Number.MAX_SAFE_INTEGER, 0, 'ASC', 'name', undefined];
  const userId = isOps ? undefinedValue : store().auth.user.id;

  return (isManager || isOps) ?
    dispatch(fetchProjects(skip, limit, sortOn, sortBy, undefinedValue, userId, undefined, undefined, undefined, search)) :
    dispatch(fetchSurveyorProjects(skip, limit, sortOn, sortBy, userId));
};

/**
 * Insert new Project with Contacts & Modifiers
 * @param payload
 */
export const addProject = (payload: Project) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const modifiers: Partial<Modifier>[] = [];
    const captureModifier = (m: Modifier[] | undefined, modifierId: number) => {
      if (va(m)) {
        m?.forEach(mm => {
          if (vo(mm)) {
            modifiers.push({...mm, modifierId});
          }
        });
      }
    };
    const {
      billableRate = '0.00',
      billingModifier,
      paymentModifier,
      paymentRate = '0.00',
      perReportingPeriodFee = '0.00',
      pmFee = '0.00',
      powerBiFee = '0.00',
      sampling,
      ...others
    } = payload;

    captureModifier(billingModifier, MODIFIER_TABLE_TYPE['Project Build']);
    captureModifier(paymentModifier, MODIFIER_TABLE_TYPE.Payment);

    const projectPayload = {
      ...others,
      billableRate,
      modifiers,
      paymentRate,
      perReportingPeriodFee,
      pmFee,
      powerBiFee,
      sampling: (sampling as string[]).join(',')
    };

    const prjRes: CleanResponse = await api.post(projectApiPath, projectPayload);
    dispatch(setSuccess(prjRes.message));

    return dispatch({type: ActionType.ADMIN_PROJECTS_ADD, payload: null});
  } catch (err) {
    dispatch(setAlert(err));
  }
};

/**
 * Update Project Values
 * @param data
 */
export const updateProject = (data: Project) => async (dispatch: Function, _store: Function, _api: ApiEngine) => {
  const modifiers: Partial<Modifier>[] = [];
  const filterCDU = (obj: T) => {
    const {
      createdAt,
      deletedAt,
      // CROS-92
      surveyCompleteDays,
      surveyIncompleteDays,
      surveyRefusedDays,
      surveyWrongInfoDays,
      updatedAt,
      ...val
    } = obj;
    return val;
  };
  const captureModifier = (m: Modifier[] | undefined, modifierId: number) => {
    if (va(m)) {
      m?.forEach(mm => {
        if (vo(mm)) {
          const mods = filterCDU(mm);
          modifiers.push({...mods, modifierId});
        }
      });
    }
  };
  const {
    billingModifier,
    clientId,
    contacts,
    lastQA,
    lineItems,
    paymentModifier,
    progress,
    questionnaire,
    sampling,
    ...payload
  } = data;

  captureModifier(billingModifier, MODIFIER_TABLE_TYPE['Project Build']);
  captureModifier(paymentModifier, MODIFIER_TABLE_TYPE.Payment);
  const project = filterCDU(payload);
  const final: Partial<Project> = {};
  // @ts-ignore
  final.contacts = (contacts || []).map(c => filterCDU(c));
  final.lineItems = lineItems?.map(l => filterCDU(l));
  const updPayload = {...project, ...final, modifiers, sampling: (sampling as string[]).join(',')};
  await _api.put(projectApiPath, updPayload);
  return dispatch({type: ActionType.ADMIN_PUT_PROJECTS, payload: []});
};

/**
 * Fetch Project Info for Project ID
 * @param projectId
 * @param isAdmin
 * @param isSvProject
 */
export const getProjectInfo = (projectId: number, isAdmin?: boolean, isSvProject?: boolean) => async (dispatch: Function, store: Function, api: ApiEngine) => {
  try {
    const user = store().auth.user;
    const query = {isAdmin};

    const managerId = (user.role === ROLES['Project Manager']) ? user.id : undefined;
    const surveyorId = (user.role === ROLES.Surveyor || user.role === ROLES['CIMR Surveyor']) ? user.id : undefined;

    if (surveyorId) {
      Object.assign(query, surveyorId);
    }

    if (managerId) {
      Object.assign(query, {managerId, isSvProject});
    }

    const projectInfoResp = await api.get(`${projectApiPath}/${projectId}`, null, query);
    const {contacts} = projectInfoResp;
    const contactsList = contacts.map((data: ClientsContacts) => {
      const {phoneNumber} = data;
      return {...data, phoneNumber: formatAsPhone(phoneNumber)};
    });
    const project = Object.assign(projectInfoResp, {contacts: contactsList});
    dispatch({type: ActionType.ADMIN_PROJECT_INFO, payload: project});
    return project;
  } catch (e) {
    dispatch(setAlert(e));
    return e;
  }
};

/**
 * Remove Project By Id
 * @param id
 * @param isDeactivated
 */
export const removeProject = (id: number, isDeactivated?: boolean) => async (dispatch: Function, store: Function, api: ApiEngine) => {
  try {
    const response: CleanResponse = await api.delete(projectApiPath, {id, isDeactivated});
    const cl: ProjectsList = store().projects;
    dispatch({type: ActionType.ADMIN_DEL_PROJECT, payload: response});
    const fi = cl.rows && cl.rows.findIndex(i => i.id === id);
    if (isDeactivated !== undefined) {
      const updDelDate = cl?.rows[fi];
      !!updDelDate && (isDeactivated ? updDelDate.deletedAt = new Date().toString() : updDelDate.deletedAt = null);
      dispatch({type: ActionType.PULL_PROJECTS, payload: cl});
    } else {
      if (fi >= 0) {
        cl.rows.splice(fi, 1);
        cl.count--;
        dispatch({type: ActionType.PULL_PROJECTS, payload: cl});
      }
    }

    dispatch(setSuccess(response.message));
  } catch (err) {
    dispatch(setAlert(err))
  }
};

/**
 * Fetch Visit Info for Project ID
 * @param projectId
 */
export const getVisitInfo = (projectId: number) => async (dispatch: Function, store: Function, api: ApiEngine) => {
  try {
    const user = store().auth.user;
    const managerId = (user.role === ROLES['Project Manager']) ? user.id : undefined;
    const visitInfos = await api.get(`${projectApiPath}/visit-info/${projectId}`, null, {managerId});
    dispatch({type: ActionType.PM_VISIT_INFO, payload: visitInfos});
    return visitInfos;
  } catch (e) {
    dispatch(setAlert(e));
  }
};

export const updateVisitInfo = (payload: T) => async (dispatch: Function, _store: Function, _api: ApiEngine) => {
  try {
    const res: CleanResponse = await _api.put(`${projectApiPath}/visit-info`, payload);
    dispatch({type: ActionType.PM_UPDATE_VISIT_INFO, payload: res.data});
    dispatch(setSuccess(res.message));
  } catch (err) {
    dispatch(setAlert(err));
  }
}

/**
 * Update the project status is locked or not
 * @param payload project id and project status
 */
export const updateProjectStatus = (payload: PILV) => async (dispatch: Function, _store: Function, _api: ApiEngine) => {
  try {
    const statusResp = await _api.put(`${projectApiPath}/lock`, payload);
    dispatch({type: ActionType.PM_UPDATE_STATUS, payload: statusResp.data});
    dispatch(setSuccess(statusResp.message));
  } catch (err) {
    dispatch(setAlert(err));
  }
}

/**
 * Update the project date (Start And Deadline)
 * @param payload dates
 */
export const updateProjectDate = (payload: PDU) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const statusResp = await api.put(`${projectApiPath}/dates`, payload);
    dispatch({type: ActionType.PM_UPDATE_DATE, payload: statusResp.data});
    dispatch(setSuccess(statusResp.message));
  } catch (err) {
    dispatch(setAlert(err));
  }
};


/**
 *
 */
export const fetchProjectInfoById = (id: number) => async (dispatch: Function, _store: Function, api: ApiEngine) => {
  try {
    const query = {id};
    const {data} = await api.get(`${projectApiPath}/name`, null, query);
    return data;
  } catch (err) {
    dispatch(setAlert(err));
  }
};
